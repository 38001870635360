import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faImage,
  faLink,
  faVideo,
  faCode,
  faTrashCan,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

import "./ContentBlock.scss";

// ブロック系コンポーネント
import TextContent from "../ContentComponents/TextContent.js";
import ImageContent from "../ContentComponents/ImageContent.js";
import VideoContent from "../ContentComponents/VideoContent.js";
import LinkContent from "../ContentComponents/LinkContent.js";
import CodeContent from "../ContentComponents/CodeContent.js";
import FormBlock from "../ContentComponents/FormBlock";

// 全画面系ブロック
import FullVideoContent from "../ContentComponents/FullVideoContent.js";

// app guide
import AppGuide from "../components/AppGuide.js";

// framer motion
const framerFadeUp = {
  hidden: {
    opacity: 0,
    translateY: 10,
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

const ContentBlock = ({
  editMode,
  cardTypes,
  block,
  slidesData,
  currentSlideIndex,
  setSlidesData,
  slideColors,
  blockIndex,
  addBlock,
  addBlockData,
  setAddBlockData,
  deleteBlock,
  reorderBlocks,
  viewershipStates
}) => {

  const [showAppGuide, setShowAppGuide] = React.useState(true);

  const updateBlockWrapper = ({ blockIndex, value, key = undefined }) => {
    const updatedSlidesData = JSON.parse(JSON.stringify(slidesData));

    if (key == undefined) {
      updatedSlidesData.slides[currentSlideIndex].blocks[blockIndex] = value;
    } else {
      updatedSlidesData.slides[currentSlideIndex].blocks[blockIndex][key] =
        value;
    }

    setSlidesData((prev) => ({
      ...prev,
      slides: updatedSlidesData.slides,
    }));
  };

  const updateBlock = (arrayOfItemsToUpdate) => {
    const updatedSlidesData = JSON.parse(JSON.stringify(slidesData));

    arrayOfItemsToUpdate.map((item) => {
      const { blockIndex, value, key = undefined } = item;

      if (key == undefined) {
        updatedSlidesData.slides[currentSlideIndex].blocks[
          blockIndex
        ].child_data = value;
      } else {
        updatedSlidesData.slides[currentSlideIndex].blocks[
          blockIndex
        ].child_data[key] = value;
      }
    });

    setShowAppGuide(false);

    setSlidesData((prev) => ({
      ...prev,
      slides: updatedSlidesData.slides,
    }));
  };

  // インデント用のスライド検知
  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 80;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = (e, blockIndex) => {
    if (!touchStart || !touchEnd) return;
    if (!editMode) return;
    if (block.full_screen) return;

    e.preventDefault();

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        changeIndentation("left");
      } else {
        changeIndentation("right");
      }
    }
  };

  const changeOrder = (direction) => {
    reorderBlocks(direction, setSlidesData, currentSlideIndex, blockIndex);
  };

  const changeIndentation = (direction) => {
    if (direction == "left") {
      if (block.indentation > 0) {
        // updatedBlock.indentation = updatedBlock.indentation - 1;
        updateBlockWrapper({
          blockIndex: blockIndex,
          value: block.indentation - 1,
          key: "indentation",
        });
      }
    } else if (direction == "right") {
      if (block.indentation < 2) {
        // updatedBlock.indentation = updatedBlock.indentation + 1;
        updateBlockWrapper({
          blockIndex: blockIndex,
          value: block.indentation + 1,
          key: "indentation",
        });
      }
    } else {
      return;
    }
  };

  return (
    <>
      <motion.div
        id={`block-${block.id}`}
        className={`slide-block-motion-wrapper indent-${block.indentation}`}
        variants={framerFadeUp}
        initial="hidden"
        animate="show"
        transition={
          !editMode
            ? {
                ...framerFadeUp.transition,
                delay: 0.2 * (blockIndex + 1),
              }
            : {
                ...framerFadeUp.transition,
                delay: 0.1,
              }
        }
        key={block.id}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={(e) => {
          onTouchEnd(e, blockIndex);
        }}
      >
        <AppGuide
          condition={
            editMode &&
            slidesData.slides.length <= 3 &&
            slidesData.slides[currentSlideIndex].blocks.length == 1 &&
            showAppGuide
          }
          message={"ブロックを編集"}
          animation={"vertical"}
          pointer={"👇"}
          styling={{
            flexDirection: "column",
            left: "50%",
            transform: "translate(calc(-50%), calc(-100% - 20px))",
          }}
        />

        {editMode && (
          <div className="slide-block-top-section">
            <div className="slide-block-type-section">
              <FontAwesomeIcon
                className="slide-block-type-icon"
                icon={cardTypes[block.child_type].faIcon}
              />
              <span
                className="slide-block-type-name"
                style={{
                  color: slidesData.slides[currentSlideIndex].font_color,
                }}
              >
                {cardTypes[block.child_type].displayName}
              </span>
            </div>

            {!slidesData.slides[currentSlideIndex].full_contents && (
              <div className="reorder-section">
                <FontAwesomeIcon
                  className="up cursor-pointer"
                  icon={faArrowUp}
                  onClick={() => {
                    changeOrder("up");
                  }}
                />
                <FontAwesomeIcon
                  className="down cursor-pointer"
                  icon={faArrowUp}
                  onClick={() => {
                    changeOrder("down");
                  }}
                />
                <FontAwesomeIcon
                  className="left cursor-pointer"
                  icon={faArrowUp}
                  onClick={() => {
                    changeIndentation("left");
                  }}
                />
                <FontAwesomeIcon
                  className="right cursor-pointer"
                  icon={faArrowUp}
                  onClick={() => {
                    changeIndentation("right");
                  }}
                />
              </div>
            )}

            <div
              className="delete-button cursor-pointer"
              onClick={() => {
                if (window.confirm("ブロックを削除しますか？")) {
                  deleteBlock(block.id);
                }
              }}
            >
              <FontAwesomeIcon className="" icon={faTrashCan} />
            </div>
          </div>
        )}

        <div
          className="block-content"
          style={
            slideColors && {
              color: slideColors.legibleColor,
            }
          }
        >
          {block.indentation > 0 && (
            <div className="indentation-markings">
              {Array(block.indentation)
                .fill(null)
                .map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="indent-dot"
                      style={{
                        background:
                          slidesData.slides[currentSlideIndex].font_color,
                      }}
                    ></div>
                  );
                })}
            </div>
          )}

          <div
            className={`block-container ${
              block.shadowed && !editMode ? "shadowed" : ""
            }`}
          >
            {(() => {
              if (block.title != "" || editMode) {
                return (
                  <>
                    {!editMode ? (
                      <span
                        className="block-name"
                        style={{
                          color: slideColors.opacityColor,
                        }}
                      >
                        {block.title}
                      </span>
                    ) : (
                      <input
                        type="text"
                        className="block-name"
                        style={{
                          borderColor: slideColors.legibleColor,
                        }}
                        value={block.title}
                        placeholder="ブロック小タイトル"
                        onChange={(e) => {
                          updateBlockWrapper({
                            blockIndex: blockIndex,
                            value: e.target.value,
                            key: "title",
                          });
                        }}
                      />
                    )}
                  </>
                );
              }
            })()}

            {(() => {
              switch (block.child_type) {
                case "text":
                  return (
                    <TextContent
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      updateSlidesData={setSlidesData}
                      slideColors={slideColors}
                      index={blockIndex}
                      editMode={editMode}
                      addBlock={addBlock}
                      updateBlock={updateBlock}
                      updateBlockWrapper={updateBlockWrapper}
                      changeIndentation={changeIndentation}
                    />
                  );
                case "code":
                  return (
                    <CodeContent
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      slideColors={slideColors}
                      updateSlidesData={setSlidesData}
                      index={blockIndex}
                      editMode={editMode}
                      updateBlock={updateBlock}
                      updateBlockWrapper={updateBlockWrapper}
                    />
                  );
                case "image":
                  return (
                    <ImageContent
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      updateSlidesData={setSlidesData}
                      index={blockIndex}
                      editMode={editMode}
                      updateBlock={updateBlock}
                      updateBlockWrapper={updateBlockWrapper}
                    />
                  );
                case "video":
                  return (
                    <VideoContent
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      updateSlidesData={setSlidesData}
                      index={blockIndex}
                      editMode={editMode}
                      updateBlock={updateBlock}
                    />
                  );
                case "link":
                  return (
                    <LinkContent
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      slideColors={slideColors}
                      updateSlidesData={setSlidesData}
                      index={blockIndex}
                      editMode={editMode}
                      updateBlock={updateBlock}
                      updateBlockWrapper={updateBlockWrapper}
                    />
                  );
                case "full_video":
                  return (
                    <FullVideoContent
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      updateSlidesData={setSlidesData}
                      index={blockIndex}
                      editMode={editMode}
                      updateBlock={updateBlock}
                    />
                  );
                case "form":
                  return (
                    <FormBlock
                      blockData={block}
                      currentSlideIndex={currentSlideIndex}
                      slidesData={slidesData}
                      slideColors={slideColors}
                      updateSlidesData={setSlidesData}
                      index={blockIndex}
                      editMode={editMode}
                      updateBlock={updateBlock}
                      updateBlockWrapper={updateBlockWrapper}
                      viewershipStates={viewershipStates}
                    />
                  );
              }
            })()}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ContentBlock;
