// ----- Slide Theme Colors
export const slideThemeColors = () => {
  return {
    minimal: {
      value: "#f2f1f0",
      colorName: "ミニマル",
      fontColor: "#685e57",
    },
    white: {
      value: "#ffffff",
      colorName: "ホワイト",
      fontColor: "#292e34",
    },
    black: {
      value: "#000000",
      colorName: "ブラック",
      fontColor: "#ffffff",
    },
    classic: {
      value: "#e3d4c6",
      colorName: "クラシック",
      fontColor: "#685e57",
    },
    calm: {
      value: "#334451",
      colorName: "落ち着き",
      fontColor: "#dadada",
    },
    northernForest: {
      value: "#366153",
      colorName: "北欧の森",
      fontColor: "#ffffff",
    },
    sweet: {
      value: "#e1a2b9",
      colorName: "やさしさ",
      fontColor: "#ffffff",
    },
    kusumiPink: {
      value: "#e3bfbf",
      colorName: "くすみピンク",
      fontColor: "#ffffff",
    },
    kusumiOrange: {
      value: "#e6c293",
      colorName: "くすみオレンジ",
      fontColor: "#ffffff",
    },
    kusumiGreen: {
      value: "#6ecc91",
      colorName: "くすみグリーン",
      fontColor: "#ffffff",
    },
    kusumiBlue: {
      value: "#c2eaef",
      colorName: "くすみ青",
      fontColor: "#292e34",
    },
    kusumiYellow: {
      value: "#d1c16a",
      colorName: "くすみイエロー",
      fontColor: "#ffffff",
    },
    autumn: {
      value: "#bea462",
      colorName: "秋",
      fontColor: "#ffffff",
    },
    lekchaColor: {
      value: "#4abde1",
      colorName: "Lekchaカラー",
      fontColor: "#ededed",
    },
    marine: {
      value: "#1a97d7",
      colorName: "海",
      fontColor: "white",
    },
    earlyBird: {
      value:
        "linear-gradient(163deg, rgba(139,220,242,1) 0%, rgba(225,174,74,0.9990371148459384) 100%)",
      colorName: "早朝",
      fontColor: "white",
    },
    earthAutumn: {
      value:
        "linear-gradient(163deg, rgba(209,193,106,1) 0%, rgba(190,209,106,1) 100%)",
      colorName: "秋",
      fontColor: "white",
    },
    newGen: {
      value:
        "linear-gradient(163deg, rgba(58,212,255,1) 0%, rgba(205,65,201,0.9990371148459384) 100%)",
      colorName: "新世代",
      fontColor: "white",
    },
  };
};

// Block Wrapper Color Modes
export const blockWrapperColorModes = [
  "darker",
  "lighter",
  // "none"
];

// ----- NEW get slide colors -----------

// parameters
const slideColorParams = {
  shiftParams: {
    adjustmentRange: 0.08,
    revertedWeight: 2,
    opacity: {
      outOfBound: .8,
      normal: .5,
    },
    saturationFactor: 1,
  },

  linearGradientOutputs: {
    samplingStopPoint: 0,
    darkerHSLAObj: {
      h: 0,
      s: 0,
      l: 1,
      a: 0.15,
    },
    lighterHSLAObj: {
      h: 0,
      s: 0,
      l: 0,
      a: 0.1,
    },
  },

  legibleParams: {
    isDarkThreshold: 0.7,
    lowerBoundLuminosity: 0.1,
    upperBoundLuminosity: 0.95,
    alpha: .9,
  },

  opacityParams: {
    isDarkThreshold: 0.7,
    lowerBoundLuminosity: 0.1,
    upperBoundLuminosity: 0.95,
    alpha: .7,
  },
};

// calculate slide colors
export const calculateSlideColors = (slideThemeColor) => {
  // keep record of slideThemeColorSpace ["hex", "linearGradient", undefined]
  const slideThemeColorSpace = (() => {
    switch (true) {
      case (slideThemeColor[0] == "#"):
        return "hex";
        break;
      case (slideThemeColor.includes("linear-gradient")):
        return "linearGradient";
        break;
      default:
        return undefined;
    }
  })();

  // return undefined if color space is incompatible
  if (slideThemeColorSpace === undefined) return undefined;

  // ----- Define the theme color HSLA object ----------------
  const slideThemeColorRGBA = (() => {
    switch (slideThemeColorSpace) {
      case "hex":
        return convertHexToRGBA({ hex: slideThemeColor, opacity: 1 });
      case "linearGradient":
        return getLinearGradientColor(
          slideThemeColor,
          slideColorParams.linearGradientOutputs.samplingStopPoint
        );
    }
  })();
  const slideThemeColorHSLA = convertRGBAtoHSLA(slideThemeColorRGBA);
  const slideThemeColorHSLAObj = HSLAStringToObject(slideThemeColorHSLA);

  // define if the color is darker or lighter
  const isDarker = slideThemeColorHSLAObj.l < 0.5 ? true : false;

  // conditions
  const slideColors = (() => {
    switch (slideThemeColorSpace) {
      case "hex":
        const { darker, lighter } = getShiftColors(
          slideThemeColorHSLAObj,
          slideColorParams.shiftParams
        );
        return {
          darkerShift: darker,
          lighterShift: lighter,
          legibleColor: HSLAObjectToString(getLegibleColor(
            slideThemeColorHSLAObj,
            slideColorParams.legibleParams
          )),
          opacityColor: HSLAObjectToString(getLegibleColor(
            slideThemeColorHSLAObj,
            slideColorParams.opacityParams
          )),
          isDarker: isDarker
        };
        break;
      case "linearGradient":
        return {
          darkerShift: HSLAObjectToString(slideColorParams.linearGradientOutputs.darkerHSLAObj),
          lighterShift: HSLAObjectToString(slideColorParams.linearGradientOutputs.lighterHSLAObj),
          legibleColor: HSLAObjectToString(getLegibleColor(
            slideThemeColorHSLAObj,
            slideColorParams.legibleParams
          )),
          opacityColor: HSLAObjectToString(getLegibleColor(
            slideThemeColorHSLAObj,
            slideColorParams.opacityParams
          )),
          isDarker: isDarker
        };
        break;
      default:
        // return undefined if color space is incompatible
        return undefined;
    }
  })();

  return slideColors;
};

// ----- some helpers ----------------

const getShiftColors = (HSLAObj, shiftParams) => {
  // ----- Treating the colors that adjusted, will be out of bound ---------------
  // determine if the produces colors luminosity will be out of bound (0-1 for HSLA)
  const luminosityOutOfBound =
    HSLAObj.l + shiftParams.adjustmentRange > 1 ||
    HSLAObj.l - shiftParams.adjustmentRange < 0;

  // determine the reverted operand and weight
  const revertedWeight = shiftParams.revertedWeight;
  const revertedWeights = {
    lighter: HSLAObj.l + shiftParams.adjustmentRange > 1 ? -1 : revertedWeight,
    darker: HSLAObj.l + shiftParams.adjustmentRange > 1 ? revertedWeight : -1,
  };


  // ----- Calculating the colors ---------------

  const outputColorOpacity = luminosityOutOfBound
    ? shiftParams.opacity.outOfBound
    : shiftParams.opacity.normal;

  // define slide colors
  var slideColorObjects = {
    darker: luminosityOutOfBound
      ? luminosityManipulationHLSA(
          HSLAObj,
          -(shiftParams.adjustmentRange * revertedWeights.darker),
          shiftParams.saturationFactor,
          outputColorOpacity
        )
      : luminosityManipulationHLSA(
          HSLAObj,
          -shiftParams.adjustmentRange,
          shiftParams.saturationFactor,
          outputColorOpacity
        ),
    lighter: luminosityOutOfBound
      ? luminosityManipulationHLSA(
          HSLAObj,
          shiftParams.adjustmentRange * revertedWeights.lighter,
          shiftParams.saturationFactor,
          outputColorOpacity
        )
      : luminosityManipulationHLSA(
          HSLAObj,
          shiftParams.adjustmentRange,
          shiftParams.saturationFactor,
          outputColorOpacity
        ),
  };

  return {
    darker: HSLAObjectToString(slideColorObjects.darker),
    lighter: HSLAObjectToString(slideColorObjects.lighter),
  };
};

const getLegibleColor = (HSLAObj, legibleParams) => {
  // if luminosity float is less than .5, then font should be white, otherwise black.
  const newHSLAObject = {
    h: HSLAObj.h,
    s: HSLAObj.s,
    l: HSLAObj.l < legibleParams.isDarkThreshold ? legibleParams.upperBoundLuminosity : legibleParams.lowerBoundLuminosity,
    a: legibleParams.alpha,
  };

  return newHSLAObject;
};


const getLinearGradientColor = (linearGradient, percentage) => {
  // get the color at the percentage of the linear gradient
  // example of linearGradient: linear-gradient(163deg, rgba(58,212,255,1) 0%, rgba(205,65,201,0.9990371148459384) 100%)

  // get the color stops
  const colorStops = linearGradient.match(/rgba?\([^)]+\)/g);

  // get the color at the percentage
  const colorAtPercentage = colorStops[percentage]
    .replace("rgba", "")
    .replace("rgb", "")
    .replace("(", "")
    .replace(")", "")
    .split(",");
  const colorAtPercentageRGBAObject = {
    r: colorAtPercentage[0],
    g: colorAtPercentage[1],
    b: colorAtPercentage[2],
    a: colorAtPercentage[3],
  };

  // convert rgba object back to string
  const colorAtPercentageRGBA = RGBAObjectToString(colorAtPercentageRGBAObject);

  return colorAtPercentageRGBA;
};

// ----- Color manipulation ----------------
export const addAlphaToColor = (color, opacity) => {
  if (color[0] != "#") {
    return undefined;
  }

  const colorWithAlpha = convertHexToRGBA({ hex: color, opacity: opacity });

  return colorWithAlpha;
};

// ----- Color space conversion ----------------

const convertHexToRGBA = ({ hex, opacity }) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    const rgbaValue = `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )}, ${opacity})`;

    return rgbaValue;
  }
  throw new Error("Bad Hex");
};

const convertRGBAtoHSLA = (rgba, opacity = 1) => {
  const rgbaValues = RGBAStringToObject(rgba);

  // Make r, g, and b fractions of 1
  const r = rgbaValues.r / 255;
  const g = rgbaValues.g / 255;
  const b = rgbaValues.b / 255;

  // alpha value stays the same
  const alpha = opacity;

  // Find greatest and smallest channel values
  var cmin = Math.min(r, g, b);
  var cmax = Math.max(r, g, b);
  var delta = cmax - cmin;
  var h = 0;
  var s = 0;
  var l = 0;

  // Calculate hue
  if (delta == 0) h = 0;
  // Red is max
  else if (cmax == r) h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax == g) h = (b - r) / delta + 2;
  // Blue is max
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  const hslaValue = `hsla(${h}, ${s}%, ${l}%, ${alpha})`;

  return hslaValue;
};

// ----- RGBA processing ----------------

const RGBAStringToObject = (rgba) => {
  // get the part of rgba string between parentheses
  const rgbaString = rgba.substring(rgba.indexOf("(") + 1, rgba.indexOf(")"));

  // split the string into an array of values and convert them to numbers, then put them into an object
  const rgbaObject = {
    r: Number(rgbaString.split(",")[0]),
    g: Number(rgbaString.split(",")[1]),
    b: Number(rgbaString.split(",")[2]),
    a: Number(rgbaString.split(",")[3]),
  };

  return rgbaObject;
};

const RGBAObjectToString = (rgbaObject) => {
  const rgbaString = `rgba(${rgbaObject.r}, ${rgbaObject.g}, ${rgbaObject.b}, ${rgbaObject.a})`;

  return rgbaString;
};

// ----- HSLA processing ----------------

const HSLAStringToObject = (hsla) => {
  // get the part of hsla string between parentheses
  const hslaString = hsla.substring(hsla.indexOf("(") + 1, hsla.indexOf(")"));

  // split the string into an array of values, then put them into an object
  const hslaObject = {
    h: Number(hslaString.split(",")[0]),
    s: Number(hslaString.split(",")[1].replace("%", "")) / 100,
    l: Number(hslaString.split(",")[2].replace("%", "")) / 100,
    a: Number(hslaString.split(",")[3]),
  };

  return hslaObject;
};

const HSLAObjectToString = (hslaObject) => {
  const hslaString = `hsla(${hslaObject.h}, ${
    Math.round(hslaObject.s * 100 * 1000) / 1000
  }%, ${Math.round(hslaObject.l * 100 * 1000) / 1000}%, ${hslaObject.a})`;

  return hslaString;
};

const luminosityManipulationHLSA = (
  hslaObject,
  luminosity,
  saturation = undefined,
  opacity = 1
) => {
  // returns a new hsla object with the luminosity changed.
  const newHSLAObject = {
    h: hslaObject.h,
    s: saturation == undefined ? hslaObject.s : hslaObject.s * saturation,
    l: Math.round((hslaObject.l + luminosity) * 1000) / 1000,
    a: opacity,
  };

  return newHSLAObject;
};
