import React from "react";
import "./ComponentsTest.scss";

// admin components
import {
  Section,
  Status,
  Input,
  TextArea,
  Button,
} from "./AdminComponents/AdminComponents.js";

// components
import Carousel from "../../UtilityComponents/Carousel/Carousel";

// blocks

const ComponentsTest = () => {
  // ----- helper conponents ----------

  const CarouselTest = ({ title, text, imageUrl }) => {
    return (
      <div className="carousel-test">
        <span className="title">{title}</span>
        {imageUrl && <img src={imageUrl} alt="" className="image" />}
        <p className="text">{text}</p>
      </div>
    );
  };

  return (
    <Section
      title="Carousel"
      description="カルーセルコンポーネントです。カルーセルブロックやクリエイターギャラリーで使用します。"
      sectionClass="carousel-component-section"
    >
      <Carousel>
        <CarouselTest
          title="カルーセルのテストです"
          text="こんな感じのカルーセルを表示できます。"
          imageUrl={"https://picsum.photos/800"}
        />
        <CarouselTest
          title="中にはなんでも入れられる"
          text="例えば、ブロックを入れれば、これ自体がカルーセルブロックになります。"
        />
        <CarouselTest
          title="そのほかにも ..."
          text="オンボーディングや、クリエイターギャラリーやクリエイタースペース内のバナーなどにも使用します。"
        />
      </Carousel>
    </Section>
  );
};

export default ComponentsTest;
