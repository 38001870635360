import React from "react";
import "./AppFooter.scss";

// router
import { useNavigate } from "react-router-dom";

// footer buttons
import { AppFooterButtons } from "../../helpers/CreatorScreens";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// app guide
import AppGuide from "../../components/AppGuide.js";

const AppFooter = ({
  auth,
  selected,
  setCreateContentModal,
  createContentAppGuide,
}) => {

  const navigate = useNavigate();


  if (true)
    return (
      <div className="app-footer-wrapper">
        <div className="app-footer-container">
          {AppFooterButtons.map((button, index) => {
            if (button.name == "create") {
              return (
                <div
                  className="app-footer-button cursor-pointer create-button"
                  key={button.name}
                  onClick={(e) => {
                    setCreateContentModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    className="app-footer-icon"
                    icon={button.icon}
                  />
                  <AppGuide
                    condition={createContentAppGuide}
                    message={"コンテンツを作成しましょう！"}
                    font={"handwritten"}
                    pointer={"👇"}
                    styling={{
                      transform: "translate(calc(-50%), calc(-135%))",
                      flexDirection: "column",
                      left: "50%",
                    }}
                  />
                </div>
              );
            } else {
              return (
                <div
                  className={`app-footer-button ${
                    selected == button.name ? "active" : ""
                  } cursor-pointer`}
                  key={button.name}
                  onClick={(e) => {

                    const unavailableMessage = `現在開発中です。 \n---\n  ${button.display_name}画面\n${button.description} `;

                    if (button.underDevelopment) return alert(unavailableMessage);
                    if (button.onlyAdmin && !auth.authState.isAdmin) return alert(unavailableMessage);

                    navigate(button.path);
                  }}
                >
                  <FontAwesomeIcon
                    className="app-footer-icon"
                    icon={button.icon}
                  />
                  {/* <span className="button-name">{button.display_name}</span> */}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
};

export default AppFooter;
