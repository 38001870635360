import axios from "axios";
import endpoints from "./apiUrls.js";

// service worker
import {
  swMainPushSubscribe,
  showLocalNotification,
} from "../serviceWorkerRegistration.js";

export const getVapid = () => {
  return axios
    .get(`${endpoints.api}/get_vapid/`)
    .then((res) => {
      return res.data.vapid_public_key;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const checkBackendSubscription = (endpoint) => {
  return axios
    .get(`${endpoints.api}/get_main_push_subscriptions`, {
      params: {
        endpoint: endpoint,
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const mainPushSubscribe = (user_id, callback) => {

  return getVapid().then((vapid) => {
    let subscriptionOptions = {
      userVisibleOnly: true,
      applicationServerKey: vapid,
    };

    if ("serviceWorker" in navigator) {
      return navigator.serviceWorker.ready
        .then((registration) => {
          return registration.pushManager
            .subscribe(subscriptionOptions)
            .then((subscription) => {
              sendMainPushSubscriptionToServer(subscription, user_id);
              return subscription;
            })
            .catch((err) => {
              throw new Error(err);
            });
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  });


  
};

export const sendTestPush = (title, body) => {
  console.log("local notification run");
  const options = {
    body,
    // here you can add more properties like icon, image, vibrate, etc.
  };
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.showNotification(title, options);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const getPushSubscription = () => {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker.ready.then((registration) => {
      return registration.pushManager.getSubscription().then((subscription) => {
        return subscription;
      });
    });
  }
};

export const sendMainPushSubscriptionToServer = (subscription, user_id) => {
  const subscriptionData = JSON.stringify(subscription);

  console.log("sending backend, user_id: ", user_id, subscription);

  axios
    .post(`${endpoints.api}/main_push_subscription`, {
      subscription: subscriptionData,
      cognito_id: user_id,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};


export const sendMainPush = (title, body, url, cognito_id) => {
  return axios
    .post(`${endpoints.api}/send_main_push_notification`, {
      cognito_id: cognito_id,
      title: title,
      body: body,
      url: url
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export const sendMainPushAllAdmin = (title, body, url, cognito_id) => {
  return axios
    .post(`${endpoints.api}/send_main_push_all_admin`, {
      cognito_id: cognito_id,
      title: title,
      body: body,
      url: url
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}