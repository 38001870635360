import React from "react";
import "./Collapsible.scss";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Collapsible = ({
  headerSection,
  wrapperClassName,
  toggleSize = 18,
  padded = true,
  bordered = false,
  children,
  initialOpen = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(initialOpen);

  const ref = React.useRef();

  return (
    <div
      className={`collapsible-wrapper ${wrapperClassName} ${
        isOpen ? "open" : "closed"
      } ${padded? "padded" : ""}`}
    >
      <div
        className={`header-section cursor-pointer ${
          isOpen ? "open" : "close"
        } ${bordered ? "bordered" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{headerSection}</div>
        <FontAwesomeIcon
          style={{
            fontSize: toggleSize,
          }}
          icon={faChevronDown}
          className={`icon ${isOpen ? "open" : "closed"}`}
        />
      </div>

      <div
        ref={ref}
        className={`collapsible-container ${isOpen ? "open" : "closed"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
