import endpoints from "./apiUrls";
import axios from "axios";


// ----- Get Contents ---------------

export const getHomeContents = (cognitoId) => {
  return axios
    .get(`${endpoints.api}/contents`, {
      params: {
        cognito_id: cognitoId,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

