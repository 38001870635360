import React from "react";
import "./CreateContentModal.scss";

// routing
import { useNavigate } from "react-router-dom";

// overlays
import Overlay from "./Overlay.js";

// helpers
import { createContentWithSlides } from "../helpers/ContentHelper.js";

// icons
import { faPlus, faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateContentModal = ({
  auth,
  createContentModal,
  setCreateContentModal,
}) => {
  // ----- navigate ---------------
  const navigate = useNavigate();

  // ----- Data of the content to be created ---------------
  const initialContentData = {
    title: "",
    slideTitles: [
      {
        slideTitle: "はじめに",
      },
      {
        slideTitle: "",
      },
      {
        slideTitle: "",
      },
    ],
  };
  const [contentData, setContentData] = React.useState(initialContentData);

  // ----- ステップ　---------------
  const [step, setStep] = React.useState("titleStep");
  const stepData = {
    titleStep: {
      modalButtons: {
        primaryButton: {
          display: true,
          text: "次へ",
          action: (e) => {
            setStep("slideStep");
          },
        },
        secondaryButton: {
          display: true,
          text: "キャンセル",
          action: (e) => {
            setCreateContentModal(false);
          },
        },
      },
    },
    slideStep: {
      modalButtons: {
        primaryButton: {
          display: true,
          text: "作成",
          action: (e) => {
            setCreateContentModal(false);
            createContent();
          },
        },
        secondaryButton: {
          display: true,
          text: "← タイトル",
          action: (e) => {
            setStep("titleStep");
          },
        },
      },
    },
  };

  // ----- コンテンツを作成する関数 ---------------
  const createContent = () => {
    createContentWithSlides(
      auth.authState.cognito_user.username,
      contentData.title,
      contentData.slideTitles
    )
      .then((content) => {
        navigate(`/content/${content.id}`, {state: {editMode: true}})
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ----- slideTitles に display_index を付与する。
  const refreshDisplayIndex = (slideTitles) => {
    slideTitles.forEach((slideTitle, index) => {
      slideTitle.display_order = index;
    });
    return slideTitles;
  };

  // ----- モーダルが表示された時は、ステップとコンテンツデータをリセット ---------------
  React.useEffect(() => {
    setStep("titleStep");
    setContentData((prev) => ({
      ...initialContentData,
      slideTitles: refreshDisplayIndex(initialContentData.slideTitles),
    }));
  }, [createContentModal]);

  return (
    <Overlay
      open={createContentModal}
      setOpen={setCreateContentModal}
      title="新しいコンテンツ"
      buttons={stepData[step].modalButtons}
    >
      <div className="create-content-modal-wrapper">
        {step === "titleStep" && (
          <div className="form-sections">
            <div className="section">
              <div className="section-title-wrapper">
                <div className="section-title">
                  <span>タイトル</span>
                </div>
                <p className="section-description">
                  まずは、コンテンツのタイトルを決めましょう。
                  <br />
                  後から変更できます。
                </p>
              </div>
              <input
                type="text"
                className="content-title-input"
                value={contentData.title}
                placeholder="どんなタイトルにしますか？"
                onChange={(e) => {
                  setContentData((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        )}

        {step === "slideStep" && (
          <div className="form-sections">
            <div className="section">
              {contentData.title != "" && (
                <div className="content-title-preview-section">
                  <div className="content-title-preview-header">
                    コンテンツのタイトル
                  </div>
                  <span className="content-title-preview">
                    {contentData.title}
                  </span>
                </div>
              )}
            </div>

            <div className="section slide-section-wrapper">
              <div className="section-title-wrapper">
                <div className="section-title">
                  <span>コンテンツの構成</span>
                </div>
                <p className="section-description">
                  コンテンツの目次を作ってみましょう。
                  <br />
                  後から編集できますので、思いつくままに入力してみてください。
                </p>
              </div>

              <div className="slide-titles">
                {contentData.slideTitles.map((slideTitle, index) => {
                  return (
                    <div className="slide-title" key={index}>
                      <input
                        type="text"
                        value={slideTitle.slideTitle}
                        placeholder="スライドのタイトルを入力 ..."
                        onChange={(e) => {
                          var updatedSlideTitles = contentData.slideTitles;
                          updatedSlideTitles[index].slideTitle = e.target.value;
                          setContentData((prev) => ({
                            ...prev,
                            slideTitles: updatedSlideTitles,
                          }));
                        }}
                      />

                      <FontAwesomeIcon
                        className="delete-button"
                        icon={faXmark}
                        onClick={() => {
                          if (contentData.slideTitles.length === 1) {
                            alert("スライドは最低一枚必要です。");
                            return;
                          }

                          var updatedSlideTitles = contentData.slideTitles;
                          updatedSlideTitles.splice(index, 1);
                          updatedSlideTitles =
                            refreshDisplayIndex(updatedSlideTitles);
                          setContentData((prev) => ({
                            ...prev,
                            slideTitles: updatedSlideTitles,
                          }));
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="add-slide-button-wrapper cursor-pointer">
                <div
                  className="add-slide-button"
                  onClick={() => {
                    var updatedSlideTitles = contentData.slideTitles;
                    updatedSlideTitles.push({ slideTitle: "" });
                    updatedSlideTitles =
                      refreshDisplayIndex(updatedSlideTitles);
                    setContentData((prev) => ({
                      ...prev,
                      slideTitles: updatedSlideTitles,
                    }));
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>スライドを追加</span>
                </div>
              </div>

              
            </div>

            <div className="summary-section">
                <FontAwesomeIcon icon={faCheck} />
                <span>
                  {contentData.slideTitles.length} 枚のスライドを作成します。
                </span>
              </div>

          </div>
        )}
      </div>
    </Overlay>
  );
};

export default CreateContentModal;
