import React from "react";

// loading
import Loading from "../../UtilityComponents/Loading.js";

// components
import People from "./People";
import FeatureStart from "./SubScreens/FeatureStart";

// helpers
import { createCommunity, getCommunity } from "../../helpers/PeopleSuiteHelper";

// icons
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

const Community = ({ auth }) => {
  // ----- states ---------------
  const [loading, setLoading] = React.useState(true);
  const [communityData, setCommunityData] = React.useState();

  // ------ functions ----------
  const loadPeople = () => {
    setLoading(true);
    getCommunity(auth.authState.cognito_user.username)
      .then((res) => {
        console.log(res);
        setCommunityData(res.community);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // ------ Effects ----------
  React.useEffect(() => {
    // get the community data
    loadPeople();
  }, []);

  // ----- render -----------

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="community-wrapper">
      {communityData ? (
        <People people={communityData.people} reloadPeople={loadPeople} />
      ) : (
        <FeatureStart
          icon={faPeopleGroup}
          header="コミュニティを作成"
          message="コミュニティを作成して、メンバーを集め始めましょう！"
          buttonText="コミュニティを作成"
          buttonCallback={() => {
            createCommunity(auth.authState.cognito_user.username).then(
              (res) => {
                setCommunityData(res.community);
                setLoading(false);
              }
            );
          }}
        />
      )}
    </div>
  );
};

export default Community;
