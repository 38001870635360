// icons
import {
  faPlus,
  faHouse,
  // faBasketShopping,
  faLayerGroup,
  faPeopleGroup,
  faUser,
  faExpand,
  faLeftLong,
} from "@fortawesome/free-solid-svg-icons";

export const Screens = {
  home: {
    name: "home",
    display_name: "ホーム",
    description: "あなたが作成したコンテンツが一覧で表示されます。",
    path: "/",
    icon: faHouse,
  },
  spaces: {
    name: "spaces",
    display_name: "スペース (Beta)",
    description: "作成したコンテンツを「スペース」にまとめて整理しましょう。講座の提供などにもおすすめです。",
    path: "/spaces",
    icon: faExpand,
    underDevelopment: false,
  },
  community: {
    name: "community",
    display_name: "メンバー",
    description: "あなたのコンテンツを購入・閲覧したユーザーや、メンバー一覧を管理します。",
    path: "/community",
    icon: faPeopleGroup,
    underDevelopment: false,
    onlyAdmin: true
  },
  profile: {
    name: "profile",
    display_name: "プロフィール",
    description: "あなたのPulpクリエイタープロフィールの管理や設定を行います。",
    path: "/profile",
    icon: faUser,
    underDevelopment: false,
  },
};


export const AppFooterButtons = [
  Screens.home,
  Screens.spaces,

  {
    name: "create",
    icon: faPlus,
  },

  Screens.community,
  Screens.profile,
];