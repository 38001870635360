import React from "react";
import "../components/Authentication.scss";

// enpoints
import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// routes
import { useLocation, useNavigate } from "react-router-dom";

// auth
import { Auth } from "aws-amplify";
import { resendConfirmation, confirmUser } from "../helpers/authentication.js";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

// components
import AuthenticationWrapper from "../components/AuthenticationWrapper.js";

const Confirm = ({ auth }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [redirectToHome, setRedirectToHome] = React.useState(false);


  const email = location.state?.email;

  if (!email) {
    navigate("/login");
  }

  const { authState, setAuthState } = auth;

  const [isConfirming, setIsConfirming] = React.useState(false);

  const [code, setCode] = React.useState("");
  const [masterError, setMasterError] = React.useState({
    isErrored: false,
    errorMessage: "エラーが発生しました。",
  });

  const resendCode = () => {
    resendConfirmation(email, true);
  };

  const validateCode = () => {
    console.log(authState);
    confirmUser(auth, email, code, masterError, setMasterError, setRedirectToHome);
  };

  React.useEffect(() => {
    if (redirectToHome) {
      navigate("/")
    }
  }, [redirectToHome])

  React.useEffect(() => {
    if (auth.authState.cognito_user?.attributes?.email_verified) {
      // setRedirectToHome(true);
      navigate("/")
    }
  }, [auth])

  return (
    <AuthenticationWrapper
      header="アカウントの有効化"
      comment="メールで届いた認証コードを入力してください。"
      appendix={{
        contactLink: true,
      }}
    >
      <div className="forms-wrapper">
        <div className="authentication-form-section">
          <span className="form-title">認証コード</span>
          <input
            type="text"
            className="form"
            value={code}
            placeholder="コードを入力"
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="button-section">
        <div
          className={`button ${code == "" ? "disabled" : ""}`}
          onClick={() => {
            if (code != "") {
              validateCode();
            }
          }}
        >
          <span>{!isConfirming ? "認証する" : "認証しています..."}</span>
        </div>

        {masterError.isErrored && masterError.errorMessage != "" && (
          <div className="masterErrorMessage">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span>{masterError.errorMessage}</span>
          </div>
        )}

        <div
          className="below-button-message link"
          onClick={() => {
            resendCode();
          }}
        >
          <span>認証コードを再送</span>
        </div>
      </div>
    </AuthenticationWrapper>
  );
};

export default Confirm;
