import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  url,
  title,
  description = "",
  imageUrl,
  type = "article",
}) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      {title && <title>{title}</title>}
      <meta name="description" content={description} />
      {/* End standard metadata tags */}

      {/* Facebook tags */}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:type" content={type} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {title && <meta property="og:title" content={title} />}
      <meta property="og:description" content={description} />

      {/* End Facebook tags */}

      {/* Twitter tags */}
      {title && <meta name="twitter:title" content={title} />}
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta property="twitter:image" content={imageUrl} />}

      {/* End Twitter tags */}
    </Helmet>
  );
};

export default SEO;
