import React from "react";
import "./ContentComponents.scss";
import "./FullVideoContent.scss";

// helpers
import uploadFileToMux from "../helpers/MuxUploadForBlocks.js";

// for streaming
// import Hls from "hls.js";
import MuxVideo from "@mux/mux-video-react";

// uploading guage
import Uploading from "../components/Uploading.js";

// helper
import { resizeFieldHeights } from "../helpers/ContentHelper.js";

const FullVideoContent = ({
  blockData,
  currentSlideIndex,
  slidesData,
  updateSlidesData,
  index,
  editMode,
  updateBlock,
}) => {
  const data = blockData.child_data;
  const placeHolderImage = "/images/placeholder_image.jpg";

  const [uploadStatus, setUploadStatus] = React.useState({
    uploading: false,
    upload_progress: 0,
    message: "",
  });

  const inputFile = React.useRef(null);
  const captionRef = React.useRef(null);

  // streaming functions
  const [localVideoUrl, setLocalVideoUrl] = React.useState(data.url);

  React.useEffect(() => {
    if (inputFile?.current?.files?.[0]) {
      const url = window.URL.createObjectURL(inputFile?.current?.files?.[0]);
      setLocalVideoUrl(url);
    }
  }, [inputFile?.current?.files]);

  React.useEffect(() => {
    setTimeout(() => {
      resizeFieldHeights([captionRef]);
    }, 500);
  }, [editMode, captionRef.current]);


  if (!editMode) {
    return (
      <div className={`component-wrapper full-video-block-wrapper`}>
        <MuxVideo
          className={`full-video ${data.cropped ? "cropped" : ""}`}
          src={localVideoUrl}
          playbackId={data.mux_playback_id}
          metadata={{
            video_id: data.mux_asset_id,
            video_title: "Video",
          }}
          streamtype="on-demand"
          loop
          controls
          autoPlay
          muted
          playsInline
          poster={
            data.mux_playback_id
              ? `https://image.mux.com/${data.mux_playback_id}/thumbnail.jpg`
              : placeHolderImage
          }
        />
      </div>
    );
  } else {
    return (
      <div className="component-wrapper full-video-block-wrapper edit-mode">
        <div className="edit-video-wrapper">
          <MuxVideo
            className={`full-video ${data.cropped ? "cropped" : ""}`}
            src={localVideoUrl}
            playbackId={data.mux_playback_id}
            metadata={{
              video_id: data.mux_asset_id,
              video_title: "Video",
            }}
            streamtype="on-demand"
            onClick={() => {
              inputFile.current.click();
            }}
            loop
            // controls
            autoPlay
            muted
            playsInline
            poster={
              data.mux_playback_id
                ? `https://image.mux.com/${data.mux_playback_id}/thumbnail.jpg`
                : placeHolderImage
            }
          />
          <input
            type="file"
            accept="video/*"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(e) =>
              uploadFileToMux(
                e.target,
                index,
                blockData,
                updateBlock,
                setUploadStatus
              )
            }
            id="file-picker"
            name="file-picker"
          />
          <div className="edit-aspect-ratio-section">
            {!data.cropped ? (
              <span className="aspect-message">画面いっぱいに表示します</span>
            ) : (
              <span className="aspect-message">
                動画全体が画面に収まるように表示します
              </span>
            )}
            <div
              className={`aspect-button ${!data.cropped ? "vertical" : ""} cursor-pointer`}
              onClick={() => {

                updateBlock([
                  {
                    blockIndex: index,
                    value: !data.cropped,
                    key: "cropped",
                  },
                ]);
              }}
            ></div>
          </div>
        </div>
        {uploadStatus.uploading && (
          <Uploading
            message={uploadStatus.message}
            percentage={uploadStatus.upload_progress}
          />
        )}

        {uploadStatus.uploading == false &&
          data.mux_playback_id &&
          data.ready == false && (
            <Uploading
              message="動画が再生されない場合は、最適化処理中です。"
              guage={false}
              seethrough={true}
            />
          )}

        <textarea
          ref={captionRef}
          className="caption"
          placeholder="キャプションを入力"
          value={data.caption.replace(/\n$/, "")}
          onChange={(e) => {
            updateBlock([
              {
                blockIndex: index,
                value: e.target.value.replace(/\n$/, ""),
                key: "caption",
              },
            ]);

            resizeFieldHeights([captionRef]);
          }}
        >
          {data.caption}
        </textarea>
      </div>
    );
  }
};

export default FullVideoContent;
