import React from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

// styling
import "./ContentView.scss";

// SEO
import SEO from "../UtilityComponents/SEO.js";

// Detect Swipe Wrapper
import SlideNavigation from "../UtilityComponents/SlideNavigation";

// App コンポーネント
import AppHeader from "../screens/CreatorScreens/AppHeader.js";
import ContentHeader from "./ContentHeader.js";
import ContentFooter from "./ContentFooter.js";

// コンテンツコンポーネント
import ContentWelcome from "./ContentWelcome.js";

// スライドコンポーネント
import SlideCover from "./SlideCover";
import SlideHeader from "./SlideHeader.js";

// ブロックコンポーネント
import ContentBlock from "./ContentBlock.js";

// 編集系コンポーネント
import SlideEditMenu from "./SlideEditMenu.js";
import AddBlockButton from "./AddBlockButton.js";

// モーダル系
import Overlay from "../Overlays/Overlay.js";
import AddBlockOverlay from "../Overlays/AddBlockOverlay.js";
import TableOfContentsModal from "../Overlays/TableOfContentsModal.js";

// QR
import { QRCode } from "react-qrcode-logo";

// helpers
import { copyToClipboard } from "../helpers/Utility.js";
import {
  sampleContentData,
  retrieveContentData,
  complexityMessage,
  cardTypes,
  addNewSlide,
  removeSlide,
  updateChanges,
  createNewBlock,
  removeBlock,
  reorderBlocks,
  isParentSlide,
} from "../helpers/ContentHelper";
import { framerFadeUp, framerSlideIn } from "../helpers/Utility.js";
import {
  blockWrapperColor,
  getSlideColors,
  calculateSlideColors,
} from "../helpers/Colors.js";

// icons
import {
  faLink,
  faPenToSquare,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// motion
import { motion, AnimatePresence } from "framer-motion";

// guide
import AppGuide from "../components/AppGuide.js";

// ------ View --------------------

const ContentView = ({ auth }) => {
  // ----- constants ----------
  const contentMode = "horizontal"; // vertical or horizontal

  // ----- getting basic data ---------------
  let { contentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // ----- defining content data states ---------------
  const [contentData, setContentData] = React.useState();
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const [slideComplexity, setSlideComplexity] = React.useState(0);
  const [isLastSlide, setIsLastSlide] = React.useState();
  const [isFirstSlide, setIsFirstSlide] = React.useState();

  // ------ form block answer states -----------
  const [formState, setFormState] = React.useState({})
  const viewershipStates = {
    form: {formState, setFormState}
  }

  // ----- defining scroll related data ----------------
  const [isScrolling, setIsScrolling] = React.useState(false);

  // ----- defining color related states ----------------
  const [slideColors, setSlideColors] = React.useState(undefined);

  // ----- defining some ux related states ----------------
  const [focusId, setFocusId] = React.useState(undefined);

  // ----- defining modes ---------------
  // sample mode
  const [sampleMode, setSampleMode] = React.useState(
    searchParams.get("sample") ? true : false
  );

  // edit mode
  const [editMode, setEditMode] = React.useState(
    location.state?.editMode ? true : false
  );

  // creator mode
  const [creatorMode, setCreatorMode] = React.useState(false);

  // welcome screen (content title and description)
  const [welcomeScreen, setWelcomeScreen] = React.useState();

  // slide cover screen
  const [slideCover, setSlideCover] = React.useState(true);

  // saving
  const [saving, setSaving] = React.useState(false);

  // ----- QR Modal ---------------
  const [qrOpen, setQrOpen] = React.useState(false);

  // ----- Chapter Modal --------------
  const [chapterModal, setChapterModal] = React.useState(false);

  // ----- defining block to add data structure ---------------
  const [addBlockData, setAddBlockData] = React.useState({
    modalOpen: false,
    indexToAdd: null,
  });

  // ----- Control functions ---------------

  const changeSlide = (direction) => {
    switch (direction) {
      case "next":
        if (isLastSlide == true) return;
        setCurrentSlideIndex(currentSlideIndex + 1);
        break;
      case "prev":
        if (isFirstSlide == true) return;
        setCurrentSlideIndex(currentSlideIndex - 1);
        break;
    }
  };

  // ----- functions ---------------

  const getSlideIndex = (slideId, slides) => {
    const slideIndex = slides.findIndex((slide) => slide.id == slideId);
    if (slideIndex >= 0) {
      return slideIndex;
    } else {
      return undefined;
    }
  };

  const getContentData = (
    slideIndexAfterLoad = 0,
    initialSlideId = undefined
  ) => {
    // if it's a sample, insert sample data
    if (sampleMode) {
      setContentData(sampleContentData(contentId));
      return;
    }

    retrieveContentData(contentId)
      .then((content) => {
        setEdited(false);
        setContentData(content);

        // set slide index
        const slideIndex = getSlideIndex(initialSlideId, content.slides);
        if (slideIndex) {
          setCurrentSlideIndex(slideIndex);
        } else {
          setCurrentSlideIndex(slideIndexAfterLoad);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 新規ブロックを追加する。
  const addBlock = (
    type,
    indexToAdd = addBlockData.indexToAdd,
    defalutValue = {}
  ) => {
    const { block_default_value, child_default_value } = defalutValue;

    createNewBlock(
      type,
      indexToAdd,
      contentData.slides[currentSlideIndex].id,
      auth,
      contentId,
      block_default_value,
      child_default_value
    ).then((block_id) => {
      setAddBlockData({
        modalOpen: false,
        indexToAdd: null,
      });

      getContentData(currentSlideIndex);

      if (type == "text") {
        setFocusId(`block-${block_id}-header`);
      } else {
        setFocusId(`block-${block_id}`);
      }
    });
  };

  // ブロックを削除する
  const deleteBlock = (blockId) => {
    removeBlock(
      blockId,
      contentData.slides[currentSlideIndex].id,
      auth,
      contentId
    ).then((res) => {
      getContentData(currentSlideIndex);
    });
  };

  // 新しいスライドを追加する
  const addSlide = (position, skipConfirm) => {
    if (!skipConfirm) {
      if (!window.confirm("スライドを追加しますか？")) {
        return;
      }
    }

    var indexToAdd = 0;

    switch (position) {
      case "prev":
        indexToAdd = currentSlideIndex;
        break;
      case "next":
        indexToAdd = currentSlideIndex + 1;
        break;
    }

    addNewSlide(indexToAdd, currentSlideIndex, auth, contentId)
      .then((res) => {
        getContentData(indexToAdd);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // スライドを削除する
  const deleteSlide = () => {
    if (contentData.slides.length == 1) {
      alert("最後のスライドは削除できません。");
      return;
    }

    if (!window.confirm("このスライドを削除しますか？")) {
      return;
    }

    removeSlide(contentData.slides[currentSlideIndex].id, auth, contentId)
      .then((res) => {
        getContentData(Math.max(currentSlideIndex - 1, 0));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 変更を保存する
  const saveContentData = () => {
    console.log(contentData);
    setSaving(true);
    updateChanges(
      contentData.slides[currentSlideIndex],
      auth,
      contentId,
      contentData
    ).then((res) => {
      console.log("change saved.");
      const messageTimer = setTimeout(() => {
        setSaving(false);
      }, 3000);
    });
  };

  // ----- effects ---------------

  // データを取得する
  React.useEffect(() => {
    // スライドの指定があれば、そのスライドを開く指定をする
    const initialSlideId = searchParams.get("slideId");
    if (initialSlideId) {
      setWelcomeScreen(false);
      getContentData(undefined, initialSlideId);
    } else {
      setWelcomeScreen(true);
      getContentData();
    }
  }, [contentId, searchParams]);

  // set creator (of the content) mode.
  React.useEffect(() => {
    if (auth.authState.cognito_user && contentData) {
      if (
        !sampleMode &&
        contentData.creator_id == auth.authState.cognito_user.username
      ) {
        setCreatorMode(true);
      } else {
        setCreatorMode(false);
      }
    }
  }, [contentData, auth]);

  // set welcome screen
  React.useEffect(() => {
    if (contentData) {
      if (
        contentData.display_cover &&
        contentData.slides.length > 1 &&
        welcomeScreen != false
      ) {
        // 表紙設定が true で、スライドが2枚以上あり、かつすでに表示された後で false になっていなければ、表示する
        setWelcomeScreen(true);
      } else {
        setWelcomeScreen(false);
      }
    }
  }, [contentData]);

  // 変更を保存する
  const [edited, setEdited] = React.useState(false); //　変更があったかどうか
  React.useEffect(() => {
    if (contentData == undefined) {
      return;
    }

    if (!edited) {
      setEdited(true);
    } else {
      // apiコールが多くなりすぎないよう、一定時間変更がなければ保存する
      const timer = setTimeout(() => {
        saveContentData();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [contentData]);

  // スライドが変わった時トップに戻る
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [currentSlideIndex]);

  // スライドが変わった時スライドカバーを表示する
  React.useEffect(() => {
    setSlideCover(true);
  }, [currentSlideIndex]);

  // スライドが変わった時、スライドの位置を計算する
  React.useEffect(() => {
    if (contentData != undefined) {
      setIsLastSlide(currentSlideIndex >= contentData.slides.length - 1);
      setIsFirstSlide(currentSlideIndex <= 0);
    }
  }, [currentSlideIndex, contentData]);

  // スライドが変わった時、あるいはスライド内容が変わった時に色を計算する
  React.useEffect(() => {
    // 色を計算する
    if (contentData != undefined) {
      // 表紙が表示の場合は、スライド1枚目の色を使う
      const bgColor =
        contentData.slides[welcomeScreen ? 0 : currentSlideIndex].theme_color;
      const slideColorsResult = calculateSlideColors(bgColor);
      setSlideColors(slideColorsResult);
    }
  }, [contentData, currentSlideIndex, welcomeScreen]);

  // フォーカスを当てる
  React.useEffect(() => {
    if (focusId != undefined) {
      const focusOn = document.getElementById(focusId);
      if (document.activeElement != focusOn) {
        focusOn.focus();
        focusOn.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setFocusId(undefined);
    }
  }, [contentData, setFocusId]);

  // ----- rendering ---------------

  if (contentData == undefined || contentData?.slides?.length == 0) {
    return (
      <div className="loading-wrapper">
        <motion.div
          variants={framerFadeUp}
          initial="hidden"
          animate="show"
          transition={framerFadeUp.transition}
          className="loading-message"
        >
          <span>コンテンツをロードしています ...</span>
        </motion.div>
      </div>
    );
  } else {
    return (
      <>
        {/* SEO */}
        <SEO
          url={window.location.href}
          title={contentData.title}
          imageUrl={
            contentData.thumbnail_image_url && contentData.thumbnail_image_url
          }
          description={contentData.content_description}
        />

        {/* CONTENT */}

        {(() => {
          if (welcomeScreen) {
            return (
              <ContentWelcome
                contentData={contentData}
                setWelcomeScreen={setWelcomeScreen}
                slideColors={slideColors}
                setCurrentSlideIndex={setCurrentSlideIndex}
              />
            );
          } else {
            return (
              <>
                <ContentHeader
                  visibility={!isScrolling}
                  editMode={editMode}
                  contentData={contentData}
                  slideData={contentData.slides[currentSlideIndex]}
                  slideColors={slideColors}
                  setChapterModal={setChapterModal}
                  display={!editMode}
                  auth={auth}
                  setWelcomeScreen={setWelcomeScreen}
                />
                <SlideNavigation
                  mode={contentMode}
                  handleNavigation={(direction) => {
                    changeSlide(direction);
                  }}
                  setIsScrolling={setIsScrolling}
                  enabled={!editMode}
                  isFirstSlide={isFirstSlide}
                  isLastSlide={isLastSlide}
                >
                  <div
                    className={`slide-view-wrapper ${
                      editMode ? "edit-mode" : ""
                    }`}
                    style={
                      !editMode
                        ? {
                            background:
                              contentData.slides[currentSlideIndex].theme_color,
                          }
                        : {}
                    }
                  >
                    <div
                      className={`slide-contents-wrapper ${
                        contentData.slides[currentSlideIndex].full_contents
                          ? "full-screen"
                          : ""
                      }`}
                    >
                      <div
                        className={`slide-contents-window ${
                          editMode ? "edit-mode" : ""
                        }`}
                        style={
                          !editMode
                            ? {
                                // color:
                                //   contentData.slides[currentSlideIndex]
                                //     .font_color,
                                color: (() => {
                                  if (slideColors) {
                                    return slideColors.legibleColor;
                                  } else {
                                    return contentData.slides[currentSlideIndex]
                                      .font_color;
                                  }
                                })(),
                              }
                            : {
                                color:
                                  contentData.slides[currentSlideIndex]
                                    .font_color,
                                background:
                                  contentData.slides[currentSlideIndex]
                                    .theme_color,
                              }
                        }
                      >
                        <SlideEditMenu
                          editMode={editMode}
                          slidesData={contentData}
                          deleteSlide={deleteSlide}
                          currentSlideIndex={currentSlideIndex}
                          setCurrentSlideIndex={setCurrentSlideIndex}
                          setSlidesData={setContentData}
                        />

                        <div className="slide-contents">
                          <SlideHeader
                            editMode={editMode}
                            addSlide={addSlide}
                            slidesData={contentData}
                            currentSlideIndex={currentSlideIndex}
                            setCurrentSlideIndex={setCurrentSlideIndex}
                            setSlidesData={setContentData}
                            slideComplexity={slideComplexity}
                            complexityMessage={complexityMessage}
                            saving={saving}
                          />

                          {slideCover &&
                          editMode == false &&
                          contentData.slides.length > 1 &&
                          contentData.slides[currentSlideIndex].blocks.length >
                            0 &&
                          contentData.slides[currentSlideIndex].title != "" ? (
                            <SlideCover
                              contentData={contentData}
                              slideData={contentData.slides[currentSlideIndex]}
                              duration={1500}
                              setSlideCover={setSlideCover}
                              slideColors={slideColors}
                            />
                          ) : (
                            <div
                              className={`slide-blocks ${
                                editMode ? "edit-mode" : ""
                              }`}
                            >
                              {(() => {
                                if (
                                  contentData.slides[currentSlideIndex].blocks
                                    .length == 0
                                ) {
                                  if (
                                    !editMode &&
                                    contentData.slides.length >
                                      currentSlideIndex + 1 &&
                                    isParentSlide(
                                      contentData.slides,
                                      contentData.slides[currentSlideIndex].id
                                    )
                                  ) {
                                    return (
                                      <div className="slide-title-for-empty-slide">
                                        <span
                                          className="slide-title"
                                          style={{
                                            borderBottom: `2px dashed ${
                                              slideColors
                                                ? slideColors.legibleColor
                                                : contentData.slides[
                                                    currentSlideIndex
                                                  ].font_color
                                            }`,
                                          }}
                                        >
                                          {
                                            contentData.slides[
                                              currentSlideIndex
                                            ].title
                                          }
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <span className="no-blocks">
                                        まだブロックがありません。
                                      </span>
                                    );
                                  }
                                }
                              })()}

                              {false &&
                                contentData.slides[currentSlideIndex].blocks
                                  .length == 0 && (
                                  <span className="no-blocks">
                                    まだブロックがありません。
                                  </span>
                                )}

                              <AppGuide
                                condition={
                                  editMode &&
                                  contentData.slides[currentSlideIndex].blocks
                                    .length < 1
                                }
                                message={
                                  '💡 "+" からブロックを追加してみましょう！'
                                }
                                pointer={"👆"}
                                reverse={true}
                                animation={"vertical"}
                                styling={{
                                  flexDirection: "column",
                                  left: "50%",
                                  transform:
                                    "translate(calc(-50%), calc(100% + 20px))",
                                }}
                              />

                              <AddBlockButton
                                getContentData={getContentData}
                                editMode={editMode}
                                slidesData={contentData}
                                slideColors={slideColors}
                                currentSlideIndex={currentSlideIndex}
                                setAddBlockData={setAddBlockData}
                                blockIndex={0}
                              />

                              {contentData.slides[currentSlideIndex].blocks
                                .sort(
                                  (a, b) => a.display_order - b.display_order
                                )
                                .map((block, blockIndex) => {
                                  return (
                                    <React.Fragment key={`content-${block.id}`}>
                                      <ContentBlock
                                        editMode={editMode}
                                        cardTypes={cardTypes}
                                        block={block}
                                        slidesData={contentData}
                                        currentSlideIndex={currentSlideIndex}
                                        setSlidesData={setContentData}
                                        slideColors={slideColors}
                                        blockIndex={blockIndex}
                                        addBlock={addBlock}
                                        addBlockData={addBlockData}
                                        setAddBlockData={setAddBlockData}
                                        deleteBlock={deleteBlock}
                                        reorderBlocks={reorderBlocks}
                                        viewershipStates={viewershipStates}
                                      />
                                      <AddBlockButton
                                        getContentData={getContentData}
                                        editMode={editMode}
                                        slidesData={contentData}
                                        slideColors={slideColors}
                                        currentSlideIndex={currentSlideIndex}
                                        setAddBlockData={setAddBlockData}
                                        blockIndex={blockIndex + 1}
                                      />
                                    </React.Fragment>
                                  );
                                })}
                            </div>
                          )}

                          {false &&
                            contentData.slides[currentSlideIndex]
                              .full_contents == false &&
                            currentSlideIndex <
                              contentData.slides.length - 1 && (
                              <motion.div
                                className="next-button"
                                variants={framerFadeUp}
                                initial="hidden"
                                animate="show"
                                transition={{
                                  ...framerFadeUp.transition,
                                  delay:
                                    0.2 * (contentData.slides.length + 1) + 2,
                                }}
                                key={`next-${currentSlideIndex}`}
                                style={{
                                  borderColor:
                                    contentData.slides[currentSlideIndex]
                                      .font_color,
                                }}
                                onClick={() => {
                                  if (editMode == false) {
                                    setCurrentSlideIndex(currentSlideIndex + 1);
                                  }
                                }}
                              >
                                <span
                                  style={{
                                    color:
                                      contentData.slides[currentSlideIndex]
                                        .font_color,
                                  }}
                                >
                                  つぎへ　👉
                                </span>
                              </motion.div>
                            )}
                        </div>
                      </div>

                      {false && (
                        <div className="content-buttons">
                          {!editMode && (
                            <div
                              className="content-button copy-link"
                              onClick={() => {
                                copyToClipboard(
                                  `${window.location.origin}/content/${contentData.id}?slideId=${contentData.slides[currentSlideIndex].id}`,
                                  true,
                                  "スライドのリンクをコピーしました。"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faLink} />
                            </div>
                          )}

                          {creatorMode && (
                            <div
                              className={`content-button edit-mode-button ${
                                editMode ? "edit-mode" : ""
                              }`}
                              onClick={() => {
                                setEditMode(!editMode);
                              }}
                            >
                              {!editMode ? (
                                <FontAwesomeIcon icon={faPenToSquare} />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {/* ----- Overlayes for content --------------- */}

                      <AddBlockOverlay
                        auth={auth}
                        contentData={contentData}
                        currentSlideIndex={currentSlideIndex}
                        addBlockData={addBlockData}
                        setAddBlockData={setAddBlockData}
                        addBlock={addBlock}
                      />

                      <Overlay
                        open={qrOpen}
                        setOpen={setQrOpen}
                        title={"QRコード"}
                        buttons={{
                          primaryButton: {
                            display: false,
                            text: "",
                            action: (e) => {},
                          },
                          secondaryButton: {
                            display: true,
                            text: "閉じる",
                            action: (e) => {
                              setQrOpen(false);
                            },
                          },
                        }}
                      >
                        <div className="qr-wrapper">
                          <QRCode
                            className="qr-code"
                            value={`${window.location.origin}/content/${contentData.id}?slideId=${contentData.slides[currentSlideIndex].id}`}
                            fgColor="#4abde1"
                            logoImage={"/logo/blocks_icon_theme.png"}
                            logoPadding={10}
                            logoPaddingStyle={"circle"}
                            qrStyle={"dots"}
                            eyeRadius={10}
                            ecLevel={"Q"}
                            id={"qr-image"}
                          />
                        </div>
                      </Overlay>

                      <TableOfContentsModal
                        editMode={editMode}
                        chapterModal={chapterModal}
                        setChapterModal={setChapterModal}
                        contentData={contentData}
                        setContentData={setContentData}
                        currentSlideIndex={currentSlideIndex}
                        setCurrentSlideIndex={setCurrentSlideIndex}
                      />
                    </div>
                  </div>
                </SlideNavigation>
                <ContentFooter
                  visibility={!isScrolling}
                  creatorMode={creatorMode}
                  currentSlideIndex={currentSlideIndex}
                  setCurrentSlideIndex={setCurrentSlideIndex}
                  contentData={contentData}
                  slideData={contentData.slides[currentSlideIndex]}
                  slideColors={slideColors}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setQrOpen={setQrOpen}
                  setChapterModal={setChapterModal}
                />
              </>
            );
          }
        })()}
      </>
    );
  }
};

export default ContentView;
