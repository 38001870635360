import React from "react";
import "./ContentFooterButtons.scss";

// icons
import {
  faLink,
  faPen,
  faXmark,
  faQrcode,
  faBarsStaggered,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Overlay from "../Overlays/Overlay";

// utility
import { copyToClipboard } from "../helpers/Utility";

const ContentFooterButtons = ({
  contentData,
  slideData,
  creatorMode,
  editMode,
  setEditMode,
  setQrOpen,
  setChapterModal,
}) => {
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const openChapterModal = () => {
    setChapterModal(true);
  };

  if (!editMode) {
    return (
      <div className={`content-footer-buttons ${editMode ? "edit-mode" : ""}`}>
        {/* ----- Creator Buttons ----------------- */}
        {creatorMode && (
          <>
            <div
              className="button"
              onClick={() => {
                toggleEditMode();
              }}
            >
              <FontAwesomeIcon icon={faPen} className="button-icon" />
              <span className="button-title">編集</span>
            </div>

            <div
              className="separator"
              style={{
                backgroundColor: slideData.font_color,
              }}
            ></div>

            <div
              className="button"
              onClick={() => {
                copyToClipboard(
                  `${window.location.origin}/content/${contentData.id}?slideId=${slideData.id}`,
                  true,
                  "スライドのリンクをコピーしました。"
                );
              }}
            >
              <FontAwesomeIcon icon={faLink} className="button-icon" />
            </div>

            {false && (
              <div
                className="button"
                onClick={() => {
                  setQrOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faQrcode} className="button-icon" />
              </div>
            )}
          </>
        )}

        {/* ----- User Buttons ----------------- */}
        {!creatorMode && (
          <>
            {false && (
              <div
                className="button chapter"
                onClick={() => {
                  openChapterModal();
                }}
              >
                <FontAwesomeIcon
                  icon={faBarsStaggered}
                  className="button-icon"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className={`content-footer-buttons ${editMode ? "edit-mode" : ""}`}>
        <div
          className="button"
          onClick={() => {
            toggleEditMode();
          }}
        >
          <FontAwesomeIcon icon={faXmark} className="button-icon" />
          <span className="button-title">編集を終了</span>
        </div>

        <div
          className={`button chapter ${editMode ? "edit-mode" : ""}`}
          onClick={() => {
            openChapterModal();
          }}
        >
          <FontAwesomeIcon icon={faBarsStaggered} className="button-icon" />
        </div>
      </div>
    );
  }
};

export default ContentFooterButtons;
