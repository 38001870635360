import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";

// react helmet
import { HelmetProvider } from "react-helmet-async";

// helpers
import isDev from "./helpers/DevDetect";

import { Amplify } from "aws-amplify";

import posthog from "posthog-js";

import App from "./App.js";

// service worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import swConfig from "./swConfig";

// amplify configure
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_S3_REGION, //OPTIONAL -  Amazon service region
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    },
  },
});

// post hog init

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: "https://app.posthog.com",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// service worker
serviceWorkerRegistration.register(swConfig);
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
