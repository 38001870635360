import endpoints from "./apiUrls";
import axios from "axios";

// ------ communitites ---------
export const getCommunity = (cognito_id) => {
  return axios
    .get(`${endpoints.api}/get_community`, {
      params: {
        cognito_id: cognito_id,
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export const createCommunity = (cognito_id) => {
  return axios
    .post(`${endpoints.api}/communities`, {
      cognito_id: cognito_id,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

// delete person

export const deletePersonData = (personId) => {
  return axios
    .delete(`${endpoints.api}/people/${personId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}


// ----- form block -------------
export const submitFormBlockData = (formData) => {
  return axios
    .post(`${endpoints.api}/form_block_submit`, formData)
}