import axios from "axios";
import endpoints from "./apiUrls.js";


// ------- Public functions ---------------
export const getBasket = (basketId) => {
  return axios
    .get(`${endpoints.api}/baskets/${basketId}`)
    .then((res) => {
      return res.data;
    })
}


// ----- Creators functions ---------------

export const getBaskets = (userId) => {
  return axios
    .get(`${endpoints.api}/baskets`, {
      params: {
        cognito_id: userId,
      },
    })
    .then((res) => {
      return res.data;
    })
};

export const getBasketContents = (cognitoId, basketData) => {
  return axios
    .get(`${endpoints.api}/edit_basket`, {
      params: {
        cognito_id: cognitoId,
        basket_id: basketData ? basketData.id : null,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateBasket = (cognitoId, basketData, basketContents) => {
  console.log(cognitoId, basketData, basketContents);
  return axios
    .post(`${endpoints.api}/update_basket`, {
      cognito_id: cognitoId,
      basket_data: basketData,
      basket_contents: basketContents,
    })
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteBasket = (userId, basketId) => {
  return axios
    .delete(`${endpoints.api}/baskets/${basketId}`, {
      params: {
        cognito_id: userId,
      },
    })
    .then((res) => {
      return res.data;
    });
};

// ----- space link --------------
export const generateSpaceLink = (spaceId) => {
  return `${window.location.origin}/space/${spaceId}`
}
