import React from "react";
import "./SlideNavigation.scss";

// components
import ProgressGuage from "./ProgressGuage.js";

// helpers
import {
  handleTouchMove,
  handleTouchEnd,
} from "../helpers/SlideNavigationHelper";

import AnimatePresenceWrapper from "./AnimatePresenceWrapper";

const SlideNavigation = ({
  mode, // "horizontal" or "vertical"
  handleNavigation,
  setIsScrolling,
  enabled,
  children,
  isLastSlide,
  isFirstSlide,
}) => {
  // ------ Refs ---------------

  const heightRef = React.useRef(null);

  // ----- Key Functions ---------------

  // arrow key to change slide
  React.useEffect(() => {
    if (enabled) {
      const keyDownHandler = (e) => {
        switch (e.key) {
          case "ArrowRight":
            e.preventDefault();
            handleNavigation("next");
            break;
          case "ArrowLeft":
            e.preventDefault();
            handleNavigation("prev");
            break;
        }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }
  }, [children]);

  // ----- Touch Functions ---------------

  const [navigationGuageData, setNavigationGuageData] = React.useState({
    percentage: 0,
    position: "top",
  });
  const [distanceWhenBoudaryReachedAt, setDistanceWhenBoudaryReachedAt] =
    React.useState();

  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);
  const [isChildSwipe, setIsChildSwipe] = React.useState(false); // 子要素にoverflowが設定されている場合 (明示的に [data-avoid-slide-swipe]　属性を付与する必要あり)、このwrapperでのスワイプはキャンセル

  const onTouchStart = (e) => {
    if (enabled) {
      if (e.target.closest("[data-avoid-slide-swipe]")) {
        setIsChildSwipe(true);
        return;
      }

      setIsScrolling(true);
      setTouchEnd(null);
      setTouchStart({
        x: e.targetTouches[0].clientX,
        y: e.targetTouches[0].clientY,
      });
    }
  };

  const onTouchMove = (e) => {
    if (enabled) {
      handleTouchMove(
        e,
        touchStart,
        touchEnd,
        setTouchEnd,
        mode,
        heightRef,
        distanceWhenBoudaryReachedAt,
        (progressData) => {
          setNavigationGuageData(progressData);
        },
        (distance) => {
          setDistanceWhenBoudaryReachedAt(distance);
        },
        resetScroll
      );

    
    }
  };

  const onTouchEnd = (e) => {
    if (enabled) {
      setIsScrolling(false);
      if (isChildSwipe) {
        setIsChildSwipe(false);
        return;
      }

      resetScroll();

      
      handleNavigation(handleTouchEnd(touchStart, touchEnd, mode, navigationGuageData));
    }
  };

  const resetScroll = () => {
    setDistanceWhenBoudaryReachedAt(null);
    setNavigationGuageData({ percentage: 0, position: "top" });
  }

  // ----- Render ---------------

  return (
    <div
      ref={heightRef}
      className="detect-swipe-wrapper"
      onTouchStart={enabled ? onTouchStart : () => {}}
      onTouchMove={enabled ? onTouchMove : () => {}}
      onTouchEnd={enabled ? onTouchEnd : () => {}}
    >
      <div className="height-container">{children}</div>

      <AnimatePresenceWrapper
        visibility={
          navigationGuageData.percentage > 0 &&
          ((navigationGuageData.position == "top" && !isFirstSlide) ||
            (navigationGuageData.position == "bottom" && !isLastSlide))
        }
      >
        <div className={`navigation-progress ${navigationGuageData.position}`}>
          <ProgressGuage
            size={30}
            strokeWidth={4}
            progress={navigationGuageData.percentage}
            color={navigationGuageData.percentage < 1 ? "gray" : "orange"}
          />
          <span className="message">
            {navigationGuageData.position == "top"
              ? "前のスライド"
              : "次のスライド"}
          </span>
        </div>
      </AnimatePresenceWrapper>
    </div>
  );
};

export default SlideNavigation;
