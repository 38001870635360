import React from "react";
import "./ContentList.scss";

// router
import { useNavigate } from "react-router-dom";

// endpoint
import endpoints from "../../helpers/apiUrls";

// admin components
import {
  Section,
  Status,
  Input,
  TextArea,
  Button,
} from "./AdminComponents/AdminComponents.js";

// components
import Collapsible from "../../UtilityComponents/Collapsible";
import ContentList from "../../HomeComponents/ContentList";

// helpers
import { get_contents_list } from "../../helpers/adminScreenHelper";
import { formatDate } from "../../helpers/Utility";

const ContentsList = ({ auth }) => {
  const navigate = useNavigate();

  // ----- States -------------
  const [allUsers, setAllUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // ----- Effects ----------
  React.useEffect(() => {
    get_contents_list(auth)
      .then((data) => {
        setAllUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // React.useEffect(() => {
  //   console.log(allUsers);
  // }, [allUsers]);

  // ----- Render ---------

  return (
    <Section
      title="全てのコンテンツ"
      description="全てのPulpコンテンツの一覧です。"
      sectionClass="contents-list-section"
    >
      <div className="all-contents-wrapper contents-wrapper">
        {allUsers.map((user) => {
          return (
            <div className="user-section" key={user.user_email}>
              <div className="user-name-section">
                <span className="user-email">■ {user.user_email}</span>
                {user.profile?.handle_name && (
                  <span
                    className="user-profile cursor-pointer scale-on-hover"
                    onClick={() => {
                      navigate(`/profile/${user.profile.handle_name}`);
                    }}
                  >
                    @{user.profile.handle_name}
                  </span>
                )}
              </div>

              <ContentList
                contents={user.user_contents}
                searchable={false}
                loading={loading}
                permissionToEdit={false}
                requestContentDelete={() => {}}
                rotateEnabled={true}
                showPublished={true}
                updatedBadge={true}
              />
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ContentsList;
