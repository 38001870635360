import endpoints from "./apiUrls";
import axios from "axios";


// ----- Admin internal test functions ---------------

export const getAdminFeedPost = (cognito_id) => {
  return axios.get(`${endpoints.api}/feed_post_get_for_admin`, {
    params: {
      cognito_id: cognito_id,
    }
  })
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    throw new Error(err);
  })
}

export const updateAdminFeedPost = (cognito_id, feedPostData) => {
  return axios.post(`${endpoints.api}/feed_post_for_admin`, {
    cognito_id: cognito_id,
    feed_post: feedPostData,
  })
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    throw new Error(err);
  })
}