import endpoints from "./apiUrls";
import axios from "axios";

// ----- validations --------------
export const validateHandleFormat = (handleName) => {
  // regex only allows lowercase letters, numbers, and underscores
  const regex = /^[a-z0-9_]+$/;

  if (handleName.length < 5) {
    return { result: false, message: "5文字以上で入力してください" };
  }

  if (handleName.length > 15) {
    return {
      result: false,
      message: "15文字以下で入力してください",
    };
  }

  if (!regex.test(handleName)) {
    return {
      result: false,
      message: "小文字の英数字とアンダースコアのみ使用できます",
    };
  }

  return { result: true, message: "" };
};

// ----- profile link --------------
export const generateProfileLink = (handleName) => {
  return `${window.location.origin}/profile/${handleName}`
}

// ----- API ---------------

// 自分のプロフィールデータを取得
export const getProfile = (cognitoId) => {
  return axios
    .get(`${endpoints.api}/profile/${cognitoId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

// handle nameからプロフィールデータを取得
export const getProfileByHandleName = (handleName) => {
  return axios
    .get(`${endpoints.api}/get_profile_by_handle_name/${handleName}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

// プロフィールを作成
export const createProfile = (cognitoId) => {
  return axios
    .post(`${endpoints.api}/create_profile`, {
      cognito_id: cognitoId,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// プロフィールを更新
export const updateProfile = (cognitoId, profileData) => {
  return axios
    .put(`${endpoints.api}/profiles/${profileData.id}`, {
      cognito_id: cognitoId,
      profile: profileData,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// プロフィールのコンテンツを取得
export const getContents = (cognitoId) => {
  return axios
    .get(`${endpoints.api}/my_profile`, {
      params: {
        cognito_id: cognitoId,
      },
    })
    .then((res) => {
      console.log(res)
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkHandleAvailability = (handleName, cognitoId) => {
  return axios
    .get(`${endpoints.api}/check_handle`, {
      params: {
        handle_name: handleName,
        cognito_id: cognitoId,
      },
    })
    .then((res) => {
      if (res.data.available) {
        return {
          result: true,
          message: `@${handleName}は使用可能です`,
        };
      } else {
        return {
          result: false,
          message: `@${handleName}は既に使用されています`,
        };
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};
