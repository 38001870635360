import React from "react";
// motion
import { motion, AnimatePresence } from "framer-motion";

const AnimatePresenceWrapper = ({
  children,
  visibility,
  initial = true,
  values = {
    initial: { opacity: 0, zIndex: "auto" },
    animate: { opacity: 1, zIndex: "auto" },
    exit: { opacity: 0, zIndex: "auto" },
  },
  exitDelay = 0,
  animateDelay = 0,
}) => {
  const [visible, setVisible] = React.useState(visibility);

  React.useEffect(() => {
    setVisible(visibility);
  }, [visibility]);

  return (
    <AnimatePresence initial={initial}>
      {visibility && (
        <motion.div
          initial={values.initial}
          animate={{
            ...values.animate,
            transition: { delay: animateDelay },
          }}
          exit={{
            ...values.exit,
            transition: { delay: exitDelay },
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimatePresenceWrapper;
