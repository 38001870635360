const getOtherSlideModals = () => {
  const slideModals = document.getElementsByClassName("slide-modal-wrapper");
  return slideModals;
};

export const generateZindex = () => {
  const slideModals = getOtherSlideModals();

  if (slideModals.length == 0) return 999;

  // get the max zIndex out of all slideModals
  let maxZIndex = 0;
  for (let i = 0; i < slideModals.length; i++) {
    const zIndex = Number(
      getComputedStyle(slideModals[i]).zIndex.replace("px", "")
    );
    if (zIndex > maxZIndex) maxZIndex = zIndex;
  }

  return maxZIndex + 1;
};
