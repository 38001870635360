import React from "react";
import "./ContentHighlightBar.scss";

// icons
import { faRss } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContentHighlightBar = ({
  updatedBadge,
  elapsedSinceLastUpdate,
  maxElapsed = 14,
}) => {
  if (updatedBadge && elapsedSinceLastUpdate <= maxElapsed) {
    return (
      <div
        className={`recently-updated-badge ${
          elapsedSinceLastUpdate <= 3 ? "immediate-recent" : "distant-recent"
        }`}
      >
        <FontAwesomeIcon className="icon" icon={faRss} />
        <span>
          {(() => {
            switch (elapsedSinceLastUpdate) {
              case 0:
                return "今日";
              case 1:
                return "昨日";
              default:
                return `${elapsedSinceLastUpdate}日前に`;
            }
          })()}
          アップデート
        </span>
      </div>
    );
  }
};

export default ContentHighlightBar;
