import React from "react";
import "./SpacePage.scss";

// basket
import Basket from "../CreatorScreens/Basket";

// loading
import Loading from "../../UtilityComponents/Loading.js";

// SEO
import SEO from "../../UtilityComponents/SEO.js";

// router
import { useParams } from "react-router-dom";

// helper
import { getBasket } from "../../helpers/BasketsHelper";

// animate
import AnimateUpWrapper from "../../components/Animation/AnimateUpWrapper";

const SpacePage = ({ auth }) => {
  // ----- params ---------------
  let { id } = useParams();

  // ----- states -------------
  const [isPublic, setIsPublic] = React.useState(true);
  const [basketData, setBasketData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  // ----- effects ------------
  React.useEffect(() => {
    setLoading(true);
    getBasket(id)
      .then((res) => {
        setIsPublic(true);
        setBasketData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message == "not public") {
          setIsPublic(false);
          setLoading(false);
        }
      });
  }, []);

  // ----- render --------------
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="space-page-wrapper">
        <AnimateUpWrapper className="basket">
          {isPublic ? (
            <>
              <SEO
                url={window.location.href}
                title={basketData.title}
                description={basketData.basket_description}
              />
              <Basket
                auth={auth}
                basketData={basketData}
                control={false}
                showPublished={false}
                showContentPublished={false}
                updatedBadge={true}
                initialOpen={true}
              />
            </>
          ) : (
            <span className="message">まだ公開されていないスペースです。</span>
          )}
        </AnimateUpWrapper>
      </div>
    );
  }
};

export default SpacePage;
