import SWUpdateNotice from "./screens/CreatorScreens/SWUpdateNotice";
import { createRoot } from "react-dom/client";

export default {
  onUpdate: (registration) => {
    // registration.unregister().then(() => {
    //   window.location.reload();
    // });

    if (registration.waiting) {

      registration.waiting?.postMessage({ type: "SKIP_WAITING" });
      
      window.location.reload();

      // const targetDiv = document.querySelector(
      //   ".creator-screen-sw-update-notice"
      // );

      // if (targetDiv) {
      //   const root = createRoot(targetDiv);
      //   root.render(
      //     <SWUpdateNotice registration={registration} />);
      // }
      
    }
  },
  onSuccess: (registration) => {
    console.log(registration);
  },
};
