import React from "react";
import "./Basket.scss";

// contents list
import ContentList from "../../HomeComponents/ContentList";

// overlays
import EditBasketModal from "../../Overlays/EditBasketModal.js";

// components
import Collapsible from "../../UtilityComponents/Collapsible";

// utility
import { copyToClipboard } from "../../helpers/Utility";

// helpers
import { generateSpaceLink } from "../../helpers/BasketsHelper.js";

// fonts
import {
  faEllipsis,
  faDollarSign,
  faLink,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Basket = ({
  auth,
  basketData,
  control = false,
  showPublished = false,
  showContentPublished = false,
  updatedBadge = false,
  removeBasket,
  loadBaskets,
  initialOpen = false,
}) => {
  // ----- states ---------------
  const [basketEditModal, setBasketEditModal] = React.useState(false);

  // ----- functions ------------
  const updateBasketData = (data) => {
    console.log("update basket", data);
  };

  return (
    <Collapsible
      bordered={true}
      headerSection={
        <div className="basket-header-section">
          {showPublished && (
            <div
              className={`basket-published-wrapper ${
                basketData.public ? "green" : "gray"
              }`}
            >
              <span className="basket-published-text">
                {basketData.public ? "公開中" : "未公開"}
              </span>
            </div>
          )}
          <div className="basket-title-section">
            <span className="basket-title">{basketData.title}</span>
            {basketData.basket_description != "" && (
              <p className="basket-description">
                {basketData.basket_description}
              </p>
            )}
          </div>
        </div>
      }
      initialOpen={initialOpen}
    >
      <div className="basket-inner-wrapper">
        <div className="basket-header-section">
          {control && (
            <div className="basket-control-section">
              <div
                className="basket-button basket-manage cursor-pointer scale-on-hover-small"
                onClick={() => {
                  setBasketEditModal(true);
                }}
              >
                <FontAwesomeIcon className="icon" icon={faEllipsis} />
                <span className="button-name">スペース編集</span>
              </div>
              <div
                className="basket-button basket-monetize cursor-pointer scale-on-hover-small"
                onClick={() => {
                  alert("Coming soon!");
                }}
              >
                <FontAwesomeIcon className="icon" icon={faDollarSign} />
                <span className="button-name">マネタイズ設定</span>
              </div>
              <div
                className="basket-button basket-manage cursor-pointer scale-on-hover-small"
                onClick={() => {
                  copyToClipboard(
                    generateSpaceLink(basketData.id),
                    true,
                    "スペースのリンクをコピーしました"
                  );
                }}
              >
                <FontAwesomeIcon className="icon" icon={faLink} />
                <span className="button-name">リンクコピー</span>
              </div>
              <div
                className="basket-button basket-delete cursor-pointer scale-on-hover-small"
                onClick={() => {
                  removeBasket(basketData.id);
                }}
              >
                <FontAwesomeIcon className="icon" icon={faTrashCan} />
                <span className="button-name">削除</span>
              </div>
            </div>
          )}
        </div>

        <div className="basket-contents">
          <ContentList
            contents={basketData.contents}
            searchable={false}
            rotateEnabled={true}
            searchPlaceHolder="スペース内を検索"
            showPublished={showContentPublished}
            updatedBadge={updatedBadge}
          />
        </div>
      </div>

      {control && (
        <EditBasketModal
          auth={auth}
          loadBaskets={loadBaskets}
          basketData={basketData}
          basketEditModal={basketEditModal}
          setBasketEditModal={setBasketEditModal}
        />
      )}
    </Collapsible>
  );
};

export default Basket;
