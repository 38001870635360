import React from "react";
import "./FeatureStart.scss";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeatureStart = ({
  icon,
  header,
  message,
  buttonText,
  buttonCallback,
}) => {
  return (
    <div className="feature-start-wrapper">
      <div className="feature-start-section">
        <div className="feature-start-container">
          <div className="header-section">
            {icon && <FontAwesomeIcon icon={icon} className="icon" />}
            <div className="message-section">
              <span className="header">{header}</span>
              <p className="message">{message}</p>
            </div>
          </div>

          <div
            className="button cursor-pointer scale-on-hover-small"
            onClick={buttonCallback}
          >
            <span>{buttonText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureStart;
