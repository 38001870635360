import React from "react";
import "./FormWrapper.scss";

const FormWrapper = ({
  children,
  formTitle,
  formDescription,
  customClassName = "",
}) => {
  return (
    <div className={`form-section ${customClassName}`}>
      {(formTitle || formDescription) && (
        <div className="form-section-title-section">
          {formTitle && <span className="form-section-title">{formTitle}</span>}
          {formDescription && (
            <p className="form-section-description">{formDescription}</p>
          )}
        </div>
      )}

      {children}
    </div>
  );
};

export default FormWrapper;
