import React from "react";
import "./ContentComponents.scss";
import "./CodeContent.scss";

// react-syntax-highlighter for code block
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/cjs/styles/prism";

// language select
import SearchSelect from "react-select";

// helper
import { resizeFieldHeights } from "../helpers/ContentHelper.js";
import {
  blockWrapperColorModes,
  blockWrapperColor,
} from "../helpers/Colors.js";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

// utility
import { copyToClipboard } from "../helpers/Utility.js";

const CodeContent = ({
  blockData,
  currentSlideIndex,
  slidesData,
  updateSlidesData,
  slideColors,
  index,
  editMode,
  updateBlock,
  updateBlockWrapper,
}) => {
  const data = blockData.child_data;

  // ----- Stylings ---------------
  const boxColors = blockWrapperColorModes;
  const boxStyleProperties = blockData &&
    slideColors && {
      backgroundColor: (() => {
        switch (blockData.color) {
          case "darker":
            return slideColors.darkerShift;
          case "lighter":
            return slideColors.lighterShift;
        }
      })(),
      borderColor: slideColors.legibleColor,
      ...(blockData.shadowed && {
        boxShadow: `10px 10px 0px ${slideColors.opacityColor}`,
      }),
    };

  const oppositeBackgroundColor =
    slideColors &&
    (() => {
      switch (blockData.color) {
        case "darker":
          return slideColors.lighterShift;
        case "lighter":
          return slideColors.darkerShift;
      }
    })();

  // ----- Some default values ---------------
  const languages = [
    { value: "oneC (1c)", label: "oneC (1c)" },
    { value: "abnf", label: "abnf" },
    { value: "accesslog", label: "accesslog" },
    { value: "actionscript", label: "actionscript" },
    { value: "ada", label: "ada" },
    { value: "angelscript", label: "angelscript" },
    { value: "apache", label: "apache" },
    { value: "applescript", label: "applescript" },
    { value: "arcade", label: "arcade" },
    { value: "arduino", label: "arduino" },
    { value: "armasm", label: "armasm" },
    { value: "asciidoc", label: "asciidoc" },
    { value: "aspectj", label: "aspectj" },
    { value: "autohotkey", label: "autohotkey" },
    { value: "autoit", label: "autoit" },
    { value: "avrasm", label: "avrasm" },
    { value: "awk", label: "awk" },
    { value: "axapta", label: "axapta" },
    { value: "bash", label: "bash" },
    { value: "basic", label: "basic" },
    { value: "bnf", label: "bnf" },
    { value: "brainfuck", label: "brainfuck" },
    { value: "cLike (c-like)", label: "cLike (c-like)" },
    { value: "c", label: "c" },
    { value: "cal", label: "cal" },
    { value: "capnproto", label: "capnproto" },
    { value: "ceylon", label: "ceylon" },
    { value: "clean", label: "clean" },
    {
      value: "clojureRepl (clojure-repl)",
      label: "clojureRepl (clojure-repl)",
    },
    { value: "clojure", label: "clojure" },
    { value: "cmake", label: "cmake" },
    { value: "coffeescript", label: "coffeescript" },
    { value: "coq", label: "coq" },
    { value: "cos", label: "cos" },
    { value: "cpp", label: "cpp" },
    { value: "crmsh", label: "crmsh" },
    { value: "crystal", label: "crystal" },
    { value: "csharp", label: "csharp" },
    { value: "csp", label: "csp" },
    { value: "css", label: "css" },
    { value: "d", label: "d" },
    { value: "dart", label: "dart" },
    { value: "delphi", label: "delphi" },
    { value: "diff", label: "diff" },
    { value: "django", label: "django" },
    { value: "dns", label: "dns" },
    { value: "dockerfile", label: "dockerfile" },
    { value: "dos", label: "dos" },
    { value: "dsconfig", label: "dsconfig" },
    { value: "dts", label: "dts" },
    { value: "dust", label: "dust" },
    { value: "ebnf", label: "ebnf" },
    { value: "elixir", label: "elixir" },
    { value: "elm", label: "elm" },
    { value: "erb", label: "erb" },
    { value: "erlangRepl (erlang-repl)", label: "erlangRepl (erlang-repl)" },
    { value: "erlang", label: "erlang" },
    { value: "excel", label: "excel" },
    { value: "fix", label: "fix" },
    { value: "flix", label: "flix" },
    { value: "fortran", label: "fortran" },
    { value: "fsharp", label: "fsharp" },
    { value: "gams", label: "gams" },
    { value: "gauss", label: "gauss" },
    { value: "gcode", label: "gcode" },
    { value: "gherkin", label: "gherkin" },
    { value: "glsl", label: "glsl" },
    { value: "gml", label: "gml" },
    { value: "go", label: "go" },
    { value: "golo", label: "golo" },
    { value: "gradle", label: "gradle" },
    { value: "groovy", label: "groovy" },
    { value: "haml", label: "haml" },
    { value: "handlebars", label: "handlebars" },
    { value: "haskell", label: "haskell" },
    { value: "haxe", label: "haxe" },
    { value: "hsp", label: "hsp" },
    { value: "htmlbars", label: "htmlbars" },
    { value: "http", label: "http" },
    { value: "hy", label: "hy" },
    { value: "inform7", label: "inform7" },
    { value: "ini", label: "ini" },
    { value: "irpf90", label: "irpf90" },
    { value: "isbl", label: "isbl" },
    { value: "java", label: "java" },
    { value: "javascript", label: "javascript" },
    { value: "jbossCli (jboss-cli)", label: "jbossCli (jboss-cli)" },
    { value: "json", label: "json" },
    { value: "juliaRepl (julia-repl)", label: "juliaRepl (julia-repl)" },
    { value: "julia", label: "julia" },
    { value: "kotlin", label: "kotlin" },
    { value: "lasso", label: "lasso" },
    { value: "latex", label: "latex" },
    { value: "ldif", label: "ldif" },
    { value: "leaf", label: "leaf" },
    { value: "less", label: "less" },
    { value: "lisp", label: "lisp" },
    { value: "livecodeserver", label: "livecodeserver" },
    { value: "livescript", label: "livescript" },
    { value: "llvm", label: "llvm" },
    { value: "lsl", label: "lsl" },
    { value: "lua", label: "lua" },
    { value: "makefile", label: "makefile" },
    { value: "markdown", label: "markdown" },
    { value: "mathematica", label: "mathematica" },
    { value: "matlab", label: "matlab" },
    { value: "maxima", label: "maxima" },
    { value: "mel", label: "mel" },
    { value: "mercury", label: "mercury" },
    { value: "mipsasm", label: "mipsasm" },
    { value: "mizar", label: "mizar" },
    { value: "mojolicious", label: "mojolicious" },
    { value: "monkey", label: "monkey" },
    { value: "moonscript", label: "moonscript" },
    { value: "n1ql", label: "n1ql" },
    { value: "nginx", label: "nginx" },
    { value: "nim", label: "nim" },
    { value: "nix", label: "nix" },
    { value: "nodeRepl (node-repl)", label: "nodeRepl (node-repl)" },
    { value: "nsis", label: "nsis" },
    { value: "objectivec", label: "objectivec" },
    { value: "ocaml", label: "ocaml" },
    { value: "openscad", label: "openscad" },
    { value: "oxygene", label: "oxygene" },
    { value: "parser3", label: "parser3" },
    { value: "perl", label: "perl" },
    { value: "pf", label: "pf" },
    { value: "pgsql", label: "pgsql" },
    {
      value: "phpTemplate (php-template)",
      label: "phpTemplate (php-template)",
    },
    { value: "php", label: "php" },
    { value: "plaintext", label: "plaintext" },
    { value: "pony", label: "pony" },
    { value: "powershell", label: "powershell" },
    { value: "processing", label: "processing" },
    { value: "profile", label: "profile" },
    { value: "prolog", label: "prolog" },
    { value: "properties", label: "properties" },
    { value: "protobuf", label: "protobuf" },
    { value: "puppet", label: "puppet" },
    { value: "purebasic", label: "purebasic" },
    { value: "pythonRepl (python-repl)", label: "pythonRepl (python-repl)" },
    { value: "python", label: "python" },
    { value: "q", label: "q" },
    { value: "qml", label: "qml" },
    { value: "r", label: "r" },
    { value: "reasonml", label: "reasonml" },
    { value: "rib", label: "rib" },
    { value: "roboconf", label: "roboconf" },
    { value: "routeros", label: "routeros" },
    { value: "rsl", label: "rsl" },
    { value: "ruby", label: "ruby" },
    { value: "ruleslanguage", label: "ruleslanguage" },
    { value: "rust", label: "rust" },
    { value: "sas", label: "sas" },
    { value: "scala", label: "scala" },
    { value: "scheme", label: "scheme" },
    { value: "scilab", label: "scilab" },
    { value: "scss", label: "scss" },
    { value: "shell", label: "shell" },
    { value: "smali", label: "smali" },
    { value: "smalltalk", label: "smalltalk" },
    { value: "sml", label: "sml" },
    { value: "sqf", label: "sqf" },
    { value: "sql", label: "sql" },
    { value: "sqlMore (sql_more)", label: "sqlMore (sql_more)" },
    { value: "stan", label: "stan" },
    { value: "stata", label: "stata" },
    { value: "step21", label: "step21" },
    { value: "stylus", label: "stylus" },
    { value: "subunit", label: "subunit" },
    { value: "swift", label: "swift" },
    { value: "taggerscript", label: "taggerscript" },
    { value: "tap", label: "tap" },
    { value: "tcl", label: "tcl" },
    { value: "thrift", label: "thrift" },
    { value: "tp", label: "tp" },
    { value: "twig", label: "twig" },
    { value: "typescript", label: "typescript" },
    { value: "vala", label: "vala" },
    { value: "vbnet", label: "vbnet" },
    {
      value: "vbscriptHtml (vbscript-html)",
      label: "vbscriptHtml (vbscript-html)",
    },
    { value: "vbscript", label: "vbscript" },
    { value: "verilog", label: "verilog" },
    { value: "vhdl", label: "vhdl" },
    { value: "vim", label: "vim" },
    { value: "x86asm", label: "x86asm" },
    { value: "xl", label: "xl" },
    { value: "xml", label: "xml" },
    { value: "xquery", label: "xquery" },
    { value: "yaml", label: "yaml" },
    { value: "zephir", label: "zephir" },
  ];

  const getLanguageDataByValue = (value) => {
    return languages.filter((item) => item.value === value)[0];
  };

  const headerRef = React.useRef(null);
  const codeRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      resizeFieldHeights([codeRef]);
    }, 500);
  }, [editMode, codeRef.current]);

  if (!editMode) {
    return (
      <div
        className={`component-wrapper code-block-wrapper ${blockData.color}`}
        style={slideColors && boxStyleProperties}
      >
        {data.file_name != "" && (
          <span className="file-name">{data.file_name}</span>
        )}

        <div className="code-section">
          {data.language != "" && (
            <span className="language">
              {getLanguageDataByValue(data.language).label}
            </span>
          )}
          <SyntaxHighlighter
            className="syntax-highlighter code"
            style={atomDark}
            language={data.language}
            showLineNumbers={true}
            data-avoid-slide-swipe
          >
            {data.code_text.replace(/\n$/, "")}
          </SyntaxHighlighter>
        </div>

        <div
          className="copy-section cursor-pointer"
          onClick={() => {
            copyToClipboard(data.code_text);
          }}
        >
          <span className="copy-text">コピーする</span>
          <FontAwesomeIcon className="copy-icon" icon={faCopy} />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`component-wrapper code-block-wrapper ${blockData.color}`}
        style={slideColors && boxStyleProperties}
      >
        <SearchSelect
          className={`language-selector`}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "none",
              borderRadius: 100,
              opacity: "50%",
            }),
          }}
          defaultValue={getLanguageDataByValue(data.language)}
          onChange={(e) => {
            updateBlock([
              {
                blockIndex: index,
                value: e.value,
                key: "language",
              },
            ]);
          }}
          options={languages}
        />

        <div className="code-section">
          <textarea
            ref={headerRef}
            type="text"
            className="file-name"
            value={data.file_name.replace(/\s/g, "")}
            placeholder="ファイル名"
            onChange={(e) => {
              updateBlock([
                {
                  blockIndex: index,
                  value: e.target.value.replace(/\s/g, ""),
                  key: "file_name",
                },
              ]);
            }}
          />
          <textarea
            ref={codeRef}
            type="text"
            className="code"
            value={data.code_text}
            placeholder="hello, world ..."
            onKeyDown={(e) => {
              switch (e.key) {
                case "Tab":
                  e.preventDefault(); // prevent the default Tab behavior
                  const start = e.target.selectionStart;
                  const end = e.target.selectionEnd;
                  const value = e.target.value;

                  // replace the Tab key with two spaces
                  e.target.value =
                    value.substring(0, start) + "  " + value.substring(end);

                  // move the cursor two positions to the right
                  e.target.selectionStart = e.target.selectionEnd = start + 2;
                  break;
              }
            }}
            onChange={(e) => {
              updateBlock([
                {
                  blockIndex: index,
                  value: e.target.value,
                  key: "code_text",
                },
              ]);
              resizeFieldHeights([codeRef]);
            }}
          />
        </div>

        <div
          className={`color-change-button ${
            boxColors[
              (boxColors.indexOf(blockData.color) + 1) % boxColors.length
            ]
          } cursor-pointer`}
          style={
            slideColors && {
              backgroundColor: oppositeBackgroundColor
            }
          }
          onClick={(e) => {
            const colorIndex =
              (boxColors.indexOf(blockData.color) + 1) % boxColors.length;
            updateBlockWrapper({
              blockIndex: index,
              value: boxColors[colorIndex],
              key: "color",
            });

            // const updatedSlidesData = slidesData;
            // const colorIndex =
            //   (boxColors.indexOf(blockData.color) + 1) % boxColors.length;
            // updatedSlidesData.slides[currentSlideIndex].blocks[
            //   index
            // ].blockData.color = boxColors[colorIndex];
            // console.log(blockData.color);
            // updateSlidesData((prev) => ({
            //   ...prev,
            //   slides: updatedSlidesData.slides,
            // }));
          }}
        ></div>
      </div>
    );
  }
};

export default CodeContent;
