// --------------------------------------
// ----- imports ------------------------
// --------------------------------------

// devDetect
import isDev from "./DevDetect";

// file naming
import { v4 as uuidv4 } from "uuid";

// image compression
import imageCompression from "browser-image-compression";

// storage
import { Storage } from "aws-amplify";

// ---------------------------------------
// ------ Common functions ---------------
// --------------------------------------

// unique file name generation
const generateFileName = (originalFileName) => {
  const extention = originalFileName.slice(originalFileName.lastIndexOf("."));
  const uuid = uuidv4();

  const newName = uuid + extention;

  return newName;
};

// uploading file to s3
const uploadFileS3 = (
  file,
  userIdForPath,
  fileName,
  progressCallbackFunction,
  completionCallbackFunction
) => {
  console.log("upload started")
  Storage.put(
    `${isDev() ? "dev-" : ""}user-${userIdForPath}/${fileName}`,
    file,
    {
      // contentType: compressedImage.type, // contentType is optional
      progressCallback(progress) {
        const progressFraction = (progress.loaded / progress.total) * 100;
        progressCallbackFunction(progressFraction);
      },
    }
  )
    .then((result) => {
      completionCallbackFunction(result);
    })
    .catch((err) => {
      console.log(err);
    });
};

// --------------------------------------
// ------ Image functions ---------------
// --------------------------------------

// ----- image master functions ---------------
export const uploadImageHelper = async (
  imageFile,
  userIdForPath,
  compressionProgressFunction,
  uploadProgressFunction,
  completionCallbackFunction
) => {
  try {
    // compress image
    const compressedImage = await compressImageFile(
      imageFile,
      compressionProgressFunction
    );

    // generate unique filename
    const uniqueFileName = generateFileName(compressedImage.name);

    // upload image to s3
    uploadFileS3(
      compressedImage,
      userIdForPath,
      uniqueFileName,
      uploadProgressFunction,
      completionCallbackFunction
    );
  } catch (err) {
    console.log("Image upload failed.", err);
  }
};

// ----- image helper functions ---------------
// image compression
const compressImageFile = async (imageFile, setProgress) => {
  const options = {
    maxSizeMB: 2,
    initialQuality: 0.85,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    onProgress: (progress) => {
      setProgress(progress);
    },
  };

  try {
    const compressedImage = await imageCompression(imageFile, options);
    return compressedImage;
  } catch (error) {
    console.log(error);
  }
};

// ----- s3 image retrieval ---------------
export const getImageUrlFromS3Path = (s3_path) => {
  const fullImageUrl = `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com/public/${s3_path}`;
  return fullImageUrl;
}
