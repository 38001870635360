import React from "react";
import "./SearchBox.scss";

// icons
import { faXmark, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBox = ({
  value,
  setValue,
  placeholder,
  searchButtonVisible,
  searchButtonFunction,
  autoSearch=false,
  queryResetFunction=() => {}
}) => {
  // ----- refs ---------------
  const searchBoxRef = React.useRef(null);

  // ----- effects ---------------
  React.useEffect(() => {
    // apply auto search.
    if (autoSearch == false) return;
    if (value == "") {
      queryResetFunction()
      return;
    };
    searchButtonFunction();
  }, [value])

  return (
    <div className="search-box-component-wrapper">
      <div className="search-box-wrapper">
        <div className="search-box">
          <input
            ref={searchBoxRef}
            type="text"
            className="search-box-input"
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <div
            className="delete-button-wrapper cursor-pointer"
            onClick={() => {
              setValue("");
              searchBoxRef.current.focus();
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="delete-button" />
          </div>
        </div>

        {searchButtonVisible && (
          <div
            className="search-button-wrapper cursor-pointer"
            onClick={() => {
              searchButtonFunction();
            }}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="search-button scale-on-hover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
