import React from "react";
import "./bgCurve.scss";

const BgCurve = ({ heightCoverage = 0.25, color = "#f1ede2", opacity = 1 }) => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const yPropotion = heightCoverage;
  const xPropotion = yPropotion * 1.65;

  const startPoint = `0, ${yPropotion * screenHeight}`;
  const firstPoint = `${xPropotion * screenWidth}, ${
    yPropotion * 2 * screenHeight
  }`;
  const secondPoint = `${xPropotion * 2 * screenWidth}, 0`;
  const thirdPoint = `${screenWidth}, ${yPropotion * screenHeight}`;

  const firstLine = `${screenWidth}, 00`;
  const secondLine = `0, 0`;

  return (
    <svg
      className="bg-curve"
      viewBox={`0 0 ${screenWidth} ${screenHeight}`}
      preserveAspectRatio="xMinYMin meet"
      fill={color}
      opacity={opacity}
    >
      <path
        d={`M${startPoint} C${firstPoint} ${secondPoint} ${thirdPoint} L${firstLine} L${secondLine} Z`}
      ></path>
    </svg>
  );
};

export default BgCurve;
