import React from "react";
import "./UsersList.scss";

// router
import { useNavigate } from "react-router-dom";

// endpoint
import endpoints from "../../helpers/apiUrls";

// admin components
import {
  Section,
  Status,
  Input,
  TextArea,
  Button,
} from "./AdminComponents/AdminComponents.js";

// components
import Collapsible from "../../UtilityComponents/Collapsible";
import SearchBox from "../../components/BasicComponents/SearchBox";

// helpers
import { get_users_list } from "../../helpers/adminScreenHelper";
import { formatDate } from "../../helpers/Utility";

const UsersList = ({ auth }) => {
  const navigate = useNavigate();

  // ----- States -------------
  const [users, setUsers] = React.useState([]);
  const [searchFilteredUsers, setSearchFilteredUsers] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  // ----- Effects ----------
  React.useEffect(() => {
    get_users_list(auth)
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // React.useEffect(() => {
  //   console.log(users);
  // }, [users]);

  // ----- Functions --------
  const searchUsers = (query) => {
    setSearchFilteredUsers(
      users.filter((data) => {
        return (
          data.user.email.includes(query) ||
          (data.profile && data.profile.handle_name.includes(query))
        );
      })
    );
  };

  const usersData = () => {
    return searchQuery != "" ? searchFilteredUsers : users;
  };

  // ----- Components ----------

  const UserListItemHeader = ({ data, auth }) => {
    return (
      <div className="user-list-header">
        <div className="user-list-header-top">
          {auth.authState.cognito_user.username === data.user.cognito_id && (
            <span className="user-list-header-tag">自分</span>
          )}
          <span>{data.user.email}</span>
        </div>
        <div className="user-list-header-bottom">
          <a
            className="link scale-on-hover"
            href={`${endpoints.api}/admin/user/${data.user.id}`}
            target="_blank"
          >
            admin
          </a>
          {data.profile ? (
            <span
              className={data.profile.handle_name ? "link scale-on-hover" : ""}
              onClick={() => {
                data.profile.handle_name &&
                  window.open(`/profile/${data.profile.handle_name}`);
              }}
            >
              {data.profile.handle_name
                ? `@${data.profile.handle_name}`
                : "no-handle"}
            </span>
          ) : (
            <span>no-profile</span>
          )}
        </div>
      </div>
    );
  };

  const UserListItem = ({ data, auth }) => {
    return (
      <div className="user-list-item">
        <Collapsible
          headerSection={<UserListItemHeader data={data} auth={auth} />}
          wrapperClassName={`user-list-item-wrapper`}
          bordered={true}
          toggleSize={14}
          padded={false}
        >
          <Status
            title="アカウント作成日"
            value={formatDate(data.user.created_at)}
          />
          <Status
            title="コンテンツ数"
            value={`公開 ${data.stats.contents_count.published} / 全数 ${data.stats.contents_count.all}`}
          />
          <Status
            title="スペース数"
            value={`公開 ${data.stats.spaces_count.published} / 全数 ${data.stats.spaces_count.all}`}
          />
          <Status
            title="ブロック数"
            value={`${data.stats.blocks_count} ブロック`}
          />
        </Collapsible>
      </div>
    );
  };

  // ----- Render ---------

  return (
    <Section
      title="Pulp Creators"
      description="Pulpユーザーの一覧です。"
      sectionClass="users-list-section"
    >
      <SearchBox
        value={searchQuery}
        setValue={setSearchQuery}
        placeholder={"email / @ハンドルネーム を入力"}
        searchButtonVisible={false}
        searchButtonFunction={() => {
          if (searchQuery == "") return;
          searchUsers(searchQuery);
        }}
        autoSearch={true}
        queryResetFunction={() => {
          setSearchQuery("");
          setSearchFilteredUsers([]);
        }}
      />
      <div className="users-list-wrapper">
        {usersData().length == 0 ? (
          <span className="users-list-message">
            {searchQuery == ""
              ? "ユーザーがいません。"
              : "該当するユーザーがいません。"}
          </span>
        ) : (
          <span className="users-list-message">
            {usersData().length}人のユーザー
          </span>
        )}
        {usersData().map((data, index) => {
          return <UserListItem data={data} auth={auth} key={data.user.id} />;
        })}
      </div>
    </Section>
  );
};

export default UsersList;
