import React from "react";

// helpers
import { getParentSlide } from "../../helpers/ContentHelper.js";

import "./SlideBreadCrumb.scss";

const SlideBreadCrumb = ({ slideData, contentData }) => {
  const [parentSlides, setParentSlides] = React.useState([]);

  const getParentSlides = () => {
    var parents = [];
    var currentSlide = slideData;

    while (currentSlide) {
      const parentSlide = getParentSlide(contentData.slides, currentSlide.id);
      if (parentSlide) {
        parents.push(parentSlide);
      }
      currentSlide = parentSlide;
    }

    setParentSlides(parents);
  };

  React.useEffect(() => {
    getParentSlides();
  }, [slideData]);


  
  if (slideData.title === "") return null;


  return (
    <div className="slide-breadcrumb-wrapper">
      <span className="slide-title">└</span>
      <div className="slide-titles">
        {parentSlides.map((slide, index) => {
          return (
            <>
              <span className="slide-title parent-slide" key={slide.id}>{slide.title}</span>
            </>
          );
        })}

        <span className="slide-title current-slide">
          {slideData.title == "" ? "-" : slideData.title}
        </span>
      </div>
    </div>
  );
};

export default SlideBreadCrumb;
