import React from "react";

// icons
import { faEllipsis, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Overlay from "../../Overlays/Overlay";
import { deletePersonData } from "../../helpers/PeopleSuiteHelper";

const Person = ({ person, index, reloadPeople }) => {
  // ----- states ----------
  const [menuOpen, setMenuOpen] = React.useState(false);

  // ----- functions -------
  const deletePerson = () => {
    if (!window.confirm("このメンバーを削除しますか？")) return;

    console.log("delete");
    deletePersonData(person.id).then(person => {
      alert('メンバーを削除しました。');
      reloadPeople();
    })

    setMenuOpen(false);
  };

  // ----- render ------------

  return (
    <>
      <div className="person-wrapper">
        {index != 0 && <div className="line"></div>}
        <div className="person-container">
          <div className="left">
            <span className="index">{index + 1}</span>
            <span className={`name ${person.person_name == "" ? "gray" : ""}`}>
              {person.person_name != "" ? person.person_name : "お名前未登録"}
            </span>
          </div>

          <div className="right">
            <span className="email">{person.endpoints[0].email.email}</span>

            <FontAwesomeIcon
              icon={faEllipsis}
              className="person-menu cursor-pointer"
              onClick={() => {
                setMenuOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <Overlay
        open={menuOpen}
        setOpen={setMenuOpen}
        title={`メンバー管理`}
        buttons={{
          primaryButton: {
            display: false,
          },
          secondaryButton: {
            display: true,
            text: "とじる",
            action: (e) => {
              setMenuOpen(false);
            },
          },
        }}
        fullScree={false}
      >
        <div className="person-menu-wrapper">
          <div className="person-menu-person-info">
            <span className="name">{person.person_name}</span>
            <span className="email">{person.endpoints[0].email.email}</span>
          </div>

          <div className="person-menu-buttons">
            <div
              className="person-menu-button red cursor-pointer scale-on-hover-small"
              onClick={() => {
                deletePerson();
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} className="icon" />
              <span>メンバー削除</span>
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default Person;
