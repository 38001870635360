import React from "react";
import "./Home.scss";
import { useNavigate } from "react-router-dom";

// content list
import ContentList from "../../HomeComponents/ContentList.js";

// helpers
import { getHomeContents } from "../../helpers/HomeHelper.js";
import { deleteContent } from "../../helpers/ContentHelper";

const Home = ({ auth }) => {
  const navigate = useNavigate();

  // ----- welcome section content data --------
  const welcomeContents = [
    {
      id: "173",
      title: "1. pulp について",
      content_description: "サービスの概要や使い方をご紹介します。",
      slides_count: 0,
      blocks_count: 0,
    },
    {
      id: "7",
      title: "2. 知識をサクッとまとめて共有",
      content_description: "レザークラフト講座のサンプルです。",
      slides_count: 5,
      blocks_count: 35,
    },
    {
      id: "166",
      title: "3. 自分の活動をまとめる",
      content_description: "栄養士のプロフィールサンプルです。",
      slides_count: 0,
      blocks_count: 0,
    },
    {
      id: "167",
      title: "4. 商品やサービスを紹介する",
      content_description: "イラスト講座の販促LPサンプルです。",
      slides_count: 0,
      blocks_count: 0,
    },
  ];

  // ----- Create Content Modal ---------------
  const [createContentModal, setCreateContentModal] = React.useState(false);

  // コンテンツを管理するステート変数
  const [contentsLoading, setContentsLoading] = React.useState(true); // コンテンツのロード状況
  const [contents, setContents] = React.useState([]);

  // ----- functions ---------------

  const loadContents = () => {
    var cognito_id = auth.authState.cognito_user.username;

    getHomeContents(cognito_id).then((data) => {
      setContentsLoading(false);
      setContents(data.contents);
    });
  };

  // コンテンツの削除
  const requestContentDelete = (content) => {
    deleteContent(
      true,
      auth.authState.cognito_user.username,
      content.id,
      loadContents
    );
  };

  // ----- effects ---------------
  React.useEffect(() => {
    loadContents();
  }, [auth]);

  // コンテンツが更新された時に走る関数。
  React.useEffect(() => {
    // console.log(contents);
  }, [contents]);

  return (
    <>
      <div className="home-wrapper">
        <div className="home-container">
          <div className="welcome-section section">
            <span className="section-heading">🤝 Pulp へようこそ！</span>
            <p className="welcome-message">
              Pulp の簡単な説明と、活用事例をまとめました。ぜひご覧ください。
            </p>

            <div className="contents-wrapper">
              <p className="tap-message">\ タップしてコンテンツを閲覧 /</p>
              <ContentList
                styleClass={"white-theme"}
                contents={welcomeContents}
                searchable={false}
                volumeInfo={false}
                permissionToEdit={false}
                menuVisible={false}
                rotateEnabled={true}
              />
            </div>
          </div>

          <div className="contents-section section">
            <span className="section-heading">あなたのコンテンツ</span>
            <ContentList
              contents={contents}
              loading={contentsLoading}
              permissionToEdit={true}
              menuVisible={true}
              requestContentDelete={requestContentDelete}
              rotateEnabled={true}
              showPublished={true}
              noContentMessage='まだコンテンツがありません。フッターの"+"アイコンから作成してみてください！'
            />
          </div>

        </div>
      </div>
    </>
  );
};

export default Home;
