import React from "react";
import "./ContentComponents.scss";
import "./TextContent.scss";

// helper
import { resizeFieldHeights } from "../helpers/ContentHelper.js";
import { blockWrapperColorModes } from "../helpers/Colors.js";

const TextContent = ({
  blockData,
  currentSlideIndex,
  slidesData,
  updateSlidesData,
  slideColors,
  index,
  editMode,
  addBlock,
  updateBlock,
  updateBlockWrapper,
  changeIndentation,
}) => {
  const data = blockData.child_data;

  const headerRef = React.useRef(null);
  const textRef = React.useRef(null);

  // ----- Stylings ---------------
  const boxColors = blockWrapperColorModes;
  const boxStyleProperties = blockData &&
    slideColors && {
      backgroundColor: (() => {
        switch (blockData.color) {
          case "darker":
            return slideColors.darkerShift;
          case "lighter":
            return slideColors.lighterShift;
        }
      })(),
      borderColor: slideColors.legibleColor,
      ...(blockData.shadowed && {
        boxShadow: `10px 10px 0px ${slideColors.opacityColor}`,
      }),
    };

  const oppositeBackgroundColor = slideColors && (() => {
    switch (blockData.color) {
      case "darker":
        return slideColors.lighterShift;
      case "lighter":
        return slideColors.darkerShift;
    }
  })();

  // ----- Functions ---------------

  const addNextTextBlock = () => {
    const defalutValue = {
      block_default_value: {
        color: blockData.color,
        indentation: data.indentation,
      },
      child_default_value: {},
    };

    addBlock("text", index + 1, defalutValue);

    adjustHeights();
  };

  const breakDownBody = (e) => {
    // disabled for now. the problem is that, the addBlock function will not only add a new block, but also retrieve backend data, before the updateBlock will finish updating.
    // This prevents the ramain block from being updated, leaving all text in the first block, and second block rendering properly.
    e.preventDefault();
    if (false) {
      e.preventDefault();
      const remainText = e.target.value.slice(0, e.target.selectionStart);

      if (
        e.target.value.replace(/\s/g, "") == "" &&
        remainText.replace(/\s/g, "") == ""
      ) {
        return;
      }

      const sentText = e.target.value.slice(e.target.selectionStart);

      updateBlock({
        blockIndex: index,
        value: remainText,
        key: "body_text",
      });

      const defalutValue = {
        block_default_value: {
          color: blockData.color,
          indentation: data.indentation,
        },
        child_default_value: {
          body_text: sentText,
        },
      };

      addBlock("text", index + 1, defalutValue);

      adjustHeights();
    }
  };

  const breakDownHeader = (e) => {
    e.preventDefault();

    e.preventDefault();
    const remainText = e.target.value.slice(0, e.target.selectionStart);
    const sentText = e.target.value.slice(e.target.selectionStart);

    const newText = sentText + textRef.current.value;

    updateBlock([
      {
        blockIndex: index,
        value: remainText,
        key: "header",
      },
      {
        blockIndex: index,
        value: newText,
        key: "body_text",
      },
    ]);

    textRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

    adjustHeights();
  };

  const backspaceText = (e) => {
    if (
      e.target.selectionStart > 0 ||
      e.target.selectionStart != e.target.selectionEnd
    ) {
      return;
    }
    e.preventDefault();

    const originalHeaderCursorPosition = headerRef.current.value.length;
    const newHeader = headerRef.current.value + textRef.current.value;

    // shift focus to headerRef
    headerRef.current.focus();
    headerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });

    // updateBlock([
    //   {
    //     blockIndex: index,
    //     value: newHeader,
    //     key: "header",
    //   },
    //   {
    //     blockIndex: index,
    //     value: "",
    //     key: "body_text",
    //   },
    // ]);

    adjustHeights();
  };

  const headerProcessor = (text, stripAllEmpty, e = null) => {
    const lines = text.split(/\r?\n/);

    // remove consecutive line breaks
    const filteredLines = lines.filter((line, index) => {
      if (stripAllEmpty) {
        if (line.trim() == "") {
          return false;
        }
      } else {
        if (index == 0) {
          return true;
        }

        if (line == "" && lines[index - 1].trim() == "") {
          // this means there are double line breaks.
          if (e && e.target.selectionStart == text.length) {
            // this means the line break happend at the end of the textarea. Move cursor to textRef.
            textRef.current.focus();
            textRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            return false;
          }
          return false;
        }
      }

      return true;
    });

    const newLines = filteredLines.join("\n");

    return newLines;
  };

  const adjustHeights = () => {
    setTimeout(() => {
      resizeFieldHeights([headerRef, textRef]);
    }, 500);
  };

  React.useEffect(() => {
    adjustHeights();
  }, [editMode, headerRef.current, textRef.current]);


  if (!editMode) {
    return (
      <div
        className={`component-wrapper text-block-wrapper ${blockData.color} ${
          blockData.shadowed ? "shadowed" : ""
        }`}
        style={slideColors && boxStyleProperties}
      >
        {data.header != "" && (
          <span className="title">{headerProcessor(data.header, true)}</span>
        )}
        {data.body_text != "" && <pre className="text">{data.body_text}</pre>}
      </div>
    );
  } else {
    return (
      <div
        className={`component-wrapper text-block-wrapper ${blockData.color} ${
          blockData.shadowed ? "shadowed" : ""
        }`}
        style={slideColors && boxStyleProperties}
      >
        <textarea
          id={`block-${blockData.id}-header`}
          ref={headerRef}
          type="text"
          className="title"
          value={data.header}
          placeholder="H1 見出し"
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case 13:
                // breakDownHeader(e);
                break;
              case 9:
                e.preventDefault();
                if (e.shiftKey) {
                  changeIndentation("left");
                } else {
                  changeIndentation("right");
                }
                break;
            }
          }}
          onChange={(e) => {
            const newValue = headerProcessor(e.target.value, false, e);
            updateBlock([
              {
                blockIndex: index,
                value: newValue,
                key: "header",
              },
            ]);
            adjustHeights();
          }}
        />
        <textarea
          id={`block-${blockData.id}-text`}
          ref={textRef}
          type="text"
          className="text"
          value={data.body_text}
          placeholder="BODY 本文"
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case 13:
                // breakDownBody(e);
                break;
              case 8:
                backspaceText(e);
                break;
              case 9:
                e.preventDefault();
                if (e.shiftKey) {
                  changeIndentation("left");
                } else {
                  changeIndentation("right");
                }
                break;
            }
          }}
          onChange={(e) => {
            updateBlock([
              {
                blockIndex: index,
                value: e.target.value,
                key: "body_text",
              },
            ]);
            adjustHeights();
          }}
        />

        <div className="edit-buttons">
          <div
            className={`shadow-button ${
              blockData.shadowed ? "shadowed" : ""
            } cursor-pointer`}
            onClick={() => {
              updateBlockWrapper({
                blockIndex: index,
                value: !blockData.shadowed,
                key: "shadowed",
              });
              adjustHeights();
            }}
          ></div>
          <div
            className={`color-change-button ${
              boxColors[
                (boxColors.indexOf(blockData.color) + 1) % boxColors.length
              ]
            } cursor-pointer`}
            style={
              slideColors && {
                backgroundColor: oppositeBackgroundColor
              }
            }
            onClick={(e) => {
              const colorIndex =
                (boxColors.indexOf(blockData.color) + 1) % boxColors.length;
              updateBlockWrapper({
                blockIndex: index,
                value: boxColors[colorIndex],
                key: "color",
              });
              adjustHeights();
            }}
          ></div>
        </div>
      </div>
    );
  }
};

export default TextContent;
