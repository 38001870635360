import React from "react";

// components
import ContentInfo from "./ContentInfo";
import SingleBlockContentInfo from "./SingleBlockContentInfo";

const ContentInfoWrapper = ({ content, volumeInfo }) => {
  // ----- states --------------
  const [isSingleBlockContent, setIsSingleBlockContent] = React.useState(false);

  // ----- constants ------------
  const supportedSingleBlocks = ["video"];

  // ----- functions ------------
  const checkSingleBlockSupport = (content) => {
    if (!content.single_block_validation?.is_single_block)
      return setIsSingleBlockContent(false);

    const singleBlockData = content.single_block_validation.block_data;

    if (supportedSingleBlocks.includes(singleBlockData.child_type)) {
      setIsSingleBlockContent(true);
    } else {
      setIsSingleBlockContent(false);
    }
  };

  // ------ effects ------------
  // check if the content has only one block
  React.useEffect(() => {
    checkSingleBlockSupport(content);
  }, [content]);

  // ----- render --------------

  if (isSingleBlockContent) {
    return (
      <>
        <SingleBlockContentInfo
          content={content}
          singleBlockData={content.single_block_validation.block_data}
        />
        <ContentInfo content={content} volumeInfo={false} />
      </>
    );
  } else {
    return <ContentInfo content={content} volumeInfo={volumeInfo} />;
  }
};

export default ContentInfoWrapper;
