import React from "react";
import "./ContentComponents.scss";
import "./LinkContent.scss";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import Overlay from "../Overlays/Overlay.js";

// helper
import { resizeFieldHeights } from "../helpers/ContentHelper.js";
import { validateUrlString } from "../helpers/Utility";

const LinkContent = ({
  blockData,
  currentSlideIndex,
  slidesData,
  updateSlidesData,
  slideColors,
  index,
  editMode,
  updateBlock,
  updateBlockWrapper,
}) => {
  const data = blockData.child_data;

  const [linkTitle, setLinkTitle] = React.useState();
  const [linkUrl, setLinkUrl] = React.useState();
  const [linkOgp, setLinkOgp] = React.useState({});
  const [faviconError, setFaviconError] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);

  const [linkEdit, setLinkEdit] = React.useState(false);

  const captionRef = React.useRef(null);

  // ----- Navigate ---------------
  const navigate = useNavigate();

  // ----- Stylings ---------------
  const boxStyleProperties = blockData && slideColors && {
    backgroundColor: (() => {
      switch (blockData.color) {
        case "darker":
          return slideColors.darkerShift;
        case "lighter":
          return slideColors.lighterShift;
      }
    })(),
    borderColor: slideColors.legibleColor,
    color: slideColors.legibleColor,
    ...blockData.shadowed && { boxShadow: `10px 10px 0px ${slideColors.opacityColor}` }
  }

  // ----- Check if the link is of Block Content ----------------
  const checkBlockContent = (urlString) => {
    const origin = window.location.origin;

    const url = new URL(urlString);
    const urlOrigin = url.origin;
    const urlFirstPathName = url.pathname.split("/")[1];
    // console.log("urrOrigin: ", urlOrigin);
    // console.log("urlFirstPathName: ", urlFirstPathName);

    if (urlOrigin != origin || urlFirstPathName != "content") {
      return false;
    }

    return true;
  };

  const navigateToContentUsingUrl = (urlString) => {
    const url = new URL(urlString);
    const urlPath = url.pathname + url.search;

    navigate(urlPath);
  };

  const getOGP = (url) => {
    axios
      .get(`${endpoints.api}/fetch_ogp`, {
        params: {
          content_id: slidesData.id,
          slide_id: slidesData.slides[currentSlideIndex].id,
          block_id: blockData.id,
          url: url,
        },
      })
      .then((res) => {
        setLinkOgp(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (data.url && validateUrlString(data.url)) {
      getOGP(data.url);
    }
  }, [data.url]);

  React.useEffect(() => {
    setTimeout(() => {
      resizeFieldHeights([captionRef]);
    }, 500);
  }, [editMode, captionRef.current]);

  if (!editMode) {
    return (
      <div className="component-wrapper link-block-wrapper">
        <a
          className="link-wrapper scale-on-hover-small shadow-on-hover"
          href={data.url}
          target="_blank"
          // style={
          //   slideColors
          //     ? {
          //         backgroundColor: slideColors["darker"],
          //         color: slidesData.slides[currentSlideIndex].font_color,
          //         borderColor:
          //           slidesData.slides[currentSlideIndex].font_color,
          //       }
          //     : {
          //         color: slidesData.slides[currentSlideIndex].font_color,
          //         borderColor:
          //           slidesData.slides[currentSlideIndex].font_color,
          //       }
          // }
          style={
            slideColors && slideColors && boxStyleProperties
          }
          onClick={(e) => {
            const url = data.url;
            if (checkBlockContent(url)) {
              e.preventDefault();
              navigateToContentUsingUrl(url);
            }
          }}
        >
          {linkOgp.favicon && !faviconError ? (
            <img
              className="link-favicon"
              src={linkOgp.favicon}
              alt=""
              onError={() => {
                setFaviconError(true);
              }}
            />
          ) : (
            <FontAwesomeIcon className="link-icon" icon={faLink} />
          )}
          <div className="link-texts">
            {data.title ? (
              <span className="link-title">{data.title}</span>
            ) : (
              <span className="link-title">{linkOgp.page_title}</span>
            )}
            {linkOgp.site_title && (
              <span className="site-title">{linkOgp.site_title}</span>
            )}
            <span className="link-url">{data.url}</span>
          </div>
          {linkOgp.image_url &&
            !imageError &&
            !linkOgp.image_url.includes("lekchaamplifytwo") && (
              <img
                src={linkOgp.image_url}
                alt=""
                className="link-img"
                onError={() => {
                  setImageError(true);
                }}
              />
            )}
        </a>
        {data.caption != "" && <p className="caption">{data.caption}</p>}
      </div>
    );
  } else {
    return (
      <>
        <div className="component-wrapper link-block-wrapper">
          <div
            className={`link-wrapper cursor-pointer ${
              editMode ? "edit-mode" : ""
            }`}
            href={data.url}
            target="_blank"
            // style={
            //   slideColors
            //     ? {
            //         backgroundColor: slideColors["darker"],
            //         color: slidesData.slides[currentSlideIndex].font_color,
            //         borderColor:
            //           slidesData.slides[currentSlideIndex].font_color,
            //       }
            //     : {
            //         color: slidesData.slides[currentSlideIndex].font_color,
            //         borderColor:
            //           slidesData.slides[currentSlideIndex].font_color,
            //       }
            // }
            style={
              slideColors && slideColors && boxStyleProperties
            }
            onClick={() => {
              setLinkTitle(data.title);
              setLinkUrl(data.url);
              setLinkEdit(true);
            }}
          >
            {linkOgp.favicon && !faviconError ? (
              <img
                className="link-favicon"
                src={linkOgp.favicon}
                alt=""
                onError={() => {
                  setFaviconError(true);
                }}
              />
            ) : (
              <FontAwesomeIcon className="link-icon" icon={faLink} />
            )}
            <div className="link-texts">
              <span className="link-title">
                {data.title ? data.title : linkOgp.page_title}
              </span>
              {linkOgp.site_title && (
                <span className="site-title">{linkOgp.site_title}</span>
              )}

              {data.url == "" ? (
                <span className="link-url">タップしてURLを追加</span>
              ) : (
                <span className="link-url">{data.url}</span>
              )}
            </div>
          </div>
          <textarea
            ref={captionRef}
            className="caption"
            placeholder="キャプションを入力"
            value={data.caption.replace(/\n$/, "")}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              updateBlock([
                {
                  blockIndex: index,
                  value: e.target.value.replace(/\n$/, ""),
                  key: "caption",
                },
              ]);
              resizeFieldHeights([captionRef]);
            }}
          >
            {data.caption}
          </textarea>
        </div>
        <Overlay
          title="リンクを編集"
          open={linkEdit}
          setOpen={setLinkEdit}
          buttons={{
            primaryButton: {
              display: true,
              text: "保存",
              action: (e) => {
                if (validateUrlString(linkUrl)) {
                  updateBlock([
                    {
                      blockIndex: index,
                      value: linkTitle,
                      key: "title",
                    },
                    {
                      blockIndex: index,
                      value: linkUrl,
                      key: "url",
                    },
                  ]);
                  setLinkEdit(false);
                } else {
                  alert("正しいURLを入力してください。");
                }
              },
            },
            secondaryButton: {
              display: true,
              text: "キャンセル",
              action: (e) => {
                setLinkEdit(false);
              },
            },
          }}
        >
          <div className="link-edit-contents">
            <div className="link-edit-content">
              <span className="link-edit-content-title">
                タイトル (空欄にすると自動で挿入されます)
              </span>
              <input
                type="text"
                className="link-content-input"
                value={linkTitle}
                placeholder="タイトルを入力"
                onChange={(e) => {
                  setLinkTitle(e.target.value);
                }}
              />
            </div>
            <div className="link-edit-content">
              <span className="link-edit-content-title">URL</span>
              <input
                type="text"
                className="link-content-input"
                value={linkUrl}
                placeholder="URLを入力"
                onChange={(e) => {
                  setLinkUrl(e.target.value);
                }}
              />
            </div>
          </div>
        </Overlay>
      </>
    );
  }
};

export default LinkContent;
