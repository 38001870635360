import React from "react";
import "./Baskets.scss";

// router
import { useNavigate } from "react-router-dom";

// overlays
import EditBasketModal from "../../Overlays/EditBasketModal.js";

// loading
import Loading from "../../UtilityComponents/Loading.js";

// basket
import Basket from "./Basket.js";

// fonts
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// helpers
import { getBaskets, deleteBasket } from "../../helpers/BasketsHelper.js";

// framer motion
import { framerFadeUp } from "../../helpers/Utility";
import { motion } from "framer-motion";

const Baskets = ({ auth }) => {
  const navigate = useNavigate();

  // ----- States ---------------
  const [baskets, setBaskets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [basketEditModal, setBasketEditModal] = React.useState(false);

  // ----- functions --------------
  const loadBaskets = () => {
    setLoading(true);
    getBaskets(auth.authState.cognito_user.username)
      .then((baskets) => {
        setLoading(false);
        setBaskets(baskets);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeBasket = (basketId) => {
    if (
      !window.confirm(
        "このスペースを削除しますか？ (スペース内のコンテンツは削除されません)"
      )
    )
      return;
    deleteBasket(auth.authState.cognito_user.username, basketId)
      .then((res) => {
        loadBaskets();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ----- Load Baskets ---------------
  React.useEffect(() => {
    loadBaskets();
  }, []);

  // ------ render -----------------

  if (loading) {
    return <Loading minHeight={0} />;
  } else {
    return (
      <div className="baskets-wrapper">
        <div className="baskets">
          {baskets.length == 0 && (
            <span className="empty-message">まだスペースがありません。</span>
          )}
          {baskets.sort((a, b) => b.public - a.public).map((basket, index) => {
            return (
              <motion.div
                className="basket"
                key={basket.id}
                variants={framerFadeUp}
                initial="hidden"
                animate="show"
                transition={{
                  ...framerFadeUp.transition,
                  delay: 0.2 * (index + 1),
                }}
              >
                <Basket
                  auth={auth}
                  basketData={basket}
                  control={true}
                  showPublished={true}
                  showContentPublished={true}
                  removeBasket={removeBasket}
                  loadBaskets={loadBaskets}
                />
              </motion.div>
            );
          })}
        </div>
        <div
          className="baskets-add-new-button cursor-pointer scale-on-hover"
          onClick={() => {
            setBasketEditModal(true);
          }}
        >
          <FontAwesomeIcon className="icon" icon={faPlus} />
          <span className="text">スペースを作成</span>
        </div>

        <EditBasketModal
          auth={auth}
          loadBaskets={loadBaskets}
          basketEditModal={basketEditModal}
          setBasketEditModal={setBasketEditModal}
        />
      </div>
    );
  }
};

export default Baskets;
