import React from "react";
import "./ContentComponents.scss";
import "./FormBlock.scss";

import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faCircleCheck,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

// helpers
import { validateEmailString } from "../helpers/Utility";
import { submitFormBlockData } from "../helpers/PeopleSuiteHelper";

// modals
import SlideModal from "../Overlays/SlideModal";

const FormBlock = ({
  blockData,
  currentSlideIndex,
  slidesData,
  updateSlidesData,
  slideColors,
  index,
  editMode,
  updateBlock,
  updateBlockWrapper,
  viewershipStates,
}) => {
  // ----- constants -------------
  const defaults = {
    title: "メンバー登録",
    message: "メールを登録して、ニュースを受け取りましょう。",
    button_text: "登録する",
    thanks_message: "ご登録ありがとうございます！",
  };

  // ----- states -------------
  // edit form block data
  const [formBlockData, setFormBlockData] = React.useState(
    blockData.child_data
  );

  // form submit data
  const [email, setEmail] = React.useState("");
  const [nickname, setNickname] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);
  const [formEditModalOpen, setFormEditModalOpen] = React.useState(false);

  // ------ Functions ----------------
  const initForms = () => {
    setEmail("");
    setNickname("");
    setEmailValid(false);
  };

  // edit functions

  const updateFormData = (key, value) => {
    setFormBlockData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const submitEditData = () => {
    updateBlock([
      {
        blockIndex: index,
        value: formBlockData,
      },
    ]);
    setFormEditModalOpen(false);
  };

  // form functions
  const submitFormData = () => {
    submitFormBlockData({
      formBlockId: formBlockData.id,
      person: {
        email: email,
        nickname: nickname,
      },
    })
      .then((res) => {
        displayThanksMessage();
      })
      .catch((err) => {
        const errType = err.response.data.type;
        switch (errType) {
          case "EmailExists":
            displayThanksMessage();
            break;
          default:
            alert("エラーが発生しました。");
            break;
        }
      });
  };

  const displayThanksMessage = () => {
    viewershipStates.form.setFormState((prev) => ({
      ...prev,
      [formBlockData.id]: {
        submitted: true,
      },
    }));
  };

  // ----- effects -------------
  // validate email when email changes
  React.useEffect(() => {
    if (!editMode) {
      if (email != "") {
        if (validateEmailString(email)) {
          setEmailValid(true);
        } else {
          setEmailValid(false);
        }
      }
    }
  }, [email]);

  // reset forms when editMode changes
  React.useEffect(() => {
    initForms();
  }, [editMode]);

  return (
    <>
      <div
        className={`component-wrapper form-block-wrapper ${
          editMode ? "edit-mode" : ""
        }`}
        onClick={() => {
          if (!editMode) return;
          setFormEditModalOpen(true);
        }}
        id={`form-block-${blockData.id}`}
      >
        {editMode && (
          <div className="form-block-edit-overlay cursor-pointer scale-on-hover-small">
            <span className="cursor-pointer scale-on-hover">
              タップして編集
            </span>
          </div>
        )}
        <div className="form-block-title-section">
          <div className="form-block-title">
            <FontAwesomeIcon icon={faUserPlus} className="form-block-icon" />
            <span className="form-block-title-text">
              {formBlockData.title ? formBlockData.title : defaults.title}
            </span>
          </div>

          <div className="line"></div>

          <p className="form-block-message">
            {formBlockData.message ? formBlockData.message : defaults.message}
          </p>
        </div>
        {viewershipStates.form.formState[formBlockData.id]?.submitted ? (
          <>
            <div className="line"></div>
            <div className="form-block-complete-section">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="form-block-complete-icon"
              />
              <span>
                {formBlockData.thanks_message != ""
                  ? formBlockData.thanks_message
                  : defaults.thanks_message}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="form-block-form-section">
              <div className="form-block-form-wrapper">
                <span className="form-block-form-title">メールアドレス</span>
                <input
                  placeholder="メールアドレスを入力 ..."
                  type="text"
                  className={`form-block-form ${editMode ? "edit-mode" : ""}`}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              {emailValid && (
                <div className="form-block-form-wrapper">
                  <span className="form-block-form-title">
                    おなまえ / ニックネーム
                  </span>
                  <input
                    placeholder="おなまえ / ニックネーム"
                    type="text"
                    className={`form-block-form ${editMode ? "edit-mode" : ""}`}
                    value={nickname}
                    onChange={(e) => {
                      setNickname(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`form-block-button ${
                emailValid && nickname != "" ? "" : "disabled"
              } cursor-pointer scale-on-hover-small`}
              onClick={() => {
                if (!emailValid || nickname == "") return;
                submitFormData();
              }}
            >
              <span>
                {formBlockData.button_text
                  ? formBlockData.button_text
                  : defaults.button_text}
              </span>
            </div>
          </>
        )}
      </div>

      <SlideModal
        title="登録フォームの表示編集"
        occupationSteps={[0.4, 0.5, 0.6]}
        open={formEditModalOpen}
        setOpen={setFormEditModalOpen}
        parentElForPaddingAdjustment={
          document.getElementsByClassName("slide-contents-wrapper")[0]
        }
      >
        <div className="form-block-edit-modal-wrapper">
          <div className="form-block-edit-modal-forms">
            <div className="form-block-edit-modal-form">
              <span className="form-title">見出し</span>
              <input
                type="text"
                placeholder={defaults.title}
                className="form"
                value={formBlockData.title}
                onChange={(e) => {
                  updateFormData("title", e.target.value);
                }}
              />
            </div>

            <div className="form-block-edit-modal-form">
              <span className="form-title">メッセージ</span>
              <textarea
                type="text"
                placeholder={defaults.message}
                className="form-text"
                value={formBlockData.message}
                onChange={(e) => {
                  updateFormData("message", e.target.value);
                }}
              />
            </div>

            <div className="form-block-edit-modal-form">
              <span className="form-title">ボタンテキスト</span>
              <input
                type="text"
                placeholder={defaults.button_text}
                className="form"
                value={formBlockData.button_text}
                onChange={(e) => {
                  updateFormData("button_text", e.target.value);
                }}
              />
            </div>

            <div className="line"></div>

            <div className="form-block-edit-modal-form">
              <span className="form-title">登録後メッセージ</span>
              <input
                type="text"
                placeholder={defaults.thanks_message}
                className="form"
                value={formBlockData.thanks_message}
                onChange={(e) => {
                  updateFormData("thanks_message", e.target.value);
                }}
              />
            </div>
          </div>

          <div
            className="form-block-edit-modal-button cursor-pointer scale-on-hover-small"
            onClick={() => {
              submitEditData();
            }}
          >
            <span>保存する</span>
          </div>
        </div>
      </SlideModal>
    </>
  );
};

export default FormBlock;
