import React, { useRef } from "react";
import "./Carousel.scss";
// framer
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "../../screens/Admin/AdminComponents/AdminComponents";

const Carousel = ({ carouselItems, children }) => {
  // ------ States --------
  const [[page, direction], setPage] = React.useState([0, 0]);
  const [elementHeight, setElementHeight] = React.useState(0);

  // ------ Variables --------
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  // ----- Functions --------
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const updateHeight = () => {
    // get the element with data-carousel-item-key
    const listItemElement = document.querySelector(
      `[data-carousel-item-key="${pageIndex()}"]`
    );

    // set height of current carousel item
    setElementHeight(listItemElement.offsetHeight);
  };

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const pageIndex = () => {
    const computedPageIndex = (() => {
      if (page == 0) return 0;
      if (page > 0) {
        return page % children.length;
      } else {
        const negativeIndex = page % children.length;
        if (negativeIndex == 0) return 0;
        return negativeIndex + children.length;
      }
    })();
    return computedPageIndex;
  };

  // ------ Effects --------

  React.useEffect(() => {
    updateHeight();
  }, [page, direction, children]);

  // ------ Render -----------

  return (
    <div className="carousel-wrapper">
      <motion.div
        className="carousel-window"
        animate={{ height: elementHeight }}
      >
        <div
          className="prev-button prevent-select"
          onClick={() => {
            paginate(-1);
          }}
        >
          <span>{"<"}</span>
        </div>
        <div className="carousel-items">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={page}
              data-carousel-item-key={pageIndex()}
              className="carousel-item-wrapper"
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
              onLoad={() => {
                updateHeight();
              }}
            >
              {children[pageIndex()]}
            </motion.div>
          </AnimatePresence>
        </div>
        <div
          className="next-button prevent-select"
          onClick={() => {
            paginate(1);
          }}
        >
          <span>{">"}</span>
        </div>
      </motion.div>

      <div className="carousel-dots">
        {Array(children.length)
          .fill(0)
          .map((item, index) => {
            return (
              <div
                key={index}
                className={`carousel-dot ${
                  index == pageIndex() ? "active" : ""
                }`}
                onClick={() => {
                  paginate(index - pageIndex());
                }}
              ></div>
            );
          })}
      </div>
    </div>
  );
};

export default Carousel;
