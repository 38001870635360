import endpoints from "./apiUrls";
import axios from "axios";

// ----- Users List -------------
export const get_users_list = (auth) => {
  return axios
    .post(`${endpoints.api}/admin_sections/users_list`, {
      cognito_id: auth.authState.cognito_user.username,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

// ----- Contents List -------------
export const get_contents_list = (auth) => {
  return axios
    .post(`${endpoints.api}/admin_sections/contents_list`, {
      cognito_id: auth.authState.cognito_user.username,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};