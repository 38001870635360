import React from "react";
import "./Overlay.scss";
import { motion } from "framer-motion";

// atoms
import CloseButton from "../components/Atoms/CloseButton";

// framer motion
const framerFade = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

const framerFadeUp = {
  hidden: {
    opacity: 0,
    translateY: 10,
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

const Overlay = ({
  open,
  setOpen,
  title,
  description = "",
  children,
  buttons,
  fullScreen = false,
}) => {
  const closeOverlay = () => {
    document.body.style.overflow = "auto";
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  if (open) {
    return (
      <motion.div
        className={`overlay-wrapper ${fullScreen ? "full-screen" : ""}`}
        variants={framerFade}
        initial="hidden"
        animate="show"
        transition={framerFade.transition}
      >
        <motion.div
          className="overlay-contents-wrapper"
          variants={framerFadeUp}
          initial="hidden"
          animate="show"
          transition={{
            ...framerFadeUp.transition,
            delay: 0.3,
          }}
        >
          <div className="overlay-contents">
            <div className="overlay-title-section container">
              <div className="overlay-title-wrapper">
                <span className="overlay-title">{title}</span>
                <CloseButton className="overlay-close" handleClick={closeOverlay} />
              </div>

              {description != "" && (
                <p className="overlay-description">{description}</p>
              )}
            </div>

            <div className="overlay-children-container container">
              {children}
            </div>
            
            {(buttons.secondaryButton.display ||
              buttons.primaryButton.display) && (
                <div className="overlay-buttons container">
                  {buttons.secondaryButton.display && (
                    <div
                      className="overlay-button secondary"
                      onClick={() => {
                        buttons.secondaryButton.action();
                      }}
                    >
                      <span>{buttons.secondaryButton.text}</span>
                    </div>
                  )}

                  {buttons.primaryButton.display && (
                    <div
                      className="overlay-button primary"
                      onClick={() => {
                        buttons.primaryButton.action();
                      }}
                    >
                      <span>{buttons.primaryButton.text}</span>
                    </div>
                  )}
                </div>
              )}
          </div>
        </motion.div>
      </motion.div>
    );
  }
};

export default Overlay;
