import React from "react";
import "./ProfileSettings.scss";

// overlays
import Overlay from "../Overlays/Overlay.js";

// helpers
import {
  validateHandleFormat,
  checkHandleAvailability,
} from "../helpers/ProfileHelper";

// コンポーネント
import ImageUpload from "../components/MediaComponents/ImageUpload.js";

// form components
import FormsWrapper from "./OverlayComponents/FormsWrapper.js";
import FormWrapper from "./OverlayComponents/FormWrapper.js";
import FormContent from "./OverlayComponents/FormContent.js";

// icon
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findAllInRenderedTree } from "react-dom/test-utils";

const ProfileSettings = ({
  auth,
  profileData,
  submitProfileData,
  profileSettingsModal,
  setProfileSettingsModal,
}) => {
  // ------ Content state -----------------
  const [profileEditData, setProfileEditData] = React.useState(profileData);
  const [edited, setEdited] = React.useState(false);

  const [handleAvailable, setHandleAvailable] = React.useState({
    available: null,
    message: "",
    color: "",
  });

  // ------ Functions -----------------
  const closeModalWithWarning = (value) => {
    if (value == false && edited == true) {
      if (
        window.confirm("変更は保存されません。よろしいですか？", "") == false
      ) {
        return;
      }
    }
    setEdited(false);
    setProfileSettingsModal(value);
  };

  const submitProfileEditData = () => {
    if (handleAvailable.available == false) {
      alert("使用できないハンドルネームが設定されています。");
      return;
    }
    submitProfileData(profileEditData);
    setEdited(false);
  };

  // inserted as a middleware to track if the data is edited to ask before closing or canceling.
  const updateProfileEditData = (key, value) => {
    setEdited(true);
    setProfileEditData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateHandleName = (value) => {
    const formatted = value.replace(/\s/g, "").toLowerCase();

    updateProfileEditData("handle_name", formatted);
  };

  // ------ Effects -----------------

  React.useEffect(() => {
    if (!profileSettingsModal) return;
    setProfileEditData(profileData);
  }, [profileSettingsModal]);

  // validates handle name
  React.useEffect(() => {
    if (!profileEditData) return;

    if (profileEditData.handle_name == profileData.handle_name) {
      setHandleAvailable({ available: null, message: "", color: "" });
      return;
    }

    if (profileEditData.handle_name == "") {
      setHandleAvailable({
        available: true,
        message: "ハンドルを削除します。",
        color: "green",
      });
      return
    }

    const formatValidation = validateHandleFormat(profileEditData.handle_name);
    console.log(formatValidation);
    if (formatValidation.result == true) {
      checkHandleAvailability(
        profileEditData.handle_name,
        auth.authState.cognito_user.username
      ).then((res) => {
        setHandleAvailable({
          available: res.result,
          message: res.message,
          color: res.result ? "green" : "red",
        });
      });
    } else {
      setHandleAvailable({
        available: false,
        message: formatValidation.message,
        color: "red",
      });
      return;
    }
  }, [profileEditData]);

  return (
    <Overlay
      open={profileSettingsModal}
      setOpen={(value) => {
        closeModalWithWarning(value);
      }}
      title="(Beta) プロフィール設定"
      buttons={{
        primaryButton: {
          display: true,
          text: "保存",
          action: (e) => {
            submitProfileEditData();
          },
        },
        secondaryButton: {
          display: true,
          text: "キャンセル",
          action: (e) => {
            closeModalWithWarning(false);
          },
        },
      }}
    >
      <div className="profile-settings-wrapper">
        <FormsWrapper>
          <FormWrapper formTitle={"スペースの設定"}>
            <FormContent subTitle={"スペース名"}>
              <input
                type="text"
                value={profileEditData.display_name}
                onChange={(e) => {
                  updateProfileEditData("display_name", e.target.value);
                }}
              />
            </FormContent>

            <FormContent subTitle={"スペースの説明"}>
              <textarea
                value={profileEditData.profile_bio}
                onChange={(e) => {
                  updateProfileEditData(
                    "profile_bio",
                    e.target.value.replace(/(\r\n|\n|\r)/gm, "")
                  );
                }}
              />
            </FormContent>
          </FormWrapper>

          <FormWrapper
            formTitle={"ハンドル"}
            formDescription={"Pulpハンドルを設定します。"}
          >
            <FormContent customClassName="handle-section-wrapper">
              <div className="handle-form-wrapper">
                <span className="handle-icon">@</span>
                <input
                  value={profileEditData.handle_name}
                  onChange={(e) => {
                    updateHandleName(e.target.value);
                  }}
                />
              </div>

              {handleAvailable.available != null && (
                <div
                  className={`handle-availability ${
                    handleAvailable.color
                  }`}
                >
                  <FontAwesomeIcon
                    icon={handleAvailable.color == "green" ? faCheck : faXmark}
                    className="availability-icon"
                  />

                  <span className="availability-text">
                    {handleAvailable.message}
                  </span>
                </div>
              )}
            </FormContent>
          </FormWrapper>

          <FormWrapper
            formTitle={"プロフィール画像"}
            formDescription={"プロフィール画像を設定します。"}
          >
            <FormContent>
              <ImageUpload
                userId={auth.authState.cognito_user.username}
                currentImageSrc={profileEditData.profile_image_url}
                setImageData={(imageUrl) => {
                  updateProfileEditData("profile_image_url", imageUrl);
                }}
                unsplashEnabled={true}
              />
            </FormContent>
          </FormWrapper>
        </FormsWrapper>
      </div>
    </Overlay>
  );
};

export default ProfileSettings;
