import React from "react";
import "./SingleBlockContentInfo.scss";

// icons
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleBlockContentInfo = ({ content, singleBlockData }) => {
  const blockType = singleBlockData?.child_type;


  return (
    <div className="single-block-content-info">
      {(() => {
        switch (blockType) {
          case "video":
            return (
              <div className="single-block-content-info__video">
                <img
                  className="scale-on-hover"
                  src={`https://image.mux.com/${singleBlockData.child_data.mux_playback_id}/animated.webp`}
                  alt=""
                />
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faPlay} className="video-icon" />
                </div>
              </div>
            );
        }
      })()}
    </div>
  );
};

export default SingleBlockContentInfo;
