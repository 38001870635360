import React from "react";
import "./ImageUpload.scss";

// icons
import {
  faBorderAll,
  faArrowUp,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import Uploading from "../../components/Uploading.js";
import UnsplashWindow from "./UnsplashWindow.js";

// helpers
import {
  uploadImageHelper,
  getImageUrlFromS3Path,
} from "../../helpers/fileHelpers";

const ImageUpload = ({
  userId,
  currentImageSrc,
  setImageData,
  unsplashEnabled = false,
  deleteEnabled = true,
}) => {
  const placeHolderImage = "/images/placeholder_image.jpg";

  // ----- states ---------------
  const [imageExists, setImageExists] = React.useState(
    currentImageSrc ? true : false
  );
  const [unsplashOpen, setUnsplashOpen] = React.useState(false);

  const [uploadStatus, setUploadStatus] = React.useState({
    uploading: false,
    upload_progress: 0,
    message: "",
  });

  // ----- refs ---------------
  const inputFile = React.useRef(null);

  // ----- functions ---------------
  const uploadImage = (imageFile) => {
    if (!imageFile) return;

    const userIdForPath = userId;

    // compression progress function
    const compressionProgressFunction = (progress) => {
      setUploadStatus((prev) => ({
        ...prev,
        uploading: true,
        message: "圧縮中 ...",
        upload_progress: progress,
      }));
    };

    // upload progress function
    const uploadProgressFunction = (progress) => {
      setUploadStatus((prev) => ({
        ...prev,
        message: "アップロード中",
        upload_progress: progress,
      }));
    };

    // completion function
    const completionCallbackFunction = (result) => {
      const fullImageUrl = getImageUrlFromS3Path(result.key);
      setImageData(fullImageUrl);
      setUploadStatus({
        uploading: false,
        upload_progress: 0,
        message: "",
      });
    };

    // upload the image
    uploadImageHelper(
      imageFile,
      userIdForPath,
      compressionProgressFunction,
      uploadProgressFunction,
      completionCallbackFunction
    );
  };

  const resetImage = () => {
    if (!deleteEnabled) return;
    if (!window.confirm("画像を削除しますか？")) return;

    setImageData("");
  };

  // ----- effects ---------------
  React.useEffect(() => {
    setImageExists(currentImageSrc ? true : false);
  }, [currentImageSrc]);

  return (
    <div className="image-upload-component-wrapper">
      <div className="image-section">
        <div className="image-preview-section">
          <img
            src={imageExists ? currentImageSrc : placeHolderImage}
            className="image-preview cursor-pointer scale-on-hover"
            alt=""
            onClick={() => {
              inputFile.current.click();
            }}
            onError={() => {
              setImageExists(false);
            }}
          />
          <input
            className="image-upload-input"
            ref={inputFile}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => uploadImage(e.target.files[0])}
          />
        </div>

        {uploadStatus.uploading && (
          <Uploading
            message={uploadStatus.message}
            percentage={uploadStatus.upload_progress}
          />
        )}
      </div>
      <div className="image-info-section">
        {unsplashEnabled && (
          <div
            className="image-upload-navigation cursor-pointer scale-on-hover"
            onClick={() => {
              setUnsplashOpen(true);
            }}
          >
            <div className="image-upload-button">
              <FontAwesomeIcon icon={faBorderAll} />
              <span>画像を検索</span>
            </div>
          </div>
        )}

        <div
          className="image-upload-navigation cursor-pointer scale-on-hover"
          onClick={() => {
            inputFile.current.click();
          }}
        >
          <div className="image-upload-button">
            <FontAwesomeIcon icon={faArrowUp} />
            <span>アップロード</span>
          </div>
        </div>

        {deleteEnabled && imageExists && (
          <div
            className="image-upload-navigation cursor-pointer scale-on-hover"
            onClick={() => {
              resetImage();
            }}
          >
            <div className="image-upload-button image-upload-navigation-delete">
              <FontAwesomeIcon icon={faTrashCan} />
              <span>削除</span>
            </div>
          </div>
        )}
      </div>

      <UnsplashWindow
        unsplashOpen={unsplashOpen}
        setUnsplashOpen={setUnsplashOpen}
        setImageUrl={setImageData}
      />
    </div>
  );
};

export default ImageUpload;
