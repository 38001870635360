import React from "react";

// icons
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CloseButton = ({ handleClick=null, bgColor="white", fontColor="black", className }) => {
  // ----- style ---------------
  const style = {
    padding: "5px",
    width: "25px",
    height: "25px",
    boxSizing: "border-box",
    borderRadius: "100px",
    backgroundColor: bgColor,
    color: fontColor,

    cursor: "pointer",
  };

  // ----- Render --------------
  return (
    <FontAwesomeIcon
      className={`close-button ${className}`}
      icon={faXmark}
      style={style}
      onClick={(e) => {
        if (handleClick) {
          handleClick(e);
        } else {
          e.preventDefault();
        }
      }}
    />
  );
};

export default CloseButton;
