import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

export default () => {
  // api connection テストコード
  axios
  .get(`${endpoints.api}/test`)
  .then((res) => {
    // console.log("api connection success", res);
  })
  .catch((err) => {
    console.log(`サーバーに接続できませんでした。\n\n ${JSON.stringify(err)}`);
    alert(`サーバーに接続できませんでした。\n\n ${JSON.stringify(err)}`);
  });
}