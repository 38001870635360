import React from "react";
import "./Profile.scss";

// navigate
import { useNavigate } from "react-router-dom";

// loading
import Loading from "../../UtilityComponents/Loading.js";

// helpers
import {
  getContents,
  getProfile,
  createProfile,
  updateProfile,
  generateProfileLink,
} from "../../helpers/ProfileHelper.js";
import { deleteContent } from "../../helpers/ContentHelper";
import { copyToClipboard } from "../../helpers/Utility";

// icons
import {
  faTrashCan,
  faUserGear,
  faLink,
  faArrowUpRightFromSquare,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// framer motion
import { framerFadeUp } from "../../helpers/Utility";
import { motion } from "framer-motion";

// content list
import ContentList from "../../HomeComponents/ContentList.js";

// basket
import Basket from "./Basket";

// overlays
import ProfileSettings from "../../Overlays/ProfileSettings";
import FeatureStart from "./SubScreens/FeatureStart";

const Profile = ({ auth }) => {
  // ----- States ---------------
  // loading
  const [loading, setLoading] = React.useState(true);

  // プロフィール設定のデータ
  const [profileData, setProfileData] = React.useState({});

  // コンテンツを管理するステート変数
  const [baskets, setBaskets] = React.useState([]);

  // プロフィール設定のモーダル
  const [profileSettingsModal, setProfileSettingsModal] = React.useState(false);

  // ----- Navigate ---------------
  const navigate = useNavigate();

  // ----- functions --------------
  const loadContents = () => {
    var cognito_id = auth.authState.cognito_user.username;

    getContents(cognito_id).then((baskets) => {
      setLoading(false);
      setBaskets(baskets);
    });
  };

  // コンテンツの削除
  const requestContentDelete = (content) => {
    deleteContent(
      true,
      auth.authState.cognito_user.username,
      content.id,
      loadContents
    );
  };

  // プロフィールの作成
  const createProfileStart = () => {
    createProfile(auth.authState.cognito_user.username).then((res) => {
      setProfileData(res.profile);
      setProfileSettingsModal(true);
    });
  };

  // プロフィールの更新
  const submitProfileData = (data) => {
    updateProfile(auth.authState.cognito_user.username, data).then((res) => {
      setProfileData(res.profile);
      setProfileSettingsModal(false);
    });
  };

  // ----- effects ---------------
  React.useEffect(() => {
    // load the content
    loadContents();

    // get the profile data
    getProfile(auth.authState.cognito_user.username).then((res) => {
      setProfileData(res.profile);
    });
  }, [auth]);

  React.useEffect(() => {
    // console.log(baskets);
  }, [baskets]);

  // ----- render ---------------

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="profile-wrapper">
      {profileData == null ? (
        <FeatureStart
          icon={faUser}
          header="プロフィールを作成"
          message="プロフィールを作成してコンテンツをシェアしましょう！"
          buttonText="プロフィールを作成"
          buttonCallback={() => {
            createProfileStart();
          }}
        />
      ) : (
        <>
          <div className="profile-info-section">
            <div className="image-section">
              <div className="image-wrapper">
                {profileData.profile_image_url ? (
                  <img
                    src={profileData.profile_image_url}
                    alt=""
                    className="person-icon"
                  />
                ) : (
                  <span className="person-icon-no-image">🤝</span>
                )}
              </div>
            </div>
            <div className="text-section">
              {profileData.display_name ? (
                <span className="profile-header">
                  {profileData.display_name}
                </span>
              ) : (
                <span className="profile-header">Your Name</span>
              )}
              {profileData.handle_name && (
                <span className="handle-name">@{profileData.handle_name}</span>
              )}
              {profileData.profile_bio && (
                <p className="profile-description">{profileData.profile_bio}</p>
              )}
            </div>
          </div>
          <div className="profile-control-section">
            <div
              className="edit-profile-button cursor-pointer scale-on-hover"
              onClick={() => {
                setProfileSettingsModal(true);
              }}
            >
              <FontAwesomeIcon
                className="edit-profile-icon"
                icon={faUserGear}
              />
              <span className="edit-profile-button-text">プロフィール設定</span>
            </div>

            <div
              className="profile-link-button cursor-pointer scale-on-hover"
              onClick={() => {
                if (profileData.handle_name) {
                  copyToClipboard(
                    generateProfileLink(profileData.handle_name),
                    true,
                    "プロフィールのリンクをコピーしました"
                  );
                } else {
                  alert(
                    "プロフィールリンクを発行するには、プロフィール設定からハンドルネームの設定を行なってください。"
                  );
                  return;
                }
              }}
            >
              <FontAwesomeIcon className="profile-link-icon" icon={faLink} />
            </div>
            <div
              className="profile-link-button cursor-pointer scale-on-hover"
              onClick={() => {
                navigate(`/profile/${profileData.handle_name}`);
              }}
            >
              <FontAwesomeIcon
                className="profile-link-icon small"
                icon={faArrowUpRightFromSquare}
              />
            </div>
          </div>
          <div className="contents-section">
            {/* <ContentList
          contents={contents}
          loading={loading}
          permissionToEdit={false}
          menuVisible={true}
          requestContentDelete={requestContentDelete}
          rotateEnabled={true}
          showPublished={false}
          noContentMessage={`まだコンテンツがありません。コンテンツ編集画面から、コンテンツを公開してみてください！`}
          updatedBadge={true}
        /> */}

            {baskets
              .sort((a, b) => b.public - a.public)
              .map((basket, index) => {
                return (
                  <motion.div
                    className="basket"
                    key={basket.id}
                    variants={framerFadeUp}
                    initial="hidden"
                    animate="show"
                    transition={{
                      ...framerFadeUp.transition,
                      delay: 0.2 * (index + 1),
                    }}
                  >
                    <Basket
                      auth={auth}
                      basketData={basket}
                      control={false}
                      showPublished={true}
                      showContentPublished={true}
                    />
                  </motion.div>
                );
              })}
          </div>
          <ProfileSettings
            auth={auth}
            profileData={profileData}
            submitProfileData={submitProfileData}
            profileSettingsModal={profileSettingsModal}
            setProfileSettingsModal={setProfileSettingsModal}
          />
        </>
      )}
    </div>
  );
};

export default Profile;
