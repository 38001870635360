import process from "process";


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev
    var development = true;
} else {
    // production
    var development = false
}

// override
// development = false;
// development = true;



export default function isDev(): boolean
{
    console.log("development: ", development);
    return development;
}