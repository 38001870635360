import React from "react";
import "./EditContentOverlay.scss";

// コンポーネント
import ImageUpload from "../components/MediaComponents/ImageUpload.js";

// Overlays
import Overlay from "./Overlay.js";

// form components
import FormsWrapper from "./OverlayComponents/FormsWrapper.js";
import FormWrapper from "./OverlayComponents/FormWrapper.js";
import FormContent from "./OverlayComponents/FormContent.js";

const EditContentOverlay = ({
  contentData,
  setContentData,
  contentEditModal,
  setContentEditModal,
}) => {
  // ------ Content state -----------------
  const [contentEditData, setContentEditData] = React.useState(contentData);
  const [edited, setEdited] = React.useState(false);

  // ------ Functions -----------------
  const closeModalWithWarning = (value) => {
    if (value == false && edited == true) {
      if (
        window.confirm("変更は保存されません。よろしいですか？", "") == false
      ) {
        return;
      }
    }
    setEdited(false);
    setContentEditModal(value);
  };

  const submitContentEditData = () => {
    setContentData(contentEditData);
    setEdited(false);
    setContentEditModal(false);
  };

  // inserted as a middleware to track if the data is edited to ask before closing or canceling.
  const updateContentEditData = (key, value) => {
    setEdited(true);
    setContentEditData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // ------ Effects -----------------
  React.useEffect(() => {
    if (!contentEditModal) return;
    setContentEditData(contentData);
  }, [contentEditModal]);

  React.useEffect(() => {
    // console.log(contentEditData);
  }, [contentEditData]);

  return (
    <Overlay
      title="コンテンツ設定"
      open={contentEditModal}
      setOpen={(value) => {
        closeModalWithWarning(value);
      }}
      buttons={{
        primaryButton: {
          display: true,
          text: "保存",
          action: (e) => {
            submitContentEditData();
          },
        },
        secondaryButton: {
          display: true,
          text: "キャンセル",
          action: (e) => {
            closeModalWithWarning(false);
          },
        },
      }}
    >
      <div className="edit-content-overlay-wrapper">
        <FormsWrapper>
          <FormWrapper
            formTitle={"コンテンツの公開"}
            formDescription={
              <>
                <strong>
                  (公開されていないコンテンツでも、リンクをシェアすれば閲覧できます。)
                </strong>
                <br />
                コンテンツを公開すると、プロフィールに表示されます。
              </>
            }
            customClassName="emphasis-wrapper"
          >
            <FormContent customClassName="flex-section">
              <input
                type="checkbox"
                id="cover-checkbox"
                checked={contentEditData.public}
                onChange={(e) => {
                  updateContentEditData("public", e.target.checked);
                }}
              />
              <label htmlFor="cover-checkbox">コンテンツを公開する</label>
            </FormContent>
          </FormWrapper>

          <FormWrapper formTitle={"タイトルと説明"}>
            <FormContent subTitle={"タイトル"}>
              <input
                type="text"
                value={contentEditData.title}
                onChange={(e) => {
                  updateContentEditData("title", e.target.value);
                }}
              />
            </FormContent>

            <FormContent subTitle={"説明"}>
              <textarea
                value={contentEditData.content_description}
                onChange={(e) => {
                  updateContentEditData(
                    "content_description",
                    e.target.value.replace(/(\r\n|\n|\r)/gm, "")
                  );
                }}
              />
            </FormContent>
          </FormWrapper>

          <FormWrapper
            formTitle={"サムネイル画像"}
            formDescription={"コンテンツのサムネイル画像を設定します。"}
          >
            <FormContent>
              <ImageUpload
                userId={contentEditData.user_id}
                currentImageSrc={contentEditData.thumbnail_image_url}
                setImageData={(imageUrl) => {
                  updateContentEditData("thumbnail_image_url", imageUrl);
                }}
                unsplashEnabled={true}
              />
            </FormContent>
          </FormWrapper>

          <FormWrapper
            formTitle={"表紙"}
            formDescription={
              "コンテンツをロードした際に、表紙を表示するかを設定します。"
            }
          >
            <FormContent customClassName="flex-section">
              <input
                type="checkbox"
                id="cover-checkbox"
                checked={contentEditData.display_cover}
                onChange={(e) => {
                  updateContentEditData("display_cover", e.target.checked);
                }}
              />
              <label htmlFor="cover-checkbox">表紙を表示する</label>
            </FormContent>
          </FormWrapper>

          <FormWrapper
            formTitle={"メモ"}
            formDescription={
              <>
                <strong>あなただけに表示されます。</strong>
                <br />
                このコンテンツについてのメモを残すことができます。
              </>
            }
          >
            <FormContent>
              <textarea
                value={contentEditData.content_memo}
                onChange={(e) => {
                  updateContentEditData("content_memo", e.target.value);
                }}
              />
            </FormContent>
          </FormWrapper>
        </FormsWrapper>
      </div>
    </Overlay>
  );
};

export default EditContentOverlay;
