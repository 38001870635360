import "./AdminScreenWrapper.scss";

// App components
import AppHeader from "../CreatorScreens/AppHeader.js";

// components
import AdminSectionWrapper from "./AdminSectionWrapper.js";

// notification test
import NotificationTest from "./NotificationTest";
import PeopleTest from "./PeopleTest";
import UsersList from "./UsersList";
import ContentsList from "./ContentsList";
import ComponentsTest from "./ComponentsTest";

const AdminScreenWrapper = ({ auth }) => {

  return (
    <div className="admin-screen-wrapper-wrapper">
      {/* App Header */}
      <AppHeader auth={auth} display={true} backgrcoundColor={"white"} />

      <div className="admin-screen-container">
        <span className="admin-header">👨🏻‍💻 Admin</span>

        <AdminSectionWrapper
          title={"👱🏻‍♀️ ユーザーリスト"}
          description={
            "Pulp admin上のCRM的な位置付けです。"
          }
          sectionType={"admin"}
        >
          <UsersList auth={auth} />
        </AdminSectionWrapper>

        <AdminSectionWrapper
          title={"📱 コンテンツリスト"}
          description={
            "全ユーザーのコンテンツです。"
          }
          sectionType={"admin"}
        >
          <ContentsList auth={auth} />
        </AdminSectionWrapper>

        <AdminSectionWrapper
          title={"コンポーネントテスト"}
          description={
            "開発中のコンポーネントのテスト一覧です。"
          }
          sectionType={"components-test"}
        >
          <ComponentsTest />
        </AdminSectionWrapper>

        <AdminSectionWrapper
          title={"🔔 通知テスト"}
          description={
            "クリエイター向けの通知をテストします。PWAで正確に動作するかが焦点です。"
          }
          sectionType={"feature-test"}
        >
          <NotificationTest auth={auth} />
        </AdminSectionWrapper>

        <AdminSectionWrapper
          title={"🙋🏻‍♂️ ピープルSuite テスト"}
          description={
            "ピープル関連の機能をテストします。Community画面・FormBlock・メールなどの動作の確認が含まれます。"
          }
          sectionType={"feature-test"}
        >
          <PeopleTest auth={auth} />
        </AdminSectionWrapper>
      </div>
    </div>
  );
};

export default AdminScreenWrapper;
