import React, { useRef } from "react";
import "./SlideModal.scss";

// animation
import { motion } from "framer-motion";

// atoms
import CloseButton from "../components/Atoms/CloseButton";
import AnimatePresenceWrapper from "../UtilityComponents/AnimatePresenceWrapper";

// helpers
import { generateZindex } from "../helpers/slideModalHelper";

const SlideModal = ({
  title,
  open,
  setOpen,
  occupationSteps = [0.3, 0.7, 0.9],
  children,
  focusId,
  parentElForPaddingAdjustment,
}) => {
  // ----- refs --------------
  const slideModalWrapperRef = useRef(null);
  const slideModalContainerRef = useRef(null);
  const slideModalContentRef = useRef(null);

  // ----- States ------------
  const [zIndex, setZIndex] = React.useState(999);
  const [isOpen, setIsOpen] = React.useState(open);
  const [occupationStepIndex, setOccupationStepIndex] = React.useState(0);

  const [parentElInitialPaddingBottom, setParentElInitialPaddingBottom] =
    React.useState(null);

  // ------ functions ----------
  const closeSlideModal = () => {
    updateParentPadding(parentElInitialPaddingBottom);
    setOpen(false);
  };

  const updateParentPadding = (value) => {
    if (!parentElForPaddingAdjustment) return;
    if (parentElInitialPaddingBottom == null) {
      const paddingBottom = Number(
        getComputedStyle(parentElForPaddingAdjustment).paddingBottom.replace(
          "px",
          ""
        )
      );
      setParentElInitialPaddingBottom(paddingBottom);

      // add transition to padding
      // parentElForPaddingAdjustment.style.transition = "padding-bottom .8s cubic-bezier(0.21, 1.29, 0.36, 0.96)";
    }
    parentElForPaddingAdjustment.style.paddingBottom = `${value}px`;
  };

  // ------ Effects --------------
  // handle props change
  React.useEffect(() => {
    setOccupationStepIndex(0);
    setIsOpen(open);

    // calculate z index incase there are already slideModal
    if (open) {
      setZIndex(generateZindex);
    }
  }, [open]);

  // handle focus
  React.useEffect(() => {
    if (!focusId || !slideModalContentRef.current) return;
    const elementToFocus = document.getElementById(focusId);
    if (!elementToFocus) return;

    // get the position of the element to focus on
    const elementToFocusPosition = elementToFocus.offsetTop;

    // scroll the modal content to the element to focus
    slideModalContentRef.current.scrollTo({
      top: elementToFocusPosition - 60,
      behavior: "smooth",
    });
  }, [focusId, isOpen]);

  // handle padding adjustment for the parent element
  React.useEffect(() => {
    if (!open) return;
    if (!parentElForPaddingAdjustment) return;

    // get the height of the slide modal container based on the current occupation step and the modal wrapper ref
    const slideModalContainerHeight =
      window.innerHeight * occupationSteps[occupationStepIndex];

    // add the height of the slide modal container to the parent element's padding bottom
    updateParentPadding(slideModalContainerHeight + 10);
  }, [isOpen, occupationStepIndex]);

  return (
    <AnimatePresenceWrapper visibility={isOpen} exitDelay={0.4}>
      <div
        className="slide-modal-wrapper"
        ref={slideModalWrapperRef}
        style={{
          zIndex: zIndex,
        }}
      >
        <motion.div
          className="slide-modal-container"
          ref={slideModalContainerRef}
          initial={{ height: 0 }}
          animate={{ height: `${occupationSteps[occupationStepIndex] * 100}%` }}
          exit={{ height: 0 }}
          transition={{
            duration: 0.4,
            ease: [0.21, 1.29, 0.36, 0.96],
          }}
        >
          {title && (
            <div className="title-section">
              <span>{title}</span>
            </div>
          )}
          <div className="slide-modal-card">
            <div
              className="slide-modal-header-section cursor-pointer"
              onClick={() => {
                occupationStepIndex == occupationSteps.length - 1
                  ? setOccupationStepIndex(0)
                  : setOccupationStepIndex(occupationStepIndex + 1);
              }}
            >
              <div className="slide-modal-toggle scale-on-hover"></div>
              <CloseButton
                className="slide-modal-close"
                handleClick={() => {
                  closeSlideModal();
                }}
                bgColor="#e2e2e2"
                fontColor="white"
              />
            </div>

            <div className="slide-modal-content" ref={slideModalContentRef}>
              {children}
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresenceWrapper>
  );
};

export default SlideModal;
