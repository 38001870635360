import React from "react";

// router
import { useLocation } from "react-router-dom";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

// enpoints
import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// libraries
import { Link, useNavigate } from "react-router-dom";

// auth
import { Auth } from "aws-amplify";
import {signInUser, resendConfirmation} from "../helpers/authentication.js";

// helpers
import { validateEmailString } from "../helpers/Utility.js";

// components
import AuthenticationWrapper from "../components/AuthenticationWrapper.js";

const Login = ({ auth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [redirectToConfirm, setRedirectToConfirm] = React.useState(false);

  const { authState, setAuthState } = auth;

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [masterValidation, setMasterValidation] = React.useState(false);
  const [masterError, setMasterError] = React.useState({
    isErrored: false,
    errorMessage: "エラーが発生しました。",
  });
  const [data, setData] = React.useState({
    email: "",
    password: "",
  });
  const [validationStatus, setValidationStatus] = React.useState({
    email: {
      validation: false,
      errorMessage: "",
    },
  });

  const login = () => {
    signInUser(auth, data.email, data.password, isConfirming, setIsConfirming, masterError, setMasterError, setRedirectToConfirm);
  };

  const validateMaster = () => {
    if (validationStatus.email.validation && data.password != "") {
      setMasterValidation(true);
    } else {
      setMasterValidation(false);
    }
  };

  const validateEmail = (email) => {
    const validation = validateEmailString(email)

    if (validation) {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        email: {
          validation: validation,
          errorMessage: "",
        },
      }));
    } else {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        email: {
          validation: validation,
          errorMessage: "正しいアドレスを入力してください。",
        },
      }));
    }
  };

  React.useEffect(() => {
    validateEmail(data.email);
  }, [data]);

  React.useEffect(() => {
    validateMaster();
  }, [validationStatus]);

  React.useEffect(() => {
    if (redirectToConfirm) {
      navigate("/confirm", {state: {email: data.email}})
    }
  }, [redirectToConfirm])

  React.useEffect(() => {
    if (auth.authState.cognito_user?.attributes?.email_verified) {
      if (location.state?.pathAfterAuthentication) {
        navigate(location.state.pathAfterAuthentication);
      } else {
        navigate("/")
      }
    }
  }, [auth])

  return (
    <AuthenticationWrapper
      header="ログイン"
      comment="続けるには、ログインが必要です"
      appendix={{
        signupLink: true,
        contactLink: true,
        forgotPasswordLink: true
      }}
    >
      <div className="forms-wrapper">
        <div className="authentication-form-section">
          <span className="form-title">メールアドレス</span>
          <input
            type="text"
            className="form"
            value={data.email}
            placeholder="mail@example.com"
            onChange={(e) => {
              setData((data) => ({
                ...data,
                email: e.target.value,
              }));
            }}
          />
          {!validationStatus.email.validation &&
            validationStatus.email.errorMessage != "" && (
              <div className="validationMessage">
                <FontAwesomeIcon icon={faCircleExclamation} />
                <span>{validationStatus.email.errorMessage}</span>
              </div>
            )}
        </div>
        <div className="authentication-form-section">
          <span className="form-title">パスワード</span>
          <input
            type="password"
            className="form"
            value={data.password}
            placeholder="パスワード"
            onChange={(e) => {
              setData((data) => ({
                ...data,
                password: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <div className="button-section">
        <div
          className={`button ${
            !masterValidation || isConfirming ? "disabled" : ""
          }`}
          onClick={() => {
            if (masterValidation && !isConfirming) {
              login();
            }
          }}
        >
          <span>{!isConfirming ? "ログイン" : "ログインしています..."}</span>
        </div>

        {masterError.isErrored && masterError.errorMessage != "" && (
          <div className="masterErrorMessage">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span>{masterError.errorMessage}</span>
          </div>
        )}
      </div>
    </AuthenticationWrapper>
  );
};

export default Login;
