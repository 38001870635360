import React from "react";
import "./Content.scss";

// fonts
import {
  faTrashCan,
  faCode,
  faLink,
  faCheck,
  faMarker,
  faRss,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// utilities
import { copyToClipboard } from "../helpers/Utility";

// components
import ContentCardMenuBar from "./ContentCardMenuBar";
import ContentHighlightBar from "./ContentHighlightBar";
import ContentInfoWrapper from "./ContentInfoWrapper";
import ContentInfo from "./ContentInfo";
import SingleBlockContentInfo from "./SingleBlockContentInfo";


const Content = ({
  requestContentDelete = () => {
    console.log("content delete function is not provided");
  },
  content,
  setContents = () => {},
  selectable = false,
  permissionToEdit = false,
  menuVisible = true,
  volumeInfo = true,
  deleteButton = true,
  shareButton = true,
  styleClass = "",
  badge = false,
  updatedBadge = false,
  showPublished = false,
}) => {
  // ----- states ---------------
  
  const [elapsedSinceLastUpdate, setElapsedSinceLastUpdate] = React.useState();

  // single block content
  const [singleBlockData, setSingleBlockData] = React.useState();

  // ----- functions ------------
  const toggleSelected = () => {
    content.selected = !content.selected;
    setContents((prev) => [...prev]);
  };

  // ----- effects ---------------

  // check if the content has only one block
  React.useEffect(() => {
    if (content.single_block_validation?.is_single_block) {
      setSingleBlockData(content.single_block_validation.block_data);
    }
  }, [content]);

  // get content elapsed since last update
  React.useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    const lastUpdate = new Date(content.updated_at).setHours(0, 0, 0, 0);

    const elapsedDays = (today - lastUpdate) / 1000 / 60 / 60 / 24;

    if (elapsedDays >= 0) {
      setElapsedSinceLastUpdate(elapsedDays);
    }
  }, [content]);

  return (
    <div
      className={`content-wrapper`}
      onClick={() => {
        if (selectable) {
          toggleSelected();
        }
      }}
      style={
        selectable && !content.selected
          ? {
              opacity: 0.5,
            }
          : {}
      }
    >
      {badge && <div className="badge"></div>}

      {selectable && (
        <div
          className={`select cursor-pointer ${
            content.selected ? "selected" : ""
          }`}
        >
          <FontAwesomeIcon className={`icon`} icon={faCheck} />
        </div>
      )}

      <div
        className={`content-card-wrapper ${styleClass} scale-on-hover-small ${
          content.selected ? "selected" : ""
        }`}
      >
        <ContentHighlightBar
          updatedBadge={updatedBadge}
          elapsedSinceLastUpdate={elapsedSinceLastUpdate}
          maxElapsed={14}
        />

        <ContentInfoWrapper content={content} volumeInfo={volumeInfo} />


        <ContentCardMenuBar
          className="content-container-small"
          content={content}
          requestContentDelete={requestContentDelete}
          menuVisible={menuVisible}
          shareButton={shareButton}
          permissionToEdit={permissionToEdit}
          showPublished={showPublished}
        />
      </div>
    </div>
  );
};

export default Content;
