import React from "react";
import "./FormsWrapper.scss";

const FormsWrapper = ({children}) => {
  return (
    <div className="forms">
      {children}
    </div>
  )
}

export default FormsWrapper;