import React from "react";
import "./MadeWithPulp.scss";

const MadeWithPulp = () => {
  return (
    <a href="https://start.pulp.land" target="_blank" className="made-with-wrapper cursor-pointer">
      <span>made with</span>
      <div className="app-icon-section">
        <img src="/logo/pulp_logo_paper.svg" alt="" className="app-icon" />
        <img src="/logo/pulp.svg" alt="" className="app-logo" />
      </div>
    </a>
  );
};

export default MadeWithPulp;
