import React from "react";
import "./Loading.scss";

// animation
import AnimateUpWrapper from "../components/Animation/AnimateUpWrapper";

const Loading = ({ minHeight = 300 }) => {
  return (
    <AnimateUpWrapper
      className={`loading-component-wrapper`}
    >
      <div className="loading-content" style={{ minHeight: minHeight }}>
        <span className="loader"></span>
      </div>
    </AnimateUpWrapper>
  );
};

export default Loading;
