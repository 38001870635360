import "./Authentication.scss";

// herlpers
import { copyToClipboard } from "../helpers/Utility.js";

// routing
import { Link, useNavigate } from "react-router-dom";

// framer motion
import { motion } from "framer-motion";

// framer motion
const framerFadeUp = {
  hidden: {
    opacity: 0,
    translateY: 10,
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

const AuthenticationWrapper = ({
  header,
  comment = "",
  appendix: {
    loginLink = false,
    signupLink = false,
    contactLink = false,
    forgotPasswordLink = false,
  },
  children,
}) => {
  return (
    <div className="authentication-page-wrapper">
      <div className="authentication-container">
        <div className="service-logo">
          {/* <span className="lekcha">Lekcha</span>
          <span className="blocks">Blocks</span> */}
          <img src="/logo/pulp_logo_paper.svg" alt="" className="icon" />
          <img src="/logo/pulp.svg" alt="" className="logo" />
        </div>

        <motion.div
          className="authentication-card"
          variants={framerFadeUp}
          initial="hidden"
          animate="show"
          transition={framerFadeUp.transition}
          key="authentication-container"
        >
          <div className="card-header">
            <span className="header">{header}</span>
            {comment != "" && <span className="comment">{comment}</span>}
          </div>
          <div className="line"></div>

          {children}

          <div className="appendix-section">
            {loginLink && (
              <div className="appendix">
                <span>
                  すでにアカウントをお持ちの方は
                  <Link to={"/login"}>ログイン</Link>
                </span>
              </div>
            )}

            {signupLink && (
              <div className="appendix">
                <span>
                  まだアカウントをお持ちでない方は
                  <Link to={"/signup"}>新規登録</Link>
                </span>
              </div>
            )}

            {forgotPasswordLink && (
              <div className="appendix">
                <span>
                  パスワードを忘れたら
                  <Link to={"/reset"}>こちら</Link>
                </span>
              </div>
            )}
          </div>

          <div className="line"></div>

          <div className="appendix-section">
            {contactLink && (
              <div className="appendix modest">
                <span>
                  お問い合わせは
                  <br />
                  <div
                    className="link"
                    onClick={() => {
                      copyToClipboard(
                        "support@lekcha.com",
                        true,
                        "アドレスをコピーしました。"
                      );
                    }}
                  >
                    support@lekcha.com
                  </div>
                </span>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AuthenticationWrapper;
