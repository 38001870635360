import React from "react";
import "./ContentInfo.scss";

const ContentInfo = ({ content, volumeInfo }) => {
  // ----- States -------------
  const [thumbnailImageExists, setThumbnailImageExists] = React.useState(
    content.thumbnail_image_url && content.thumbnail_image_url != ""
      ? true
      : false
  );

  // ----- Effects ---------------
  // check if thumbnail image exists
  React.useEffect(() => {
    setThumbnailImageExists(
      content.thumbnail_image_url && content.thumbnail_image_url != ""
        ? true
        : false
    );
  }, [content]);



  // ------ Render ---------------

  return (
    <div className="content-info">
      {thumbnailImageExists && (
        <div className="content-thumbnail-section">
          <img
            src={content.thumbnail_image_url}
            className="content-thumbnail-image"
            alt=""
          />
        </div>
      )}

      <div className="content-info-section content-container">
        <div className="content-title-section">
          <span className="contents-title">{content.title}</span>

          {content.content_description && content.content_description != "" && (
            <p className="contents-description">
              {content.content_description}
            </p>
          )}

          {volumeInfo && (
            <span className="contents-volume">
              {content.slides_count}スライド, {content.blocks_count}
              ブロック
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentInfo;
