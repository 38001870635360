import React from "react";
import "./SlideEditMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faImage,
  faLink,
  faVideo,
  faCode,
  faTrashCan,
  faCopy,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";

import { themeColors } from "../helpers/ContentHelper.js";

const SlideEditMenu = ({
  editMode,
  slidesData,
  deleteSlide,
  currentSlideIndex,
  setCurrentSlideIndex,
  setSlidesData,
}) => {
  const colors = themeColors();

  const setSlideColor = (color) => {
    const updatedSlidesData = slidesData;
    updatedSlidesData.slides[currentSlideIndex].theme_color =
      colors[color].value;
    updatedSlidesData.slides[currentSlideIndex].font_color =
      colors[color].fontColor;

    setSlidesData((slidesData) => ({
      ...slidesData,
      slides: updatedSlidesData.slides,
    }));
  };

  const updateSlideTitle = (title) => {
    const updatedSlidesData = slidesData;
    updatedSlidesData.slides[currentSlideIndex].title = title;

    setSlidesData((slidesData) => ({
      ...slidesData,
      slides: updatedSlidesData.slides,
    }));
  };

  const duplicateSlide = () => {
    alert("Coming soon");
    return;

    if (!window.confirm("スライドを複製しますか？")) {
      return;
    }

    var updatedSlidesData = { ...slidesData };
    updatedSlidesData.slides.splice(currentSlideIndex + 1, 0, {
      ...slidesData.slides[currentSlideIndex],
    });
    updatedSlidesData.slides[currentSlideIndex + 1].id = `slide-${
      currentSlideIndex + 1
    }`;

    setSlidesData((slidesData) => ({
      ...slidesData,
      slides: updatedSlidesData.slides,
    }));
  };

  const toggleSlideLock = (lockStatus) => {
    alert("Coming soon");
    return;

    if (lockStatus) {
      const updatedSlides = slidesData;
      updatedSlides.slides[currentSlideIndex].locked = true;
      setSlidesData((slidesData) => ({
        ...slidesData,
        slides: updatedSlides.slides,
      }));
    } else {
      const updatedSlides = slidesData;
      updatedSlides.slides[currentSlideIndex].locked = false;
      setSlidesData((slidesData) => ({
        ...slidesData,
        slides: updatedSlides.slides,
      }));
    }
  };

  if (editMode) {
    return (
      <div className="slide-edit-menu">
        <div className="slide-title-edit-section">
          <span className="slide-title-edit-section-header">
            スライドのタイトル
          </span>
          <div className="slide-title-input-wrapper">
            <input
              type="text"
              className="slide-title-input"
              value={slidesData.slides[currentSlideIndex].title}
              placeholder="スライドのタイトル ..."
              onChange={(e) => {
                updateSlideTitle(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="slide-edit-buttons-section">
          <span>カラー</span>
          <div className="theme-color-section">
            {Object.keys(colors).map((key, index) => {
              return (
                <div
                  key={key}
                  className="color-circle"
                  style={{
                    background: colors[key].value,
                  }}
                  onClick={() => {
                    setSlideColor(key);
                  }}
                ></div>
              );
            })}
          </div>

          {slidesData.slides[currentSlideIndex].locked ? (
            <FontAwesomeIcon
              className={`slide-lock-icon locked`}
              icon={faLock}
              onClick={() => {
                toggleSlideLock(false);
              }}
            />
          ) : (
            <FontAwesomeIcon
              className={`slide-lock-icon`}
              icon={faLockOpen}
              onClick={() => {
                toggleSlideLock(true);
              }}
            />
          )}

          <FontAwesomeIcon
            className="slide-duplicate-icon"
            icon={faCopy}
            onClick={() => {
              duplicateSlide();
            }}
          />

          <FontAwesomeIcon
            className="slide-delete-icon"
            icon={faTrashCan}
            onClick={() => {
              deleteSlide();
            }}
          />
        </div>
      </div>
    );
  }
};

export default SlideEditMenu;
