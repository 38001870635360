// ----- Clipboards ----------------
export const copyToClipboard = (
  textToCopy,
  displayAlert = true,
  alertText = "コピーしました"
) => {
  navigator.clipboard.writeText(textToCopy).then(() => {
    if (displayAlert) {
      alert(alertText);
    }
  });
};

export const readClipboard = () => {
  // const clipboardItems = await navigator.clipboard.read();
  // for (const clipboardItem of clipboardItems) {
  //   for (const type of clipboardItem.types) {
  //     const blob = await clipboardItem.getType(type);
  //     console.log(blob);
  //   }
  // }

  return navigator.clipboard
    .read()
    .then((items) => {
      console.log(items);
      for (const item of items) {
        // text
        if (item.types.includes("text/plain")) {
          return navigator.clipboard.readText().then((text) => {
            console.log(text);
            return {
              type: "text",
              data: text,
            };
          });
        }

        if (item.types.includes("image/png")) {
          // Blob オブジェクトを取得
          return item.getType("image/png").then((blob) => {
            console.log(blob);
            return {
              type: "image",
              data: blob,
            };
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// ----- Validations ----------------
export const validateEmailString = (email) => {
  const validation = email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return !!validation;
};

export const validateUrlString = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const validateUrlRegex = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

// ------ random rotation -------------
export const randomRotation = (min = 0, max = 1) => {
  const random = Math.random() * (max - min) + min;
  const positive = Math.random() > 0.5 ? true : false; // generates true or false
  const value = random * (positive ? 1 : -1);
  return value;
};

// ----- Framer Motion ----------------
export const framerFadeUp = {
  hidden: {
    opacity: 0,
    translateY: 10,
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

export const framerFadeDown = {
  hidden: {
    opacity: 0,
    translateY: -10,
  },
  show: {
    opacity: 1,
    translateY: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

export const framerSlideIn = {
  hidden: {
    opacity: 0,
    translateX: -10,
  },
  show: {
    opacity: 1,
    translateX: 0,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

export const framerFadeSize = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
    delay: 0,
  },
};

// ------ Datetime -------------
export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}