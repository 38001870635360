import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// mux
import * as UpChunk from "@mux/upchunk";

// video naming
import { v4 as uuidv4 } from "uuid";

const generateFileName = (fileName) => {
  const extention = fileName.slice(fileName.lastIndexOf("."));
  const uuid = uuidv4();

  return uuid + extention;
};

const uploadFileToMux = (inputRef, index, blockData, updateBlock, setUploadStatus, ) => {
  var videoData = {};

  setUploadStatus(prev => ({
    ...prev,
    uploading: true,
    upload_progress: 0,
    message: "アップロード中",
  }))

  axios
    .get(`${endpoints.api}/mux_upload_url`)
    .then((res) => {
      const url = res.data.mux_upload_url.url;

      videoData.originalFilename = inputRef.files[0].name;
      videoData.uploadId = res.data.mux_upload_url.id;

      // inputRef.files[0].name = generateFileName(inputRef.files[0].name);

      const upload = UpChunk.createUpload({
        endpoint: url, // Authenticated url
        file: inputRef.files[0], // File object with your video file’s properties
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // Subscribe to events
      upload.on("error", (error) => {
        console.log(error)
      });

      upload.on("progress", (progress) => {

        setUploadStatus(prev => ({
          ...prev,
          upload_progress: progress.detail
        }))

        // updateBlock({
        //   blockIndex: index,
        //   key: "upload_progress",
        //   value: progress.detail,
        // });
      });

      upload.on("success", () => {
        setUploadStatus(prev => ({
          ...prev,
          uploading: false,
          upload_progress: 0,
          message: "",
        }))

        // updateBlock({
        //   blockIndex: index,
        //   key: "uploading",
        //   value: false,
        // });

        videoData.sourceUrl = upload.endpoint;
        axios
          .get(`${endpoints.api}/get_mux_upload_data/${videoData.uploadId}`)
          .then((mux_upload_data_res) => {
            videoData.mux_asset_id =
              mux_upload_data_res.data.mux_upload_data.data.asset_id;

            axios
              .get(`${endpoints.api}/get_mux_asset/${videoData.mux_asset_id}`)
              .then((playback_id_res) => {
                var newBlockData = blockData.child_data;

                newBlockData.originalFilename = videoData.originalFilename;
                newBlockData.sourceUrl = videoData.sourceUrl;
                newBlockData.mux_asset_id = videoData.mux_asset_id;
                newBlockData.mux_playback_id =
                  playback_id_res.data.asset_data.playback_ids[0].id;

                updateBlock([{
                  blockIndex: index,
                  value: newBlockData,
                }]);
              })
              .catch((playback_id_err) => {
                console.log(playback_id_err);
              });
          })
          .catch((mux_asset_err) => {
            console.log(mux_asset_err);
          });

      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default uploadFileToMux;