import React from "react";
import "./SlideHeader.scss";

// motion
import { motion } from "framer-motion";

// icons
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// app guide
import AppGuide from "../components/AppGuide.js";

// overlays
import EditContentOverlay from "../Overlays/EditContentOverlay.js";

// framer motion
const savingMessage = {
  hidden: {
    opacity: 0,
    translateY: 10,
    height: 0,
  },
  show: {
    opacity: 1,
    translateY: 0,
    height: "auto",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.6,
  },
};

const SlideHeader = ({
  editMode,
  addSlide,
  slidesData,
  currentSlideIndex,
  setCurrentSlideIndex,
  setSlidesData,
  slideComplexity,
  complexityMessage,
  saving,
}) => {
  // ----- States ----------------
  const [contentEditModal, setContentEditModal] = React.useState(false);

  const contentTitleRef = React.useRef(null);
  React.useEffect(() => {
    setTimeout(() => {
      if (contentTitleRef.current) {
        contentTitleRef.current.style.height = "auto"; // reset height to auto to recalculate
        contentTitleRef.current.style.height = `${contentTitleRef.current.scrollHeight}px`; // set new height based on content
      }
    }, 500);
  }, [editMode, contentTitleRef.current]);

  return (
    <div className={`content-header-section ${editMode ? "edit-mode" : ""}`}>
      <div className={`content-header-content ${editMode ? "edit-mode" : ""}`}>
        <div
          className="add-slide-before"
          onClick={() => {
            addSlide("prev", false);
          }}
        >
          <span>+</span>
        </div>
        <div className="title-wrapper">
          {editMode && (
            <div className="content-title-section">
              {false && (
                <input
                  type="text"
                  value={slidesData.title}
                  ref={contentTitleRef}
                  className="content-title"
                  placeholder="コンテンツのタイトルを入力"
                  onChange={(e) => {
                    setSlidesData((slidesData) => ({
                      ...slidesData,
                      title: e.target.value,
                    }));

                    contentTitleRef.current.style.height = "auto"; // reset height to auto to recalculate
                    contentTitleRef.current.style.height = `${contentTitleRef.current.scrollHeight}px`; // set new height based on content
                  }}
                />
              )}

              <span className="content-title">{slidesData.title}</span>
              <div
                className="content-edit-button cursor-pointer scale-on-hover"
                onClick={() => {
                  setContentEditModal(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="content-edit-button-icon"
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="add-slide-after"
          onClick={() => {
            addSlide("next", false);
          }}
        >
          <AppGuide
            condition={
              editMode &&
              slidesData.slides[currentSlideIndex].blocks.length > 0 &&
              slidesData.slides.length <= 3
            }
            message={"スライドを追加"}
            animation={"horizontal"}
            pointer={"👉"}
            styling={{
              top: "50%",
              transform: "translate(calc(-100% - 20px), calc(-50%))",
            }}
          />
          <span>+</span>
        </div>
      </div>

      {false && (
        <div className="slide-complexity">
          <span className="text">ボリューム</span>
          <span
            className={`complexity intensity-${
              complexityMessage(slideComplexity).intensity
            }`}
          >
            {complexityMessage(slideComplexity).message}
          </span>
          <div className="complexity-guage-wrapper">
            {Array(3)
              .fill(null)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`complexity-guage intensity-${
                      complexityMessage(slideComplexity).intensity >= index + 1
                        ? complexityMessage(slideComplexity).intensity
                        : ""
                    }`}
                  ></div>
                );
              })}
          </div>
        </div>
      )}

      {editMode && (
        <motion.div
          className="saving-message"
          variants={savingMessage}
          initial={"hidden"}
          animate={saving ? "show" : "hidden"}
        >
          <span>保存しています ... </span>
        </motion.div>
      )}

      <EditContentOverlay
        contentData={slidesData}
        setContentData={setSlidesData}
        contentEditModal={contentEditModal}
        setContentEditModal={setContentEditModal}
      />
    </div>
  );
};

export default SlideHeader;
