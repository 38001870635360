import React from 'react'
import "./People.scss"

// components
import Person from './Person';


const People = ({people, reloadPeople}) => {

  return (
    <div className="people-wrapper">

      {
        people.length == 0 &&
        <p className="no-one-is-here">
          まだメンバーがいません。<br/>
          フォームブロックでメンバーを集めましょう！
        </p>
      }

      {
        people.map((person, index) => {
          return (
            <Person person={person} index={index} key={person.id} reloadPeople={reloadPeople} />
          )
        })
      }

    </div>
  )
}

export default People;