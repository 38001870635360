import React from "react";
import "./ContentComponents.scss";
import "./ImageContent.scss";

// components
import Uploading from "../components/Uploading.js";

// helper
import { resizeFieldHeights } from "../helpers/ContentHelper.js";
import { uploadImageHelper, getImageUrlFromS3Path } from "../helpers/fileHelpers.js";
import { randomRotation } from "../helpers/Utility";

const ImageContent = ({
  blockData,
  currentSlideIndex,
  slidesData,
  updateSlidesData,
  index,
  editMode,
  updateBlock,
  updateBlockWrapper,
}) => {
  const data = blockData.child_data;
  const placeHolderImage = "/images/placeholder_image.jpg";

  // ----- states ----------------
  const [rotation, setRotation] = React.useState("rotate(0deg)");

  // ----- refs -----------------
  const inputFile = React.useRef(null);
  const captionRef = React.useRef(null);

  // ----- functions ----------------
  const refreshImageUrl = (s3_path) => {
    if (s3_path) {
      return getImageUrlFromS3Path(s3_path);
    } else {
      return placeHolderImage;
    }
  };

  const uploadImage = (imageFile) => {
    if (!imageFile) return;

    const userIdForPath = slidesData.user_id;

    // set the image url to placeholder image
    setImageUrl(placeHolderImage);

    // compression progress function
    const compressionProgressFunction = (progress) => {
      setUploadStatus((prev) => ({
        ...prev,
        uploading: true,
        message: "圧縮中 ...",
        upload_progress: progress,
      }));
    };

    // upload progress function
    const uploadProgressFunction = (progress) => {
      setUploadStatus((prev) => ({
        ...prev,
        message: "アップロード中",
        upload_progress: progress,
      }));
    };

    // completion function
    const completionCallbackFunction = (result) => {
      setImageUrl(refreshImageUrl(result.key));
      updateBlock([
        {
          blockIndex: index,
          value: result.key,
          key: "image_url",
        },
      ]);
      setUploadStatus({
        uploading: false,
        upload_progress: 0,
        message: "",
      });
    };

    // upload the image
    uploadImageHelper(imageFile, userIdForPath, compressionProgressFunction, uploadProgressFunction, completionCallbackFunction)
  };

  // ----- states ---------------

  const [uploadStatus, setUploadStatus] = React.useState({
    uploading: false,
    upload_progress: 0,
    message: "",
  });

  const [imageUrl, setImageUrl] = React.useState(() => {
    return refreshImageUrl(data.image_url);
  });

  // ----- effects ----------------

  React.useEffect(() => {
    setTimeout(() => {
      resizeFieldHeights([captionRef]);
    }, 500);
  }, [editMode, captionRef]);

  // get rotation
  React.useEffect(() => {
    if (blockData.tilted) {
      setRotation(`rotate(${randomRotation(1, 2)}deg)`);
    }
  }, [blockData])

  // ----- render ----------------

  if (!editMode) {
    return (
      <div className="component-wrapper image-block-wrapper">
        <img
          className={`image ${data.cropped ? "cropped" : ""} `}
          style={blockData.tilted ? {
            transform: rotation,
          } : {}}
          alt=""
          src={imageUrl}
          onError={(e) => {
            setImageUrl(placeHolderImage);
          }}
        />
        {data.caption !== "" && <p className="caption">{data.caption}</p>}
      </div>
    );
  } else {
    return (
      <>
        <div className="component-wrapper image-block-wrapper">
          <div className="edit-image-wrapper">
            <img
              className={`image ${data.cropped ? "cropped" : ""} `}
              style={blockData.tilted ? {
                transform: "rotate(1.5deg)",
              } : {}}
              src={imageUrl}
              alt=""
              onClick={() => {
                inputFile.current.click();
              }}
              onError={() => {
                setImageUrl(placeHolderImage);
              }}
            />
            <input
              type="file"
              accept="image/*"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={(e) => uploadImage(e.target.files[0])}
              id="file-picker"
              name="file-picker"
            />

            <div className="edit-buttons">
              <div
                className="tilt-button cursor-pointer"
                onClick={() => {
                  updateBlockWrapper({
                    blockIndex: index,
                    value: !blockData.tilted,
                    key: "tilted",
                  });
                }}
              ></div>
              <div
                className={`aspect-button ${
                  !data.cropped ? "vertical" : ""
                } cursor-pointer`}
                onClick={() => {
                  updateBlock([
                    {
                      blockIndex: index,
                      value: !data.cropped,
                      key: "cropped",
                    },
                  ]);
                }}
              ></div>
            </div>
          </div>

          {uploadStatus.uploading && (
            <Uploading
              message={uploadStatus.message}
              percentage={uploadStatus.upload_progress}
            />
          )}

          <textarea
            ref={captionRef}
            className="caption"
            placeholder="キャプションを入力"
            value={data.caption}
            onChange={(e) => {
              updateBlock([
                {
                  blockIndex: index,
                  value: e.target.value.replace(/(\r\n|\n|\r)/gm, ""),
                  key: "caption",
                },
              ]);
              resizeFieldHeights([captionRef]);
            }}
          >
            {data.caption}
          </textarea>
        </div>
      </>
    );
  }
};

export default ImageContent;
