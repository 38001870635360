import React from "react";
import "./ContentList.scss";

import { useNavigate } from "react-router-dom";

// loading
import Loading from "../UtilityComponents/Loading.js";

// components
import SearchBox from "../components/BasicComponents/SearchBox";
import Content from "./Content.js";

// helpers
import {
  devideContentsIntoColumns,
  returnSelected,
} from "../helpers/ContentListHelper.js";
import { randomRotation } from "../helpers/Utility.js";

// app guide
import AppGuide from "../components/AppGuide.js";

// framer
import { framerFadeUp } from "../helpers/Utility";
import { motion } from "framer-motion";

const ContentList = ({
  contents,
  setContents,
  loading = false,
  searchable = true,
  searchPlaceHolder = "コンテンツを検索",
  selectable = false,
  jumpable = true,
  permissionToEdit = false,
  menuVisible = true,
  volumeInfo = true,
  requestContentDelete,
  rotateEnabled = false,
  showPublished = false,
  updatedBadge = false,
  styleClass,
  noContentMessage = "まだコンテンツがありません。",
}) => {
  const navigate = useNavigate();

  // ----- states  ----------------
  const [columnOne, setColumnOne] = React.useState([]);
  const [columnTwo, setColumnTwo] = React.useState([]);

  // search
  const [searchQuery, setSearchQuery] = React.useState("");

  // ----- rotation and jiggle factors ---------
  const rotationFactor = 1.5;
  const jiggleFactor = 4;

  // ----- functions ---------------

  const generateColumns = (contents) => {
    const { columnOneData, columnTwoData } =
      devideContentsIntoColumns(contents);
    setColumnOne(columnOneData);
    setColumnTwo(columnTwoData);
  };

  const filterBySearchQuery = () => {
    var search = searchQuery.toLocaleLowerCase();
    var condition = new RegExp(search);
    var result = contents.filter(function (el) {
      return condition.test(el.title.toLocaleLowerCase());
    });

    generateColumns(result);
  };

  // ----- effects ---------------
  React.useEffect(() => {
    // ----- set columns ------------
    if (searchQuery != "") {
      filterBySearchQuery();
    } else {
      generateColumns(contents);
    }
  }, [contents]);

  if (loading) {
    return <Loading minHeight={0} />;
  } else {
    return (
      <div className={`contents-list-wrapper`}>
        {contents.length == 0 && (
          <p className="no-contents">{noContentMessage}</p>
        )}

        {searchable && contents.length > 0 && (
          <div className="search-box">
            <SearchBox
              placeholder={searchPlaceHolder}
              value={searchQuery}
              setValue={setSearchQuery}
              searchButtonVisible={false}
              searchButtonFunction={() => {
                filterBySearchQuery();
              }}
              autoSearch={true}
              queryResetFunction={() => {
                generateColumns(contents);
              }}
            />
          </div>
        )}

        {selectable && (
          <div className="selected-message">
            <span>{returnSelected(contents).length}件選択されています</span>
          </div>
        )}

        {(columnOne.length > 0 || columnTwo.length > 0) && (
          <div className="columns-wrapper">
            <div className={`contents`}>
              {columnOne.length > 0 &&
                columnOne.map((content, index) => {
                  return (
                    <motion.div
                      className="content-wrapper-for-app-guide"
                      variants={framerFadeUp}
                      initial="hidden"
                      animate="show"
                      transition={framerFadeUp.transition}
                      onClick={() => {
                        if (jumpable == false) return;
                        navigate(`/content/${content.id}`);
                      }}
                      key={content.id}
                    >
                      <AppGuide
                        condition={false && contents.length == 1}
                        message={"Open me!"}
                        animation={"vertical"}
                        font={"handwritten"}
                        reverse={true}
                        pointer={"👆"}
                        styling={{
                          flexDirection: "column",
                          left: "50%",
                          bottom: 0,
                          transform: "translate(calc(-50%), calc(100% + 20px))",
                        }}
                      />
                      <div
                        className="content-list-content-wrapper"
                        style={
                          rotateEnabled
                            ? {
                                transform: `rotate(${
                                  randomRotation() * rotationFactor
                                }deg) translate(${
                                  randomRotation() * jiggleFactor
                                }px, ${randomRotation() * jiggleFactor}px)`,
                              }
                            : {}
                        }
                      >
                        <Content
                          requestContentDelete={requestContentDelete}
                          content={content}
                          setContents={setContents}
                          selectable={selectable}
                          permissionToEdit={permissionToEdit}
                          menuVisible={menuVisible}
                          styleClass={styleClass}
                          volumeInfo={volumeInfo}
                          showPublished={showPublished}
                          updatedBadge={updatedBadge}
                        />
                      </div>
                    </motion.div>
                  );
                })}
            </div>
            <div className={`contents`}>
              {columnTwo.length > 0 &&
                columnTwo.map((content, index) => {
                  return (
                    <motion.div
                      className="content-wrapper-for-app-guide"
                      variants={framerFadeUp}
                      initial="hidden"
                      animate="show"
                      transition={framerFadeUp.transition}
                      onClick={() => {
                        if (jumpable == false) return;
                        navigate(`/content/${content.id}`);
                      }}
                      key={content.id}
                    >
                      <AppGuide
                        condition={false && contents.length == 1}
                        message={"Open me!"}
                        animation={"vertical"}
                        font={"handwritten"}
                        reverse={true}
                        pointer={"👆"}
                        styling={{
                          flexDirection: "column",
                          left: "50%",
                          bottom: 0,
                          transform: "translate(calc(-50%), calc(100% + 20px))",
                        }}
                      />
                      <div
                        className="content-list-content-wrapper"
                        style={
                          rotateEnabled
                            ? {
                                transform: `rotate(${
                                  randomRotation() * rotationFactor
                                }deg) translate(${
                                  randomRotation() * jiggleFactor
                                }px, ${randomRotation() * jiggleFactor}px)`,
                              }
                            : {}
                        }
                      >
                        <Content
                          requestContentDelete={requestContentDelete}
                          content={content}
                          setContents={setContents}
                          selectable={selectable}
                          permissionToEdit={permissionToEdit}
                          menuVisible={menuVisible}
                          styleClass={styleClass}
                          volumeInfo={volumeInfo}
                          showPublished={showPublished}
                          updatedBadge={updatedBadge}
                        />
                      </div>
                    </motion.div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default ContentList;
