import React from "react";
import "./NotificationTest.scss";

// helpers
import {
  getVapid,
  mainPushSubscribe,
  sendTestPush,
  getPushSubscription,
  sendMainPushSubscriptionToServer,
  sendMainPush,
  checkBackendSubscription,
  sendMainPushAllAdmin,
} from "../../helpers/PushNotificationsHelper.js";
import { getServiceWorkerRegistration } from "../../helpers/swHelper";

// admin components
import {Section, Input, Button, Status} from "./AdminComponents/AdminComponents";



// ------ Main Export -----------------
const NotificationTest = ({ auth }) => {
  // ----- States ----------------
  const [notificationPermission, setNotificationPermission] = React.useState();

  const [serviceWorker, setServiceWorker] = React.useState();

  const [notificationSubscription, setNotificationSubscription] =
    React.useState();

  const [backendSubscriptions, setBackendSubscriptions] = React.useState({
    current_subscription: undefined,
    other_subscriptions: [],
  });

  const [testNotificationData, setTestNotificationData] = React.useState({
    title: "",
    body: "",
    url: "",
  });

  const [sending, setSending] = React.useState(false);

  // ----- main functions -------------
  const checkStatus = () => {
    checkNotificationPermission();
    checkServiceWorker();
    checkPushSubscription();
  };

  // ----- button functions -------------

  const subscribeToPush = () => {
    mainPushSubscribe(auth.authState.cognito_user?.username)
      .then((subscription) => {
        console.log("subscription sent to backend: ", subscription);
        setNotificationSubscription(subscription);
        checkStatus();
      })
      .catch((err) => {
        checkStatus();
      });
  };

  const sendPush = ({
    title = "テスト通知",
    body = "これはテスト通知です。",
    url = "https://www.google.com/",
  }) => {
    setSending(true);
    sendMainPush(title, body, url, auth.authState.cognito_user?.username).then(
      (res) => {
        alert("送信しました。");
        setSending(false);
      }
    );
  };

  const sendPushAllAdmin = ({ title, body, url }) => {
    setSending(true);
    sendMainPushAllAdmin(
      title,
      body,
      url,
      auth.authState.cognito_user?.username
    ).then((res) => {
      alert("送信しました。");
      setSending(false);
    });
  };

  // ----- effect functions --------------

  const checkNotificationPermission = () => {
    setNotificationPermission(() => {
      if (!("Notification" in window)) {
        return "unavailable";
      } else {
        return Notification.permission;
      }
    });
  };

  const checkServiceWorker = () => {
    getServiceWorkerRegistration().then((registration) => {
      setServiceWorker(registration);
    });
  };

  const checkPushSubscription = () => {
    getPushSubscription().then((subscription) => {
      setNotificationSubscription(subscription);
    });
  };

  const checkBackendEndpoint = () => {
    if (notificationSubscription?.endpoint) {
      checkBackendSubscription(notificationSubscription.endpoint).then(
        (res) => {
          setBackendSubscriptions((prevState) => ({
            ...prevState,
            current_subscription: res.current_subscription,
            other_subscriptions: res.other_subscriptions,
          }));
        }
      );
    }
  };

  // ------ effects ------------
  // detect service worker registration
  React.useEffect(() => {
    checkStatus();
  }, []);

  // get push subscription from backend
  React.useEffect(() => {
    checkBackendEndpoint();
  }, [notificationSubscription]);

  return (
    <div className="notification-test-wrapper">
      <Section
        title="ステータス"
        description="さまざまなデバイスで試してみて、赤がある場合は教えてください。"
        sectionClass="status-section"
      >
        <Status
          title="通知許可"
          value={notificationPermission}
          color={(() => {
            switch (notificationPermission) {
              case "unavailable":
                return "gray";
              case "granted":
                return "green";
              case "denied":
                return "red";
              case "default":
                return "gray";
              default:
                return "gray";
            }
          })()}
        />
        <br />
        <br />
        <Status
          title="Service Worker"
          value={(() => {
            if (serviceWorker?.installing) {
              return "インストール中 ...";
            }
            if (serviceWorker?.waiting) {
              return "待機状態";
            }
            if (serviceWorker?.active?.state == "activated") {
              return "登録済み";
            } else {
              return "未登録";
            }
          })()}
          color={(() => {
            if (serviceWorker?.installing) {
              return "gray";
            }
            if (serviceWorker?.waiting) {
              return "red";
            }
            if (serviceWorker?.active?.state == "activated") {
              return "green";
            } else {
              return "gray";
            }
          })()}
        />
        <Status
          title="Push Subscription"
          value={(() => {
            if (notificationSubscription) {
              return "登録済み";
            } else {
              return "未登録";
            }
          })()}
          color={(() => {
            if (notificationSubscription) {
              return "green";
            } else {
              return "gray";
            }
          })()}
        />
        <Status
          title="Endpoint"
          value={(() => {
            if (notificationSubscription) {
              return notificationSubscription.endpoint;
            } else {
              return "未登録";
            }
          })()}
          color={(() => {
            if (notificationSubscription) {
              return "normal";
            } else {
              return "gray";
            }
          })()}
        />

        <Status
          title="Backend保存"
          value={(() => {
            if (!notificationSubscription) {
              return "未登録";
            }
            if (backendSubscriptions.current_subscription) {
              return `OK | ID-${backendSubscriptions.current_subscription.id}`;
            } else {
              return "NG";
            }
          })()}
          color={(() => {
            if (!notificationSubscription) {
              return "gray";
            }
            if (backendSubscriptions.current_subscription) {
              return "green";
            } else {
              return "red";
            }
          })()}
        />

        {backendSubscriptions.current_subscription && (
          <Status
            title="全てのEndpoint"
            value={`他 ${backendSubscriptions.other_subscriptions.length} 件の通知先Endpointがアカウントに紐づけられています。`}
            color="normal"
          >
            <div className="other-subscriptions underline">
              {backendSubscriptions.other_subscriptions.map(
                (otherSubscription, index) => {
                  return (
                    <div key={index} className="other-subscription">
                      <span>ID-{otherSubscription.id}</span>
                    </div>
                  );
                }
              )}
            </div>
          </Status>
        )}
      </Section>

      <Section
        title="登録操作"
        description="各種テストの操作画面です。"
        sectionClass="buttons-section"
      >
        {notificationPermission != "unavailable" &&
          serviceWorker?.active?.state == "activated" &&
          !notificationSubscription &&
          notificationPermission != "denied" && (
            <Button text="通知をオンにする" onClick={subscribeToPush} />
          )}

        {serviceWorker &&
          notificationSubscription &&
          notificationPermission && (
            <Button text="テスト通知" onClick={sendPush} disabled={sending} />
          )}

        <Button text="ステータスを更新" onClick={checkStatus} />
      </Section>

      {serviceWorker && notificationSubscription && notificationPermission && (
        <Section
          title="通知送信"
          description="全てのAdminに通知を送信します。"
          sectionClass="send-section"
        >
          <div className="send-section-inputs">
            <Input
              header="title | 通知見出し"
              value={testNotificationData.title}
              onChange={(e) => {
                setTestNotificationData((prevState) => ({
                  ...prevState,
                  title: e.target.value,
                }));
              }}
            />
            <Input
              header="body | 通知本文"
              value={testNotificationData.body}
              onChange={(e) => {
                setTestNotificationData((prevState) => ({
                  ...prevState,
                  body: e.target.value,
                }));
              }}
            />
            <Input
              header="url | 通知をタップ後の遷移先 (フルリンクでも、相対リンクでもOK e.g. /content/6)"
              value={testNotificationData.url}
              onChange={(e) => {
                setTestNotificationData((prevState) => ({
                  ...prevState,
                  url: e.target.value,
                }));
              }}
            />
          </div>

          <div className="send-buttons">
            <Button
              text="自分のみに通知を送信"
              disabled={sending}
              onClick={() => {
                sendPush(testNotificationData);
              }}
            />

            <Button
              theme="dark"
              text="全Adminに通知を送信"
              disabled={sending}
              onClick={() => {
                sendPushAllAdmin(testNotificationData);
              }}
            />
          </div>
        </Section>
      )}
    </div>
  );
};

export default NotificationTest;
