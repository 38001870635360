import React from "react";
import "./ContentWelcome.scss";

// router
import { useNavigate } from "react-router-dom";

// components
import MadeWithPulp from "../UtilityComponents/MadeWithPulp";

// framer motion
import { motion } from "framer-motion";
import { framerFadeUp } from "../helpers/Utility.js";

// icons
import { faArrowUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContentWelcome = ({
  contentData,
  setWelcomeScreen,
  slideColors,
  setCurrentSlideIndex,
}) => {
  const navigate = useNavigate();

  // ----- states ---------------
  const [thumbnailImageExists, setThumbnailImageExists] = React.useState(
    contentData.thumbnail_image_url && contentData.thumbnail_image_url != ""
      ? true
      : false
  );

  // ----- effects ---------------
  React.useEffect(() => {
    setThumbnailImageExists(
      contentData.thumbnail_image_url && contentData.thumbnail_image_url != ""
        ? true
        : false
    );
  }, [contentData]);

  return (
    <div className="content-welcome-wrapper">
      <div className="content-welcome-content-wrapper">
        <motion.div
          className="content-welcome-container"
          variants={framerFadeUp}
          initial="hidden"
          animate="show"
          transition={{
            ...framerFadeUp.transition,
            delay: 0.5,
          }}
        >
          <div className="welcome-card-content">
            {thumbnailImageExists && (
              <div className="content-thumbnail-section">
                <img
                  src={contentData.thumbnail_image_url}
                  className="content-thumbnail-image scale-on-hover"
                  alt=""
                  onError={(e) => {
                    setThumbnailImageExists(false);
                  }}
                />
              </div>
            )}

            <div className="content-info-section">
              <div className="content-title-section">
                <span className="content-title">{contentData.title}</span>
                {contentData.content_description != "" && (
                  <p className="content-description">
                    {contentData.content_description}
                  </p>
                )}
              </div>

              <div className="table-of-contents">
                <div className="table-of-contents-header-section">
                  <span className="table-of-contents-header">■ 目次</span>
                  <span className="content-volume">
                    {contentData.slides.length} ページ
                  </span>
                  {/* <FontAwesomeIcon className="icon cursor-pointer" icon={faChevronDown} /> */}
                </div>

                <div className="table-of-contents-items">
                  {contentData.slides.map((slide, index) => {
                    return (
                      <div
                        key={slide.id}
                        className={`table-of-contents-item cursor-pointer scale-on-hover-small ${
                          slide.depth_level == 0 ? "top-level" : ""
                        }`}
                        onClick={() => {
                          setCurrentSlideIndex(index);
                          setWelcomeScreen(false);
                        }}
                      >
                        {slide.depth_level > 0 && (
                          <div className="dots">
                            {Array(slide.depth_level)
                              .fill()
                              .map((_, i) => {
                                return <div className="dot" key={i}></div>;
                              })}
                          </div>
                        )}

                        <span className="slide-index">
                          {index + 1 < 10 ? `0${index + 1}` : index + 1}
                        </span>

                        <span className="page-title">
                          {slide.title != "" ? slide.title : "-"}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div
            className="start-watching-wrapper"
            onClick={() => {
              setWelcomeScreen(false);
            }}
            style={
              slideColors && {
                background: contentData.slides[0].theme_color,
                color: slideColors.legibleColor,
              }
            }
          >
            <span className="text">ひらく</span>
            <FontAwesomeIcon className="arrow" icon={faArrowUp} />
          </div>
        </motion.div>
      </div>

      {/* <div className="made-with-section">
        <span>made with</span>
        <div className="app-icon-section">
          <img src="/logo/pulp_logo_paper.svg" alt="" className="app-icon" />
          <img src="/logo/pulp.svg" alt="" className="app-logo" />
        </div>
      </div> */}

      <div className="made-with-section">
        <MadeWithPulp />
      </div>
    </div>
  );
};

export default ContentWelcome;
