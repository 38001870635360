import React from "react";
import "./AppGuide.scss";

// framer
import { framerFadeSize } from "../helpers/Utility";
import { motion } from "framer-motion";

// icons
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AppGuide = ({
  condition,
  message,
  pointer = "",
  animation = "vertical",
  font = "default",
  styling,
  reverse = false,
}) => {
  const [closed, setClosed] = React.useState(false);

  if (condition && !closed) {
    return (
      <motion.div
        className="app-guide"
        style={styling}
        variants={framerFadeSize}
        initial="hidden"
        animate="show"
        transition={{
          ...framerFadeSize.transition,
          delay: 0.5,
          duration: 1,
        }}
      >
        {!reverse ? (
          <>
            <span className={`message ${font}`}>{message}</span>
            <span className={`pointer ${animation}`}>{pointer}</span>
          </>
        ) : (
          <>
            <span className={`pointer ${animation}`}>{pointer}</span>
            <span className={`message ${font}`}>{message}</span>
          </>
        )}

        <div
          className="close-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setClosed(true);
          }}
        >
          <FontAwesomeIcon icon={faXmark} className="close-icon" />
        </div>
      </motion.div>
    );
  }
};

export default AppGuide;
