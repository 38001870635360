import React from "react";
import "./CreatorScreenWrapper.scss";

// bgCurve
import BgCurve from "./bgCurve.js";

// app components
import AppHeader from "./AppHeader.js";
import AppFooter from "./AppFooter.js";

// screen data
import { Screens } from "../../helpers/CreatorScreens";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Overlays
import CreateContentModal from "../../Overlays/CreateContentModal";

const CreatorScreenWrapper = ({
  auth,
  selectedScreen,
  screenTitleSection = false,
  children,
}) => {
  // ----- Create Content Modal ---------------
  const [createContentModal, setCreateContentModal] = React.useState(false);

  return (
    <div className="creator-screen-background">
      {/* for service worker update (will be injected by service worker regstration when update is detected) */}
      <div className="creator-screen-sw-update-notice"></div>
      
      {/* background wave */}
      <BgCurve />
      
      <div className="creator-screen-wrapper">

        {/* App Header */}
        <AppHeader auth={auth} display={true} backgrcoundColor={"white"} />

        {/* Creator Screen Contents */}
        <div className="creator-screen-content-wrapper">
          <div className="creator-screen-container">
            {/* Screen title and description section */}
            {screenTitleSection && (
              <div className="creator-screen-title-section">
                <FontAwesomeIcon
                  className="creator-screen-icon"
                  icon={Screens[selectedScreen].icon}
                />
                <div className="creator-screen-title-texts">
                  <span className="creator-screen-title">
                    {Screens[selectedScreen].display_name}
                  </span>
                  <p className="creator-screen-description">
                    {Screens[selectedScreen].description}
                  </p>
                </div>
              </div>
            )}

            <div className="creator-screen-contents">{children}</div>
          </div>
        </div>

        {/* App Footer goes here */}
        <AppFooter
          auth={auth}
          setCreateContentModal={setCreateContentModal}
          createContentAppGuide={false && selectedScreen == "home"}
          selected={selectedScreen}
        />

        {/* Overlays */}
        <CreateContentModal
          auth={auth}
          createContentModal={createContentModal}
          setCreateContentModal={setCreateContentModal}
        />
      </div>
    </div>
  );
};

export default CreatorScreenWrapper;
