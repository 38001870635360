import React from "react";
import "./ProgressGuage.scss";

const ProgressGuage = ({
  size = 30,
  strokeWidth = 4,
  progress = 0,
  color = "orange",
}) => {
  // ----- Constants ------------
  const radius = size / 2;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  // ----- Functions -------------
  const calculateStrokeDashoffset = (progress) => {
    return circumference - progress * circumference;
  };

  // ----- States ----------------

  const [strokeDashoffset, setStrockDashoffset] = React.useState(
    calculateStrokeDashoffset(progress)
  );

  // ------ Effects ---------------
  // Update values when progress change
  React.useEffect(() => {
    const updatedStrokeDashoffset = calculateStrokeDashoffset(progress);
    setStrockDashoffset(updatedStrokeDashoffset);
  }, [progress]);

  return (
    <svg height={size} width={size} className="progress-guage">
      <circle
        className="progress-guage-circle"
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

export default ProgressGuage;
