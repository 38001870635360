import { createBrowserRouter, Navigate } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

// pages
import Signup from "../screens/Signup.js";
import Confirm from "../screens/Confirm.js";
import Login from "../screens/Login.js";
import ResetPassword from "../screens/ResetPassword.js";

// In app pages
import TestHome from "../screens/TestHome.js";
import ContentView from "../components/ContentView.js";
import HomeView from "../screens/CreatorScreens/Home.js";
import BasketsView from "../screens/CreatorScreens/Baskets.js";
import CommunityView from "../screens/CreatorScreens/Community.js";
import ProfileView from "../screens/CreatorScreens/Profile.js";

// public pages
import PublicPageWrapper from "../screens/PublicPages/PublicPageWrapper.js";
import ProfilePage from "../screens/PublicPages/ProfilePage.js";
import SpacePage from "../screens/PublicPages/SpacePage.js";

// screen wrappers
import CreatorScreenWrapper from "../screens/CreatorScreens/CreatorScreenWrapper.js";
import AdminScreenWrapper from "../screens/Admin/AdminScreenWrapper.js";

export const routes = (authProps) => {
  const authState = authProps.authState;

  // protected route (wraps routes that require user authentication)
  const ProtectedRoute = ({ authenticationPath = "/signup", children }) => {
    if (!authState.cognito_user) {
      return (
        <Navigate
          to={authenticationPath}
          state={{
            pathAfterAuthentication: window.location.pathname,
          }}
          replace
        />
      );
    }

    return children;
  };

  const AdminProtectedRoute = ({ authenticationPath = "/login", children }) => {
    if (!authState.cognito_user || !authState.isAdmin) {
      return (
        <Navigate
          to={authenticationPath}
          replace
        />
      );
    }

    return children;
  };

  return createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute authenticationPath="/login">
          <CreatorScreenWrapper auth={authProps} selectedScreen={"home"}>
            <HomeView auth={authProps} />
          </CreatorScreenWrapper>

          <ScrollRestoration />
        </ProtectedRoute>
      ),
    },

    // ------ Admins --------------
    {
      path: "/admin",
      element: (
        <AdminProtectedRoute authenticationPath="/login">
          <AdminScreenWrapper auth={authProps} />
          <ScrollRestoration />
        </AdminProtectedRoute>
      ),
    },

    // ------ Contents -------------

    {
      path: "/content/:contentId",
      element: <ContentView auth={authProps} />,
    },

    // ------ Spaces ---------------
    {
      path: "/spaces",
      element: (
        <ProtectedRoute authenticationPath="/login">
          <CreatorScreenWrapper
            auth={authProps}
            selectedScreen={"spaces"}
            screenTitleSection={true}
          >
            <BasketsView auth={authProps} />
          </CreatorScreenWrapper>

          <ScrollRestoration />
        </ProtectedRoute>
      ),
    },

    {
      path: "/space/:id",
      element: (
        <PublicPageWrapper auth={authProps}>
          <SpacePage auth={authProps} />
        </PublicPageWrapper>
      ),
    },

    {
      path: "/space/:id",
      element: (
        <PublicPageWrapper auth={authProps}>
          <SpacePage auth={authProps} />
        </PublicPageWrapper>
      ),
    },

    // ------ Community -------------
    {
      path: "/community",
      element: (
        <ProtectedRoute authenticationPath="/login">
          <CreatorScreenWrapper
            auth={authProps}
            selectedScreen={"community"}
            screenTitleSection={true}
          >
            <CommunityView auth={authProps} />
          </CreatorScreenWrapper>

          <ScrollRestoration />
        </ProtectedRoute>
      ),
    },

    // ------ Profiles --------------
    {
      path: "/profile",
      element: (
        <ProtectedRoute authenticationPath="/login">
          <CreatorScreenWrapper
            auth={authProps}
            selectedScreen={"profile"}
            screenTitleSection={true}
          >
            <ProfileView auth={authProps} />
          </CreatorScreenWrapper>

          <ScrollRestoration />
        </ProtectedRoute>
      ),
    },

    {
      path: "/profile/:handle_name",
      element: (
        <PublicPageWrapper auth={authProps}>
          <ProfilePage auth={authProps} />
        </PublicPageWrapper>
      ),
    },

    // ------ Authentication --------------
    {
      path: "/signup",
      element: <Signup auth={authProps} />,
    },
    {
      path: "/confirm",
      element: <Confirm auth={authProps} />,
    },
    {
      path: "/reset",
      element: <ResetPassword auth={authProps} />,
    },
    {
      path: "/login",
      element: <Login auth={authProps} />,
    },

    // ------ Deprecated ---------------

    // {
    //   path: "/samples",
    //   element: <TestHome auth={authProps} />,
    // },
    
  ]);
};
