import React, { useRef } from "react";
import "./ContentFooter.scss";

// components
import ContentFooterButtons from "./ContentFooterButtons.js";

// icons
import { faAngleUp, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// utilities
import { addAlphaToColor } from "../helpers/Colors.js";

// framer motion
import { motion } from "framer-motion";

// helpers
import { getParentSlide, isParentSlide } from "../helpers/ContentHelper.js";

// guide
import AppGuide from "../components/AppGuide.js";

const ContentFooter = ({
  visibility,
  creatorMode,
  currentSlideIndex,
  setCurrentSlideIndex,
  contentData,
  slideData,
  slideColors,
  editMode,
  setEditMode,
  setQrOpen,
  setChapterModal,
}) => {
  const slideLength = contentData.slides.length;
  const isFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === slideLength - 1;

  // ----- Refs ---------------
  const animateRef = useRef(null);

  // ----- states --------------
  const [footerVisible, setFooterVisible] = React.useState(visibility);

  // ----- functions -----------

  const nextSlide = () => {
    if (isLastSlide) return;
    setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const prevSlide = () => {
    if (isFirstSlide) return;
    setCurrentSlideIndex(currentSlideIndex - 1);
  };

  // ----- slide colors ----------------

  const footerColors = slideData &&
    slideColors && {
      color: (() => {
        switch (slideData.full_contents) {
          case true:
            return "white";
          case false:
            return slideColors.legibleColor;
        }
      })(),
    };

  // ----- Effects -------------

  React.useEffect(() => {
    if (visibility == true) {
      let timeoutId = setTimeout(() => {
        setFooterVisible(visibility);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setFooterVisible(visibility);
    }
  }, [visibility]);

  return (
    <motion.div
      ref={animateRef}
      variants={{
        hidden: {
          // bottom: -animateRef?.current?.offsetHeight,
          opacity: 0,
        },
        visible: {
          // bottom: 0,
          opacity: 1,
        },
      }}
      animate={footerVisible ? "visible" : "hidden"}
      transition={{ ease: "easeInOut", duration: 0.3, delay: 0 }}
      className={`content-footer-wrapper ${editMode ? "edit-mode" : ""} ${
        slideData.full_contents ? "full-content" : ""
      } footer-screen-adapt`}
      style={
        !editMode &&
        !slideData.full_contents &&
        addAlphaToColor(slideData.theme_color, 0.7)
          ? {
              // background: addAlphaToColor(slideData.theme_color, 0.7),
              // boxShadow: `0px -10px 10px ${addAlphaToColor(
              //   slideData.theme_color,
              //   0.7
              // )}`,
            }
          : {}
      }
    >
      <div
        className="content-footer-container"
        style={
          slideColors && {
            color: footerColors.color,
          }
        }
      >
        <AppGuide
          condition={creatorMode && !editMode && slideData.blocks.length == 0}
          message={"💡 編集してみましょう！"}
          pointer={"👇"}
          animation={"vertical"}
          styling={{
            flexDirection: "column",
            transform: "translate(calc(0px), calc(-100% - 10px))",
          }}
        />

        {false && !editMode && (
          <div className="footer-slide-title-section">
            {slideData.depth_level > 0 &&
              getParentSlide(contentData.slides, slideData.id) && (
                <span className="chapter-title">
                  {getParentSlide(contentData.slides, slideData.id).title}
                </span>
              )}
            <span className="footer-slide-title">{slideData.title}</span>
          </div>
        )}

        {contentData.slides.length > 1 && (
          <div
            className={`progress-bar-section ${editMode ? "edit-mode" : ""}`}
          >
            {editMode || contentData.slides.length >= 10 ? (
              <div
                className="progress-bar-wrapper"
                style={{
                  backgroundColor: (() => {
                    if (slideColors) {
                      if (slideColors.isDarker) {
                        return "rgba(255,255,255,.3)";
                      } else {
                        return "rgba(0,0,0,.3)";
                      }
                    }
                  })(),
                }}
              >
                <div
                  className="progress-bar"
                  style={
                    editMode || slideData.full_contents
                      ? {
                          width: `${
                            ((currentSlideIndex + 1) / slideLength) * 100
                          }%`,
                        }
                      : {
                          width: `${
                            ((currentSlideIndex + 1) / slideLength) * 100
                          }%`,
                          backgroundColor: (() => {
                            if (slideColors) {
                              return slideColors.legibleColor;
                            } else {
                              return slideData.font_color;
                            }
                          })(),
                        }
                  }
                ></div>
              </div>
            ) : (
              <div
                className="progress-bar-wrapper-new"
                style={{
                  backgroundColor: (() => {
                    if (slideColors) {
                      if (slideColors.isDarker) {
                        return "rgba(255,255,255,.3)";
                      } else {
                        return "rgba(0,0,0,.3)";
                      }
                    }
                  })(),
                }}
              >
                <div
                  className="progress-bars"
                  style={{
                    width: `${((currentSlideIndex + 1) / slideLength) * 100}%`,
                    backgroundColor: slideColors
                      ? slideColors.legibleColor
                      : slideData.font_color,
                  }}
                ></div>
                <div className="progress-bars-overlay">
                  {contentData.slides.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className="progress-bar"
                        onClick={() => setCurrentSlideIndex(index)}
                      >
                        <div
                          className={`progress-bar-index ${
                            index == 0 ||
                            isParentSlide(contentData.slides, slide.id)
                              ? "parent-slide"
                              : "child-slide"
                          }`}
                          style={{
                            backgroundColor: slideColors
                              ? slideColors.legibleColor
                              : slideData.font_color,
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="page-index-section">
              <span>
                {currentSlideIndex + 1} / {contentData.slides.length}
              </span>
            </div>
          </div>
        )}

        {(creatorMode || contentData.slides.length > 1) && (
          <div
            className={`content-footer ${
              editMode || slideData.full_contents ? "white-back" : ""
            }`}
            style={
              !editMode
                ? {
                    color: (() => {
                      if (slideColors) {
                        return slideColors.legibleColor;
                      } else {
                        return slideData.font_color;
                      }
                    })(),
                    backgroundColor: (() => {
                      if (slideColors) {
                        return slideColors.lighterShift;
                      } else {
                        return "rgba(0, 0, 0, .1)";
                      }
                    })(),
                  }
                : {}
            }
          >
            <div
              className={`page-icon-wrapper ${isFirstSlide ? "disabled" : ""}`}
              onClick={() => {
                prevSlide();
              }}
            >
              <FontAwesomeIcon
                icon={faAngleUp}
                className="page-icon prev-slide"
              />
            </div>

            <ContentFooterButtons
              contentData={contentData}
              slideData={slideData}
              creatorMode={creatorMode}
              editMode={editMode}
              setEditMode={setEditMode}
              setQrOpen={setQrOpen}
              setChapterModal={setChapterModal}
            />

            {!creatorMode && contentData.slides.length > 1 && (
              <>
                {!isLastSlide ? (
                  <span className="next-message">スワイプして次へ</span>
                ) : (
                  <span className="next-message">Fin.</span>
                )}
              </>
            )}

            <div
              className={`page-icon-wrapper ${isLastSlide ? "disabled" : ""} ${
                !editMode &&
                contentData.slides.length > 1 &&
                currentSlideIndex == 0 &&
                !slideData.full_contents
                  ? "emphasize"
                  : ""
              }`}
              onClick={() => {
                nextSlide();
              }}
            >
              <FontAwesomeIcon
                icon={faAngleUp}
                className="page-icon next-slide"
              />
            </div>
          </div>
        )}

        {!editMode &&
          contentData.slides.length > 1 &&
          !isLastSlide &&
          contentData.slides[currentSlideIndex + 1].title != "" && (
            <div className="next-slide-title-section">
              <span className="next-que">Next |</span>
              <span className="next-slide-title">
                {contentData.slides[currentSlideIndex + 1].title}
              </span>
            </div>
          )}
      </div>
    </motion.div>
  );
};

export default ContentFooter;
