import React from "react";

// framer
import { motion } from "framer-motion";
import { framerFadeUp, framerSlideIn } from "../../helpers/Utility.js";

const AnimateUpWrapper = ({ children, className }) => {
  return (
    <motion.div
      variants={framerFadeUp}
      initial="hidden"
      animate="show"
      transition={framerFadeUp.transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimateUpWrapper;
