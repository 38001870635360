import React from "react";
import "./AdminSectionWrapper.scss";

// components
import Collapsible from "../../UtilityComponents/Collapsible";

const AdminSectionWrapper = ({ children, title, description, sectionType }) => {
  // ----- constants ---------------
  const sectionTypeInfo = {
    "admin": {
      color: "blue",
      displayName: "Admin",
    },
    "feature-test": {
      color: "orange",
      displayName: "機能テスト",
    },
    "components-test": {
      color: "brown",
      displayName: "コンポーネント",
    },
    "others": {
      color: "gray",
      displayName: "その他",
    },
  };

  return (
    <Collapsible
      wrapperClassName="admin-section-wrapper"
      headerSection={
        <div className="header-contents">
          {sectionType && (
            <span
              className="section-type"
              style={{
                backgroundColor: sectionTypeInfo[sectionType].color,
                color: "white"
              }}
            >
              #{sectionTypeInfo[sectionType].displayName}
            </span>
          )}
          {title && <span className="title">{title}</span>}
          {description && <p className="description">{description}</p>}
        </div>
      }
    >
      <div className="admin-section-container">{children}</div>
    </Collapsible>
  );
};

export default AdminSectionWrapper;
