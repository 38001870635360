import React from "react";
import "./Signup.scss";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

// enpoints
import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// libraries
import { Link, useNavigate } from "react-router-dom";

// auth
import { Auth } from "aws-amplify";
import { signUpUser } from "../helpers/authentication.js";

// helpers
import { validateEmailString } from "../helpers/Utility.js";

// components
import AuthenticationWrapper from "../components/AuthenticationWrapper.js";

const Signup = ({ auth }) => {
  const navigate = useNavigate();

  const [redirectToConfirm, setRedirectToConfirm] = React.useState(false);

  const { authState, setAuthState } = auth;

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [masterValidation, setMasterValidation] = React.useState(false);
  const [masterError, setMasterError] = React.useState({
    isErrored: false,
    errorMessage: "エラーが発生しました。",
  });
  const [data, setData] = React.useState({
    agreement: false,
    email: "",
    password: "",
    testerCode: "",
  });
  const [validationStatus, setValidationStatus] = React.useState({
    email: {
      validation: false,
      errorMessage: "",
    },
    password: {
      validation: false,
      errorMessage: "",
    },
    testerCode: {
      validation: false,
      errorMessage: "",
    },
  });

  const submitData = () => {
    signUpUser(
      authState,
      setAuthState,
      data.email,
      data.password,
      isConfirming,
      setIsConfirming,
      masterError,
      setMasterError,
      setRedirectToConfirm
    );
  };

  const validateMaster = () => {
    if (
      data.agreement &&
      validationStatus.email.validation &&
      validationStatus.password.validation
    ) {
      setMasterValidation(true);
    } else {
      setMasterValidation(false);
    }
  };

  const validateEmail = (email) => {
    const validation = validateEmailString(email);

    if (validation) {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        email: {
          validation: validation,
          errorMessage: "",
        },
      }));
    } else {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        email: {
          validation: validation,
          errorMessage: "正しいアドレスを入力してください。",
        },
      }));
    }
  };

  const validatePassword = (password) => {
    const regExpAlphabets = /[a-zA-Z]/g;
    const numbers = /\d/;
    const validation = regExpAlphabets.test(password) && numbers.test(password) && password.length >= 8;
    if (validation) {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        password: {
          validation: validation,
          errorMessage: "",
        },
      }));
      return;
    } else {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        password: {
          validation: validation,
          errorMessage: "パスワードは、8文字以上、数字とアルファベットの組み合わせである必要があります",
        },
      }));
      return;
    }
  };

  const validateTesterCode = (code) => {
    const validation = code != "";
    if (validation) {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        testerCode: {
          validation: validation,
          errorMessage: "",
        },
      }));
      return;
    } else {
      setValidationStatus((validationStatus) => ({
        ...validationStatus,
        testerCode: {
          validation: validation,
          errorMessage: "コードを入力してください",
        },
      }));
      return;
    }
  }

  React.useEffect(() => {
    validateEmail(data.email);
    validatePassword(data.password);
    validateTesterCode(data.testerCode);
  }, [data]);

  React.useEffect(() => {
    validateMaster();
  }, [validationStatus]);

  React.useEffect(() => {
    if (redirectToConfirm) {
      navigate("/confirm", { state: { email: data.email } });
    }
  }, [redirectToConfirm]);

  React.useEffect(() => {
    if (auth.authState.cognito_user?.attributes?.email_verified) {
      // setRedirectToHome(true);
      navigate("/");
    }
  }, [auth]);

  // ----- 検証中のためパスワードを必要とする ---------------
  const [testerValidated, setTesterValidated] = React.useState(false);
  const [trialCount, setTrialCount] =  React.useState(0);

  const sendTesterCode = () => {
    if (trialCount < 5) {
      setIsConfirming(true);
      axios
        .post(`${endpoints.api}/validate_tester_code`, {
          tester_code: data.testerCode,
        })
        .then((res) => {
          console.log(res);
          setIsConfirming(false);
          setMasterError({
            isErrored: false,
            errorMessage: "",
          })
          setTesterValidated(true);
        })
        .catch((err) => {
          setTrialCount(trialCount + 1)
          console.log(err);
          setIsConfirming(false);
          setMasterError({
            isErrored: true,
            errorMessage: "コードが正しくありません。",
          })
          setValidationStatus((validationStatus) => ({
            ...validationStatus,
            testerCode: {
              validation: false,
            }
          }))
        });
    } else {
      setMasterError({
        isErrored: true,
        errorMessage: "時間をおいて再度試してください。",
      })
    }
    
  }

  if (testerValidated) {
    return (
      <AuthenticationWrapper
        header="新規ユーザー登録"
        comment="pulp へようこそ"
        appendix={{
          loginLink: true,
          contactLink: true,
        }}
      >
        <div className="forms-wrapper">
          <div className="authentication-form-section">
            <span className="form-title">メールアドレス</span>
            <input
              type="text"
              className="form"
              value={data.email}
              placeholder="メールアドレスを入力"
              onChange={(e) => {
                setData((data) => ({
                  ...data,
                  email: e.target.value,
                }));
              }}
            />
            {!validationStatus.email.validation &&
              validationStatus.email.errorMessage != "" && (
                <div className="validationMessage">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span>{validationStatus.email.errorMessage}</span>
                </div>
              )}
          </div>
          <div className="authentication-form-section">
            <span className="form-title">パスワード</span>
            <input
              type="password"
              className="form"
              value={data.password}
              placeholder="パスワードを入力"
              onChange={(e) => {
                setData((data) => ({
                  ...data,
                  password: e.target.value,
                }));
              }}
            />
            {!validationStatus.password.validation &&
              validationStatus.password.errorMessage != "" && (
                <div className="validationMessage">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span>{validationStatus.password.errorMessage}</span>
                </div>
              )}
          </div>
        </div>
        <div className="button-section">
          <div className="agreement-section">
            <input
              type="checkbox"
              id="agreement"
              checked={data.agreement}
              onChange={(e) => {
                setData((data) => ({
                  ...data,
                  agreement: e.target.checked,
                }));
              }}
            />
            <label htmlFor="agreement">
              <a href="https://lekcha.com/terms.html" target="_blank" className="link">利用規約</a>・<a href="https://lekcha.com/privacy.html" target="_blank" className="link">プライバシーポリシー</a>に同意する
            </label>
          </div>

          <div
            className={`button ${
              !masterValidation || isConfirming ? "disabled" : ""
            }`}
            onClick={() => {
              if (masterValidation && !isConfirming) {
                submitData();
              }
            }}
          >
            <span>
              {!isConfirming ? "登録する" : "アカウントを作成しています..."}
            </span>
          </div>

          {masterError.isErrored && masterError.errorMessage != "" && (
            <div className="masterErrorMessage">
              <FontAwesomeIcon icon={faCircleExclamation} />
              <span>{masterError.errorMessage}</span>
            </div>
          )}
        </div>
      </AuthenticationWrapper>
    );
  } else {
    return (
      <AuthenticationWrapper
        header="新規ユーザー登録"
        comment="pulp は現在、一部のユーザーさまに限定α版を提供しています。"
        appendix={{
          loginLink: true,
          contactLink: true,
        }}
      >
        <div className="forms-wrapper">
          <div className="authentication-form-section">
            <span className="form-title">テストユーザーコード</span>
            <input
              type="text"
              className="form"
              value={data.testerCode}
              placeholder="コードを入力"
              onChange={(e) => {
                setData((data) => ({
                  ...data,
                  testerCode: e.target.value.replace(" ", ""),
                }));
              }}
            />
          </div>

          <div className="button-section">
            <div
              className={`button ${
                !validationStatus.testerCode.validation || isConfirming ? "disabled" : ""
              }`}
              onClick={() => {
                if (validationStatus.testerCode.validation && !isConfirming) {
                  sendTesterCode();
                }
              }}
            >
              <span>
                {!isConfirming ? "コードを認証" : "コードを認証しています..."}
              </span>
            </div>

            {masterError.isErrored && masterError.errorMessage != "" && (
              <div className="masterErrorMessage">
                <FontAwesomeIcon icon={faCircleExclamation} />
                <span>{masterError.errorMessage}</span>
              </div>
            )}
          </div>

          <div className="sns-section">
            <span className="sns-header">
              💡 事前登録や使い方は Instagram で！
            </span>
            <p className="sns-message">
              pulp の最新情報や使い方、事前登録方法に関しては、公式Instagramアカウントでご案内しています！ぜひフォローしてください。
            </p>
            <a href="https://www.instagram.com/pulp_lekcha/" target="_blank" className="instagram-wrapper">
              <img src={"/other_services/instagram.svg"} alt="" className="ig-icon" />
              <span>Pulp公式アカウントをフォロー</span>
            </a>
          </div>


        </div>
      </AuthenticationWrapper>
    );
  }
};

export default Signup;
