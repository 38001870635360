import React from "react";
import "./PeopleTest.scss";

// admin components
import {
  Section,
  Input,
  TextArea,
  Button,
} from "./AdminComponents/AdminComponents.js";

// helpers
import {
  getAdminFeedPost,
  updateAdminFeedPost,
} from "../../helpers/FeedPostHelper";

const PeopleTest = ({ auth }) => {
  // ----- states ---------------
  const [edited, setEdited] = React.useState(false);
  const [feedPostData, setFeedPostData] = React.useState({
    title: "",
    body_text: "",
    public_on_feed: false,
  });

  // ----- functions ------------
  const validation = () => {
    if (feedPostData.title == "" || feedPostData.body_text == "") {
      return false;
    }
    return true;
  }

  const getData = () => {
    getAdminFeedPost(auth.authState.cognito_user.username).then((data) => {
      setFeedPostData(data);
      setEdited(false);
    });
  };

  const saveData = () => {
    if (!validation()) {
      alert("タイトルと本文内容を入力してください。");
      getData();
      return;
    }

    updateAdminFeedPost(auth.authState.cognito_user.username, feedPostData)
      .then((data) => {
        alert("保存しました。");
        console.log(data);
        setFeedPostData(data);
        setEdited(false);
      })
      .catch((err) => {
        alert("保存に失敗しました。");
        console.log(err);
      });
  };

  const updateFeedPostData = (key, value) => {
    setFeedPostData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setEdited(true);
  }

  // ----- effects ---------------
  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Section
      title="返信メッセージの設定"
      description="オーディエンスがFormBlockに登録した際、自動で返信されるメッセージを設定します。"
      sectionClass="feed-post-section"
    >
      <Input
        header="title | 返信タイトル"
        value={feedPostData.title}
        onChange={(e) => {
          updateFeedPostData("title", e.target.value);
        }}
      />
      <TextArea
        header="body_text | 本文内容"
        value={feedPostData.body_text}
        onChange={(e) => {
          updateFeedPostData("body_text", e.target.value);
        }}
      />

      <div className="button-section">
        <Button text="保存" theme="dark" disabled={!edited || !validation()} onClick={() => {
          saveData();
        }} />
      </div>
    </Section>
  );
};

export default PeopleTest;
