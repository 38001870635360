import React from "react";
import "./AddBlockButton.scss";

// utility
import { readClipboard, validateUrlRegex } from "../helpers/Utility";

// helpers
import { moveBlocksToSlide } from "../helpers/ContentHelper";

// icons
import {
  faPaste,
  faArrowRightFromBracket,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// overlay
import Overlay from "../Overlays/Overlay";

const AddBlockButton = ({
  getContentData,
  editMode,
  slidesData,
  slideColors,
  currentSlideIndex,
  setAddBlockData,
  blockIndex,
}) => {
  // ------ States ----------------

  const moveBlocksOverlayInitial = {
    open: false,
    create: true,
    slideTitle: "",
  };
  const [moveBlocksOverlay, setMoveBlocksOverlay] = React.useState(
    moveBlocksOverlayInitial
  );

  // ----- slide colors ----------------
  const componentColors = slideColors
    ? {
        color: slideColors.legibleColor,
      }
    : {
        color: slidesData.slides[currentSlideIndex].font_color,
      };

  // ----- Functions ---------------
  // クリップボードのデータからブロックを追加する
  const addBlockFromClipboardData = (clipboardData) => {
    var blockType = "";

    console.log(clipboardData);

    switch (clipboardData.type) {
      case "text":
        if (validateUrlRegex(clipboardData.data) == true) {
          blockType = "link";
        } else {
          blockType = "text";
        }
        break;
      case "image":
        blockType = "image";
        break;
    }

    console.log(blockType);

    alert("Coming soon ...");

    return;
  };

  // この AddBlockButton 以降のブロックを、次のスライドに分割する
  const sendBlocksToNextSlide = (createNew) => {
    const currentSlideId = slidesData.slides[currentSlideIndex].id;
    const blockIdsToMove = slidesData.slides[currentSlideIndex].blocks
      .slice(blockIndex)
      .map((block) => block.id);

    if (createNew) {
      moveBlocksToSlide({
        slideId: currentSlideId,
        blockIdsToMove: blockIdsToMove,
        slideTitle: moveBlocksOverlay.slideTitle,
      }).then((targetSlideId) => {
        getContentData(undefined, targetSlideId);
      });
    } else {
      moveBlocksToSlide({
        slideId: currentSlideId,
        blockIdsToMove: blockIdsToMove,
        slideTitle: moveBlocksOverlay.slideTitle,
        targetSlideId: slidesData.slides[currentSlideIndex + 1].id,
      }).then((targetSlideId) => {
        getContentData(undefined, targetSlideId);
      });
    }

    setMoveBlocksOverlay(moveBlocksOverlayInitial);
  };

  // ------ Render ----------------

  if (editMode && !slidesData.slides[currentSlideIndex].full_contents) {
    return (
      <>
        <div
          className="add-block-button-wrapper"
          style={{ color: componentColors.color }}
        >
          <div
            className="dotted-line"
            style={{
              borderColor: componentColors.color,
            }}
          ></div>
          <div className="buttons">
            {false && (
              <div
                className="slide-block-add-button"
                style={{
                  borderColor: slidesData.slides[currentSlideIndex].font_color,
                }}
                onClick={() => {
                  setAddBlockData({
                    modalOpen: true,
                    indexToAdd: blockIndex,
                  });
                }}
              >
                <span
                  style={{
                    color: slidesData.slides[currentSlideIndex].font_color,
                  }}
                >
                  + ブロックを追加
                </span>
              </div>
            )}

            <div
              className="slide-block-add-button round emphasize scale-on-hover"
              style={{
                borderColor: componentColors.color,
              }}
              onClick={() => {
                setAddBlockData({
                  modalOpen: true,
                  indexToAdd: blockIndex,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>

            <div
              className="separator"
              style={{
                background: componentColors.color,
              }}
            ></div>

            <div
              className="slide-block-add-button round no-border"
              style={{
                borderColor: componentColors.color,
              }}
              onClick={() => {
                alert("Coming soon ...");
                // readClipboard().then((data) => {
                //   addBlockFromClipboardData(data);
                // });
              }}
            >
              <FontAwesomeIcon icon={faPaste} />
            </div>

            {slidesData.slides[currentSlideIndex].blocks.length >
              blockIndex && (
              <>
                <div
                  className="separator"
                  style={{
                    background: componentColors.color,
                  }}
                ></div>

                <div
                  className="slide-block-add-button round no-border"
                  style={{
                    borderColor: componentColors.color,
                  }}
                  onClick={() => {
                    setMoveBlocksOverlay((moveBlocksOverlay) => ({
                      ...moveBlocksOverlay,
                      open: true,
                    }));
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                </div>
              </>
            )}
          </div>
          <div
            className="dotted-line"
            style={{
              borderColor: componentColors.color,
            }}
          ></div>
        </div>

        {/* ----- Modals --------------- */}
        <Overlay
          open={moveBlocksOverlay.open}
          setOpen={(value) => {
            setMoveBlocksOverlay((moveBlocksOverlay) => ({
              ...moveBlocksOverlay,
              open: value,
            }));
          }}
          title={"スライドの分割"}
          description={"これ以降のブロックを次のスライドに移動します。"}
          buttons={{
            primaryButton: {
              display: true,
              text: "移動",
              action: (e) => {
                setMoveBlocksOverlay((moveBlocksOverlay) => ({
                  ...moveBlocksOverlay,
                  open: false,
                }));
                sendBlocksToNextSlide(moveBlocksOverlay.create);
              },
            },
            secondaryButton: {
              display: true,
              text: "閉じる",
              action: (e) => {
                setMoveBlocksOverlay(moveBlocksOverlayInitial);
              },
            },
          }}
        >
          <div className="move-block-modal">
            {slidesData.slides.length > currentSlideIndex + 1 && (
              <div className="section">
                <span className="section-title">どこに移動しますか？</span>
                <div className="destination-list">
                  <div
                    className={`destination ${
                      moveBlocksOverlay.create ? "selected" : ""
                    }`}
                    onClick={() => {
                      setMoveBlocksOverlay((moveBlocksOverlay) => ({
                        ...moveBlocksOverlay,
                        create: true,
                      }));
                    }}
                  >
                    <span>新規スライド</span>
                  </div>
                  <div
                    className={`destination ${
                      !moveBlocksOverlay.create ? "selected" : ""
                    }`}
                    onClick={() => {
                      setMoveBlocksOverlay((moveBlocksOverlay) => ({
                        ...moveBlocksOverlay,
                        create: false,
                      }));
                    }}
                  >
                    <span>次のスライド</span>
                  </div>
                </div>
              </div>
            )}

            {moveBlocksOverlay.create && (
              <div className="section">
                <span className="section-title">新しいスライドのタイトル</span>
                <input
                  className="slide-title-input"
                  type="text"
                  placeholder="スライドのタイトル"
                  value={moveBlocksOverlay.slideTitle}
                  onChange={(e) => {
                    setMoveBlocksOverlay((moveBlocksOverlay) => ({
                      ...moveBlocksOverlay,
                      slideTitle: e.target.value,
                    }));
                  }}
                />
              </div>
            )}
          </div>
        </Overlay>
      </>
    );
  }
};

export default AddBlockButton;
