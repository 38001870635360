import isDev from "./DevDetect";


const getApiUrl = () => {

  if (isDev()) {

    return({
      // api: "http://10.0.2.2:4000",
      api: "http://localhost:4000",
      // api: "https://06c9-60-102-178-79.ngrok-free.app"
    })

  } else {


    // ===== CAUTION ===============
    // when you update the api url, make sure to check the index.html file for dynamic manifest generation endpoint for PWA.
    // =============================

    
    return({
      api: "https://lekcha-blocks-api.herokuapp.com",
    })

  }

}

const apiUrls = getApiUrl()

export default apiUrls;
