import React from "react";
import "./AppHeader.scss";
import { Link } from "react-router-dom";

// authentication
import { signOutUser } from "../../helpers/authentication.js";

const AppHeader = ({ display, auth }) => {
  return (
    <div className={`brand-bar-wrapper ${!display ? "hide" : ""}`}>
      <Link to={"/"} className="brand-bar">
        {/* <span>Block</span> */}
        <img src="/logo/pulp_logo_paper.svg" alt="" className="app-icon" />
        <img src="/logo/pulp.svg" alt="" className="app-logo" />
      </Link>

      {auth.authState.isAdmin == true && (
        <Link to={"/admin"} className="brand-bar">
          <span className="underline">admin</span>
        </Link>
      )}

      {auth.authState.cognito_user ? (
        <div
          className="logout cursor-pointer"
          onClick={() => {
            if (window.confirm("ログアウトしますか？")) {
              signOutUser(auth);
            }
          }}
        >
          <span>ログアウト</span>
        </div>
      ) : (
        <Link to="/login" className="logout cursor-pointer">
          <span>ログイン</span>
        </Link>
      )}
    </div>
  );
};

export default AppHeader;
