import "./FormContent.scss";

const FormContent = ({children, subTitle, customClassName=""}) => {
  return (
    <div className={`form-section-body ${customClassName}`}>
      { subTitle && <span className="form-section-body-title">{subTitle}</span>}
      {children}
    </div>
  );
};

export default FormContent;