import React from "react";
import "./UnsplashWindow.scss";

// endoints
import axios from "axios";
import endpoints from "../../helpers/apiUrls.js";

// components
import SearchBox from "../BasicComponents/SearchBox.js";

// overlays
import Overlay from "../../Overlays/Overlay.js";

const UnsplashWindow = ({ unsplashOpen, setUnsplashOpen, setImageUrl }) => {

  // ----- states ---------------
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchPending, setSearchPending] = React.useState(false); // to show loading icon
  const [imageUrls, setImageUrls] = React.useState([]);

  // ----- functions ---------------
  const searchUnsplash = () => {
    if (searchQuery == "") return;
    axios
      .post(`${endpoints.api}/unsplash/search_images`, {
        query: searchQuery,
      })
      .then((res) => {
        setImageUrls(res.data);
        setSearchPending(false);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const handleSelectImage = (imageUrl) => {
    setImageUrl(imageUrl);
    closeWindow(false);
  };

  const closeWindow = (value) => {
    if (value == false) {
      setImageUrls([]);
      setSearchQuery("");
      setSearchPending(false);
      setUnsplashOpen(false);
    }
  };

  // ----- effects ---------------
  // update if search is pending when the query is updated
  React.useEffect(() => {
    if (searchQuery != "") {
      setSearchPending(true);
    } else {
      setSearchPending(false);
    }
  }, [searchQuery]);

  return (
    <Overlay
      open={unsplashOpen}
      setOpen={closeWindow}
      title="画像を検索"
      description="Unsplashで画像を検索します。(画像が見つからない場合は、英語での検索をお試しください。)"
      buttons={{
        primaryButton: {
          display: false,
          text: "",
          action: (e) => {},
        },
        secondaryButton: {
          display: false,
          text: "",
          action: (e) => {},
        },
      }}
      fullScreen={true}
    >
      <div className="unsplash-window-component-wrapper">
        <div className="search-section">
          <SearchBox
            placeholder="検索キーワード"
            value={searchQuery}
            setValue={setSearchQuery}
            searchButtonVisible={true}
            searchButtonFunction={() => {
              searchUnsplash();
            }}
          />
        </div>

        <div className="images-section">
          {imageUrls.length == 0 && !searchPending && searchQuery != "" && (
            <span className="no-result-message">
              画像が見つかりませんでした。
            </span>
          )}

          <div className="unsplash-images">
            {imageUrls.map((url, index) => {
              return (
                <div className="image-wrapper" key={url}>
                  <img
                    src={url}
                    className="unsplash-image cursor-pointer scale-on-hover"
                    onClick={() => {
                      handleSelectImage(url);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default UnsplashWindow;
