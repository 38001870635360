import React from "react";
import "./Uploading.scss";

const Uploading = ({ percentage, message, guage = true, seethrough=false }) => {

  return (
    <div className={`uploading-guage-wrapper ${seethrough ? "seethrough" : ""}`}>
      <span className="message">{message}</span>
      {guage && (
        <div className="guage-back">
          <div
            className="guage-bar"
            style={{
              width: `${percentage}%`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Uploading;
