import React from "react";
import "./AppStart.scss";

// framer
import { framerFadeUp } from "../helpers/Utility";
import { motion } from "framer-motion";

const AppStart = () => {
  return (
    <div className="app-start-screen-wrapper">
      <motion.div
        className="start-screen-content"
        variants={framerFadeUp}
        initial="hidden"
        animate="show"
        transition={framerFadeUp.transition}
      >
        <img className="icon" src="/icons_2/pulp_icon.png" alt="" />
        <span className="loading-text">Now Loading ...</span>
      </motion.div>
    </div>
  );
};

export default AppStart;
