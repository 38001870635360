// ------ Config ---------------

import { progress } from "framer-motion";

// the required distance between touchStart and touchEnd to be detected as a swipe
const swipeBoundary = 80;

// apply threshold to vertical swipe
const verticalBuffer = 50;

// ----- Detecting vertical scroll position -----

const detectBound = (distanceY, ref) => {
  let result = {
    direction: null,
    boundReached: false,
    remainingDistance: null,
  };

  const scrollPosition = window.scrollY; // => scroll position

  // which direction?
  if (distanceY > 0) {
    // scroll up
    result.direction = "top";

    // isTop?
    if (scrollPosition == 0) {
      result.boundReached = true;
    }

    // remaining distance
    result.remainingDistance = scrollPosition;
  } else {
    // scroll down
    result.direction = "bottom";

    // isBottom?
    const windowHeight = window.innerHeight; // => window height
    const scrollHeight = ref.current.scrollHeight; // => scroll height
    const bottomScrollPosition = scrollHeight - windowHeight; // => bottom scroll position

    if (scrollPosition >= bottomScrollPosition) {
      result.boundReached = true;
    }

    // remaining distance
    result.remainingDistance = bottomScrollPosition - scrollPosition;
  }

  return result;
};

export const detectBottom = (ref) => {
  const scrollPosition = window.scrollY; // => scroll position
  console.log({ scrollPosition });

  const windowHeight = window.innerHeight; // => window height
  const scrollHeight = ref.current.scrollHeight; // => scroll height

  const bottomScrollPosition = scrollHeight - windowHeight; // => bottom scroll position

  const isBottom = scrollPosition >= bottomScrollPosition;
  const remainingDistance = bottomScrollPosition - scrollPosition;

  const result = {
    isBottom,
    remainingDistance,
  };

  return result;
};

export const detectTop = () => {
  const scrollPosition = window.scrollY; // => scroll position
  if (scrollPosition == 0) console.log("is Top", scrollPosition);
  console.log({ scrollPosition });

  const isTop = scrollPosition == 0;
  const remainingDistance = scrollPosition;

  const result = { isTop, remainingDistance };

  return result;
};

// ----- Touch Event handlers --------------

export const handleTouchMove = (
  e,
  touchStart,
  touchEnd,
  setTouchEnd,
  mode,
  heightRef,
  boundaryReachedAt,
  progressCallback = () => {},
  boundReachedCallback = () => {},
  resetScroll
) => {
  const updatedPositions = {
    x: e.targetTouches[0].clientX,
    y: e.targetTouches[0].clientY,
  };

  const distanceY = updatedPositions.y - touchStart.y;
  const absDistanceY = Math.abs(distanceY);

  // ----- if scroll changed direction, reset. --------------
  // First, detect if the scroll direction changed. Direction change can be detemined by first determining current direction by comparing
  // touchEnd and updatedPositions, and then compare to intended scroll direction by boundaryReachedAt.direction.

  var directionChangeOffset = 0;

  if (boundaryReachedAt && touchEnd) {
    const directionChangeBoundary = 5;

    const currentDirection =
      touchEnd.y - updatedPositions.y < 0 ? "top" : "bottom";

    const intendedDirection = boundaryReachedAt.direction;

    if (currentDirection != intendedDirection) {

      resetScroll();



      //  when the direction change, record the position to boundaryReachedAt (add key 'directionChangedAt').

      // console.log(absDistanceY)
      // if (!boundaryReachedAt.directionChangedAt) {
      //   boundReachedCallback((prev) => ({
      //     ...prev,
      //     directionChangedAt: absDistanceY,
      //   }));
      // }

      // then, give the direction change verdict a buffer of 10px using directionChangedAt (if the direction change is within 10px, it is not considered a direction change.)
      // comparing current position and the directionChangedAt, if the direction change is larger than 10px, reset.


      // if (
      //   Math.abs(absDistanceY - boundaryReachedAt.directionChangedAt) >
      //   directionChangeBoundary
      // ) {
      //   resetScroll();
      //   directionChangeOffset = Math.abs(absDistanceY - boundaryReachedAt.directionChangedAt);
      // }
    }
  }

  // update touchEnd
  setTouchEnd(updatedPositions);

  if (mode == "vertical") {
    // check if boundary is reached
    const bound = detectBound(distanceY, heightRef);

    if (bound.boundReached) {
      // if bound is reached,

      if (
        !boundaryReachedAt ||
        absDistanceY <= boundaryReachedAt.scrollDistance
      ) {
        // if the boundaryReachedAt is null and distanceY is not larger than boundaryReachedAt, record the distance.
        const boundaryReachedData = {
          scrollDistance: absDistanceY,
          direction: bound.direction,
        };
        boundReachedCallback(boundaryReachedData);
      } else {
        // (if distanceY is larger, that means the boundary is already reached, and the user is scrolling further.)
        // calculate outOfBoundaryDistance (how much user has scrolled past the boundary)
        const outOfBoundaryDistance =
          // absDistanceY - boundaryReachedAt.scrollDistance - directionChangeOffset;
          absDistanceY - boundaryReachedAt.scrollDistance;
        // console.log({directionChangeOffset});

        // calculate percentage
        const percentage =
          outOfBoundaryDistance / (swipeBoundary + verticalBuffer);

        // if the percentage is below 1 and above 0.5, set the filteredPercentage to percentage. if the percentage is below 0.5, 0, and anything else is 1.
        const fileteredPercentage = percentage < 1 && percentage > 0.05 ? percentage : percentage < 0.05 ? 0 : 1;

        progressCallback({
          percentage: fileteredPercentage,
          position: boundaryReachedAt.direction,
        });
      }
    } else {
      // if bound is not reached, reset the boundaryReachedAt
      boundReachedCallback(null);
    }
  } else {
    return;
  }
};

export const handleTouchEnd = (touchStart, touchEnd, mode, navigationGuageData) => {
  if (!touchStart || !touchEnd) return;

  // calculate distances
  const distanceX = touchEnd.x - touchStart.x;
  const distanceY = touchEnd.y - touchStart.y;

  if (mode == "horizontal") {
    if (Math.abs(distanceY) > swipeBoundary) return; // return if it is a vertical swipe
    if (Math.abs(distanceX) < swipeBoundary) return; // return if swipe is too small

    if (distanceX > 0) {
      return "prev";
    } else {
      return "next";
    }
  } else {
    if (navigationGuageData.percentage >= 1) {
      return navigationGuageData.position == "top" ? "prev" : "next";
    }
  }
};
