import React from "react";
import "./PublicPageWrapper.scss";

// made with
import MadeWithPulp from "../../UtilityComponents/MadeWithPulp";

const PublicPageWrapper = ({ auth, children }) => {
  return (
    <div className="public-page-screen-wrapper">
      <div className="public-page-container">{children}</div>
      <div className="made-with-section">
        <MadeWithPulp />
      </div>
    </div>
  );
};

export default PublicPageWrapper;
