import React from "react";
import "./TableOfContentsModal.scss";

// modal components
import SlideModal from "./SlideModal";
import Overlay from "./Overlay";

// icons
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableOfContentsModal = ({
  editMode,
  chapterModal,
  setChapterModal,
  contentData,
  setContentData,
  currentSlideIndex,
  setCurrentSlideIndex,
}) => {
  // ------ states -------------
  const [focusSlideId, setFocusSlideId] = React.useState(null);

  // ----- refs ----------
  const tableOfContentsModalRef = React.useRef(null);

  // ------ functions ---------------
  const incrementSlideDepth = (slideIndex) => {
    if (contentData.slides[slideIndex].depth_level >= 1) {
      return;
    }

    var updatedSlideData = contentData.slides;
    updatedSlideData[slideIndex].depth_level += 1;
    setContentData({ ...contentData, slides: updatedSlideData });
  };

  const decrementSlideDepth = (slideIndex) => {
    if (contentData.slides[slideIndex].depth_level <= 0) {
      return;
    }

    var updatedSlideData = contentData.slides;
    updatedSlideData[slideIndex].depth_level -= 1;
    setContentData({ ...contentData, slides: updatedSlideData });
  };

  // ------ Effects ----------------
  // jump to current slide
  React.useEffect(() => {
    const currentSlideId = contentData.slides[currentSlideIndex].id;
    setFocusSlideId(`table-content-slide-${currentSlideId}`);
  }, [currentSlideIndex, chapterModal]);

  return (
    <SlideModal
      title="もくじ"
      occupationSteps={[0.5, 0.9, 0.25]}
      open={chapterModal}
      setOpen={setChapterModal}
      focusId={focusSlideId}
      parentElForPaddingAdjustment={
        document.getElementsByClassName("slide-contents-wrapper")[0]
      }
    >
      <div className={`slides-section ${editMode ? "edit-mode" : ""}`}>
        {contentData.slides.map((slide, index) => {
          return (
            <div
              className="slide-wrapper"
              key={`table-content-slide-${slide.id}`}
              id={`table-content-slide-${slide.id}`}
            >
              <div className="slide-container-wrapper">
                {slide.depth_level > 0 && (
                  <div className="slide-depth-level-section">
                    {Array(slide.depth_level)
                      .fill(null)
                      .map((_, i) => {
                        return <div className="indent-dot" key={i}></div>;
                      })}
                  </div>
                )}
                <div
                  className={`slide-container ${
                    index === currentSlideIndex ? "active" : ""
                  }`}
                  onClick={() => {
                    setCurrentSlideIndex(index);
                    // setChapterModal(false);
                  }}
                >
                  <div className="slide-title-section">
                    {editMode ? (
                      <span className="index">スライド {index + 1}</span>
                    ) : (
                      <span className="index">{index + 1}</span>
                    )}

                    {slide.title != "" && (
                      <span className="slide-title">{slide.title}</span>
                    )}
                  </div>

                  <span className="blocks-count">
                    {slide.blocks.length} ブロック
                  </span>
                </div>
              </div>

              {editMode && (
                <div className="slide-edit-section">
                  {false && (
                    <>
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        className={`up-arrow slide-edit-arrow`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // decrementSlideDepth(index);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        className={`down-arrow slide-edit-arrow`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          // incrementSlideDepth(index);
                        }}
                      />
                    </>
                  )}

                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className={`left-arrow slide-edit-arrow`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      decrementSlideDepth(index);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className={`right-arrow slide-edit-arrow`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      incrementSlideDepth(index);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </SlideModal>
  );
};

export default TableOfContentsModal;
