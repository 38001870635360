import React from 'react';
import "./AdminComponents.scss";

export const Section = ({ children, sectionClass, title, description }) => {
  return (
    <div className="admin-section">
      <div className="section-info">
        <span className="section-title">■ {title}</span>
        {description && <p className="section-description">{description}</p>}
      </div>
      <div className={`section-contents ${sectionClass}`}>{children}</div>
    </div>
  );
};

export const Status = ({ title, value, color, children }) => {
  return (
    <div className="status">
      <span className="status-name">{title}</span>
      <span className={`status-value ${color}`}>{value}</span>
      {children}
    </div>
  );
};

export const Input = ({ header, value, onChange }) => {
  return (
    <div className="admin-input-component">
      <span className="header">{header}</span>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className="send-section-input"
      />
    </div>
  );
};

export const TextArea = ({ header, value, onChange }) => {
  return (
    <div className="admin-textarea-component">
      <span className="header">{header}</span>
      <textarea
        value={value}
        onChange={onChange}
        className="send-section-input"
      />
    </div>
  );
};

export const Button = ({ text, onClick, theme="dark", disabled, className }) => {
  return (
    <div
      className={`admin-button cursor-pointer ${theme} ${
        disabled ? "disabled" : "scale-on-hover"
      } ${className ? className : ""}`}
      onClick={!disabled ? onClick : () => {}}
    >
      <span>{text}</span>
    </div>
  );
};