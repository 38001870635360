import React from "react";
import { Auth, Hub } from "aws-amplify";

// router
import { redirect } from "react-router-dom";

// enpoints
import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// ---- Session -------------------

// sessions
export const getCurrentSession = async (authProps) => {
  const { authState, setAuthState } = authProps;

  setAuthState((authState) => ({
    ...authState,
    isAuthenticating: true,
  }));

  Auth.currentSession()
    .then((session) => {
      // console.log(session);
      // setAuthState((authState) => ({ ...authState, isAuthenticated: true }));
      const user = Auth.currentAuthenticatedUser().then((user) => {

        // check admin status
        axios
          .get(`${endpoints.api}/users/${user.username}`)
          .then((admin_user) => {
            if (admin_user.data.admin) {
              setAuthState((authState) => ({
                ...authState,
                isAdmin: true
              }));
            } else {
              setAuthState((authState) => ({
                ...authState,
                isAdmin: false
              }));
            }
          })
          .catch((apiErr) => {
            console.log(apiErr);
          });

        setAuthState((authState) => ({
          ...authState,
          isAuthenticated: true,
          isAuthenticating: false,
          cognito_user: user,
        }));
      });
    })
    .catch((error) => {
      // console.log(error);
      setAuthState((authState) => ({
        ...authState,
        isAuthenticated: false,
        isAuthenticating: false,
        cognito_user: null,
      }));
    });
};

// ---- Signups -------------------

// signups
export const signUpUser = async (
  authState,
  setAuthState,
  email,
  password,
  isConfirming,
  setIsConfirming,
  masterError,
  setMasterError,
  setRedirectToConfirm
) => {
  setIsConfirming(true);
  Auth.signUp({
    username: email,
    password: password,
    attributes: {
      email: email,
    },
    autoSignIn: {
      // optional - enables auto sign in after user is confirmed
      enabled: true,
    },
  })
    .then((res) => {
      console.log("signup res: ", res);
      // create user in backend api
      setAuthState((authState) => ({
        ...authState,
        cognito_user: res.user,
      }));

      axios
        .post(`${endpoints.api}/users`, {
          cognito_id: res.userSub,
          email: res.user.username,
        })
        .then((user) => {
          console.log("user created in backend", user);
          // send user to confirm page
          setRedirectToConfirm(true);
        })
        .catch((apiErr) => {
          console.log(apiErr);
        });
    })
    .catch((err) => {
      console.log(err);
      switch (err.name) {
        case "UsernameExistsException":
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "すでにユーザーが存在します。",
          }));
          setIsConfirming(false);
          break;
        case "InvalidPasswordException":
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage:
              "パスワードは、8文字以上、数字とアルファベットの組み合わせである必要があります。",
          }));
          setIsConfirming(false);
          break;
        default:
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "エラーが発生しました。",
          }));
          setIsConfirming(false);
      }
    });
};

// resending signup confirmation code
export const resendConfirmation = async (email, displayAlert) => {
  Auth.resendSignUp(email)
    .then((res) => {
      if (displayAlert) {
        alert("認証コードを再送しました。");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// sign up user confirmation
export const confirmUser = async (
  authProps,
  email,
  code,
  masterError,
  setMasterError,
  setRedirectToHome
) => {
  const { authState, setAuthState } = authProps;

  Auth.confirmSignUp(email, code)
    .then((res) => {
      getCurrentSession(authProps).then(() => {
        setRedirectToHome(true);
      });
    })
    .catch((err) => {
      console.log(err);
      switch (err.name) {
        case "CodeMismatchException":
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "コードが間違っています。",
          }));
          break;
        case "ExpiredCodeException":
          resendConfirmation(authState.user.email, false);
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage:
              "コードの有効期限が切れています。\n新しいコードを送信しました。",
          }));
          break;
        default:
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "エラーが発生しました。",
          }));
      }
    });
};

// ---- Signins -------------------

// signins
export const signInUser = async (
  auth,
  email,
  password,
  isConfirming,
  setIsConfirming,
  masterError,
  setMasterError,
  setRedirectToConfirm
) => {
  const { authState, setAuthState } = auth;

  setIsConfirming(true);

  Auth.signIn(email, password)
    .then((res) => {
      getCurrentSession(auth);
    })
    .catch((err) => {
      console.log(err);
      switch (err.name) {
        case "UserNotConfirmedException":
          // send user to confirm page
          setRedirectToConfirm(true);
          break;
        case "NotAuthorizedException":
          setIsConfirming(false);
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "メールアドレスまたはパスワードが間違っています。",
          }));
          break;
        case "LimitExceededException":
          setIsConfirming(false);
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "上限を超えました。時間をおいてお試しください。",
          }));
          break;
        default:
          setIsConfirming(false);
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "エラーが発生しました。",
          }));
      }
    });
};

// ---- Signouts -------------------

// signouts
export const signOutUser = async ({ authState, setAuthState }) => {
  Auth.signOut()
    .then((res) => {
      console.log("signed out: ", res);
      setAuthState((authState) => ({
        ...authState,
        isAuthenticated: false,
        isAuthenticating: false,
        cognito_user: null,
      }));
    })
    .catch((err) => {
      console.log("failed to sign out: ", err);
    });
};

// ---- Forgot Passwords -------------------

// sending reset confirmation code
export const sendPasswordResetCode = async (
  email,
  setIsConfirming,
  setResetStep,
  masterError,
  setMasterError
) => {
  setIsConfirming(true);
  Auth.forgotPassword(email)
    .then((data) => {
      console.log(data);
      setResetStep("resetPassword");
      setIsConfirming(false);
    })
    .catch((err) => {
      console.log(err);
      switch (err.name) {
        case "LimitExceededException":
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "上限を超えました。時間をおいてお試しください。",
          }));
          setIsConfirming(false);
          break;
        default:
          setMasterError((masterError) => ({
            ...masterError,
            isErrored: true,
            errorMessage: "エラーが発生しました。",
          }));
          setIsConfirming(false);
      }
    });
};

// confirming code and changing password
export const resetPassword = async (
  email,
  code,
  newPassword,
  setIsConfirming,
  setRedirectToLogin,
  masterError,
  setMasterError
) => {
  setIsConfirming(true);
  Auth.forgotPasswordSubmit(email, code, newPassword)
    .then((data) => {
      console.log(data);
      setIsConfirming(false);
      setRedirectToLogin(true);
    })
    .catch((err) => {
      console.log(err);
      setMasterError((masterError) => ({
        ...masterError,
        isErrored: true,
        errorMessage: "エラーが発生しました。",
      }));
      setIsConfirming(false);
    });
};
