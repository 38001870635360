import axios from "axios";
import endpoints from "./apiUrls.js";

// colors
import { slideThemeColors } from "./Colors.js";

// fonts
import {
  faFont,
  faImage,
  faLink,
  faVideo,
  faCode,
  faTrashCan,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

// ----- デフォルトデータ系 ---------------
export const sampleContentData = (contentName) => {
  const sampleContents = {
    how_to: {
      id: "content-3",
      title: "Welcome | Lekcha Blocksの使い方",
      slides: [
        {
          id: "slide-0",
          fullContents: false,
          subTitle: "Welcome!",
          title: "Lekcha Blocksへようこそ！",
          backgroundColor: "#4abde1",
          fontColor: "#ededed",
          blocks: [
            {
              type: "image",
              data: {
                url: "https://gifdb.com/images/high/aesthetic-welcome-water-akgp7yuidec4o8nn.gif",
                caption: "",
                cropped: false,
                indentation: 0,
                tilt: true,
              },
              id: "slide-0-image-7",
            },
            {
              type: "text",
              data: {
                header: "はじめまして！",
                text: "ここでは、Lekchaの新機能 『Lekcha Blocks』 についてご説明します🔥",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-0-text-0",
            },
            {
              type: "text",
              data: {
                header: "新機能: Lekcha Blocksって？",
                text: "",
                boxColor: "lighter",
                indentation: 1,
                shadowed: true,
              },
              id: "slide-0-text-5",
            },
            {
              type: "text",
              data: {
                header: "全く新しい教え方・学び方",
                text: "インタラクティブなスライドをスマホで簡単に作成し、無料コンテンツとして共有したり、講座として販売できる機能です。 知識を「まとめて、共有」することに特化しており、見ていて飽きないわかりやすいデザインで、全く新しい学習体験を届けることができます。",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-8",
            },
            {
              type: "text",
              data: {
                header: "様々な「ブロック」を使って自在に表現",
                text: "テキスト、画像、動画、リンク、コード、縦型動画、メールフォームなど、いろんな形の情報を「ブロック」という形で自由に組み合わせられます。",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-7",
            },
            {
              type: "text",
              data: {
                header: "👇こんな画面で編集します",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-0-text-2",
            },
            {
              type: "image",
              data: {
                url: "https://drive.google.com/uc?export=download&id=16ug0TLCsF18F8s1iYGxIVqU5174bRiff",
                caption: "",
                cropped: false,
                indentation: 2,
              },
              id: "slide-0-image-1",
            },
            {
              type: "text",
              data: {
                header: "それでは早速、ページをめくってみてください！",
                text: "",
                boxColor: "lighter",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-0-text-4",
            },
          ],
          locked: false,
        },
        {
          id: "slide-4",
          fullContents: false,
          subTitle: "Lekcha Blocksって…",
          title: "何ができるの？",
          backgroundColor: "#e7e5e2",
          fontColor: "#685e57",
          blocks: [
            {
              type: "text",
              data: {
                header: "Lekcha Blocksを使えば…",
                text: "",
                boxColor: "lighter",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-4-text-16",
            },
            {
              type: "text",
              data: {
                header:
                  "SNSでは教えきれない・伝えにくいノウハウを、サクッと体系的にまとめて発信できる",
                text: "",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-4-text-14",
            },
            {
              type: "text",
              data: {
                header:
                  "動画講座を作成するきっかけとして、単発のミニ講座を作成できる",
                text: "",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-4-text-0",
            },
            {
              type: "text",
              data: {
                header:
                  "既に持っている講座につなげる無料特典や、学習後の補助教材として活用できる",
                text: "",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-1-text-15",
            },
            {
              type: "text",
              data: {
                header:
                  "そして、自分の活動や、教えている内容をまとめておく「自己紹介」の場としても活用できちゃう😍",
                text: "",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-4-text-1",
            },
            {
              type: "image",
              data: {
                url: "https://www.icegif.com/wp-content/uploads/icegif-81.gif",
                caption: "",
                cropped: true,
                indentation: 1,
                tilt: true,
              },
              id: "slide-4-image-17",
            },
            {
              type: "text",
              data: {
                header: "ブロックを重ねて、スライドを作る。",
                text: "",
                boxColor: "lighter",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-4-text-2",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "さまざまな種類の「ブロック」を使って、簡単にコンテンツを作成。",
                boxColor: "darker",
                indentation: 2,
                shadowed: true,
              },
              id: "slide-4-text-15",
            },
            {
              type: "image",
              data: {
                url: "https://i.ibb.co/c8dGS47/CFCFCCBB-F959-4268-9-C38-384-CAB040-B0-E.jpg",
                caption: "今後もどんどん追加予定！",
                cropped: false,
                indentation: 2,
                tilt: true,
              },
              id: "slide-4-image-5",
            },
            {
              type: "text",
              data: {
                header: "スマホひとつで、知識を体系化。",
                text: "",
                boxColor: "lighter",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-4-text-6",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "動画を挿入したり、",
                boxColor: "darker",
                indentation: 2,
                shadowed: true,
              },
              id: "slide-4-text-12",
            },
            {
              type: "video",
              data: {
                caption: "",
                cropped: false,
                mux_asset_id: "4IoT02wyWn16W7yJZRE5xYmlDOyH9eyosVHS8vgXzLo00",
                mux_playback_id: "TmvveNUQNSZziyVf7wURrZHSwMa6Vs8rGjcxKQPiuiU",
                originalFilename:
                  "mixkit-beautiful-sunrise-landscape-1944-medium.mp4",
                sourceUrl:
                  "https://storage.googleapis.com/video-storage-gcp-us-east1-vop1-uploads/fOZpebiOYJAok9Dpefj47v?Expires=1681970131&GoogleAccessId=uploads-gcp-us-east1-vop1%40mux-video-production.iam.gserviceaccount.com&Signature=ActZfYRDZq2RNJiL8tmswOzAlvIjmmMG2TXF5eNrMkmMo%2B%2Fyp6cIiRKXxy5V334VjnjXhzS%2BPV0pGescTPo0jkp3deYu17ru%2FeE8C41DL7zh6Hf1FCPtpVtnatlcTLA%2BfYPkR0xyCgFbpvoojiRu5PKggs4j6KRgLDhhtaALmK%2BloktKj7z78yWWnitCVmLyIXlLmdINQJh%2F51t5%2BLn6F%2FW0OqalQQFw4EZLGFE1KLNVt9oo9j49ntbulEdY4i6MhtBu72PUtNIQ5Eg81nP%2BcLhUkumoz1vUj5xF7dz%2Ft0YlptA6iSP9IZ648ZvIjjASY%2FFb0LDUF338Y%2Ff1bbw9DA%3D%3D&upload_id=ADPycdu0J8f2rzLZQK-LjAC0AK7Q5YyAOXPLlhIgafywNkE_e9s2AB8B7kBLHBL7raG_5sB5Xdp0ib9RVD_zvmJaa7pJyQ",
                upload_progress: 100,
                uploading: false,
                url: "",
                indentation: 2,
              },
              id: "slide-4-video-13",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "コードも書けます。👇",
                boxColor: "darker",
                indentation: 2,
                shadowed: true,
              },
              id: "slide-4-text-8",
            },
            {
              type: "code",
              data: {
                header: "index.js",
                text: "function start() {\n alert('Hello, world!');\n}",
                language: "javascript",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-4-code-9",
            },
            {
              type: "text",
              data: {
                header: "フルスクリーンの縦型動画も入れられます。",
                text: "",
                boxColor: "darker",
                indentation: 2,
                shadowed: true,
              },
              id: "slide-4-text-10",
            },
            {
              type: "image",
              data: {
                url: "https://i.ibb.co/9WG5DXP/42-FE2-D17-1-B44-41-F2-8-FEA-C29738619-D8-D.jpg",
                caption: "これは画像です。",
                cropped: false,
                indentation: 2,
              },
              id: "slide-4-image-11",
            },
          ],
        },
        {
          id: "slide-2",
          fullContents: false,
          subTitle: "Lekcha Blocksって...",
          title: "どうやって使うの？",
          backgroundColor:
            "linear-gradient(163deg, rgba(242,187,107,1) 0%, rgba(250,149,84,1) 100%)",
          fontColor: "white",
          blocks: [
            {
              type: "text",
              data: {
                header: "スマホで簡単に操作できます。",
                text: "必要な作業は、好きなブロックの種類を選んで追加するだけ。",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-2-text-0",
            },
            {
              type: "video",
              data: {
                url: "https://drive.google.com/uc?export=download&id=1aPPp6xvKLAT7jwO05YtOP3BMj0lI1Cjq",
                caption: "このように、縦型動画もアップロードできます。",
                cropped: false,
                indentation: 1,
                uploading: false,
                upload_progress: 100,
                originalFilename: "blocks_使い方_1.mp4",
                sourceUrl:
                  "https://storage.googleapis.com/video-storage-gcp-us-east1-vop1-uploads/wcwluX1MEPFLaZpFzLTZCB?Expires=1682071145&GoogleAccessId=uploads-gcp-us-east1-vop1%40mux-video-production.iam.gserviceaccount.com&Signature=0PIChWGQuYbX9eUq1K%2BYrdORbt2unx3kdYJSoQJM1%2F2rOySK41nibTuwm9CI7VOjxYIA%2FVqKIb%2Bxqfk%2FpYhaQRFut%2FUN0rM12uqqzDHllJLKBc49Nm4ruslfgpfnY7J%2FmNUCcKh8CCV1Eh15iABxP0ktFQpGEYBlLoI%2Fmd8IdMYpqWMVOaYHfTik3c00AMw9SrcxHHxMxnXZIgDo02nnOQ2p6WtI5pNF6sWHTUMsagVN1mBVEM%2BD%2FGwCFTHIMrF1cSzXbNi7%2FGi9mtsgPUMZcxdpqwAzNzWruWoug46Kopmqq3sV4ZPWlYe7T2RCxBl824%2BIhNiorn8jGyXUIPaBWg%3D%3D&upload_id=ADPycdtBJzVe7NU6AItQgLm5pwSzK8WjFS0O7j9TZ1J0QXA1_Hh9dKJKd9cq7C3dnb8TTI2ntYEoi8Q58ffLiQD0iY5oIA",
                mux_asset_id: "PCHfJwPlk3PK700QdHUzz5iEAUAzt86OSmnoxFrywr01I",
                mux_playback_id: "phsVf8hAMroFLTLoPo2EGgK8mAphsujifydRir9hAx8",
              },
              id: "slide-2-video-1",
            },
            {
              type: "text",
              data: {
                header: "テーマごとにスライドを分けて、見やすく構成できます。",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-2-text-2",
            },
            {
              type: "video",
              data: {
                url: "https://drive.google.com/uc?export=download&id=1wJu43bIGJYeLimQiAb-OLxKLs11WON9B",
                caption: "",
                cropped: false,
                indentation: 1,
                uploading: false,
                upload_progress: 100,
                originalFilename: "スライド追加_2.mp4",
                sourceUrl:
                  "https://storage.googleapis.com/video-storage-gcp-us-east1-vop1-uploads/TXf5h2kkOESGAtei9zRKtC?Expires=1682071157&GoogleAccessId=uploads-gcp-us-east1-vop1%40mux-video-production.iam.gserviceaccount.com&Signature=TnfTxS63zfl98dD7RlDOeQtgBlJIiZOdCeyPvoL9A%2F%2BaaW%2F7%2Fi0C4cdLwWsdytujtjklD%2BTK%2FFdoMzA%2BlHCNjrHFAfSBdJlDH6YAuSN3nhwvTes20Lp%2FDprSCicP4C8%2Fkb6mHUqGZPN%2BloJ%2FWPNHm9YQION3c%2F%2FSegzaW2N1%2BqvNyNhmxs8ZjzwAhPXaWZIzBtaHMPHcbpNQpa%2F4SCKagUJ0jtbX6YAdX2SRWxqXI0SdNum1k9dr%2FkioTvConLfuUlj7DChfpQm3U95yoVr3mgErACDe1nrPuiGXZbeh0ywQIDniXYdcgZVdXbWwUDScBTU0zmuhSREDas4W4xfS4Q%3D%3D&upload_id=ADPycdvLum7embYP1Hzj3oYebhD95FaYmoprpjUETMhfhFcKJ81nF9ozmhCRhBgBeoxJgQhWqr2RhpbhbEXOc6Ge6Iz06Q",
                mux_asset_id: "4bG02RLjUn9uWikQPzzHQ4GE9CvZLdtc01tY58XfZpv01g",
                mux_playback_id:
                  "W9nF1BUxdPfVY7rKTUvF4ZLu1ohDuJ01700Wq4QgFWpJI",
              },
              id: "slide-2-video-3",
            },
            {
              type: "text",
              data: {
                header:
                  "🤖 そして、AI機能で簡単にコンテンツの作成もできちゃいます...!!!",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-2-text-4",
            },
            {
              type: "image",
              data: {
                url: "https://drive.google.com/uc?export=download&id=1SsAggmRS4PYf9GHL195C4hPviE0PRuBj",
                caption: "",
                cropped: false,
                indentation: 1,
              },
              id: "slide-2-image-5",
            },
          ],
        },
      ],
    },
    leather_craft: {
      title: "レザークラフト講座",
      slides: [
        {
          id: "slide-0",
          fullContents: false,
          subTitle: "ミニ講座シリーズ",
          title: "【レザークラフト講座】コバ処理編",
          backgroundColor: "#E7E5E2",
          fontColor: "#685E57",
          blocks: [
            {
              type: "image",
              data: {
                url: "https://images.unsplash.com/photo-1492105232359-ca132c09762c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
                caption: "",
                cropped: true,
                indentation: 0,
                tilt: true,
              },
              id: "slide-0-image-2",
            },
            {
              type: "text",
              data: {
                header: "ようこそ！",
                text: "この講座では、レザークラフトの重要な工程である「コバ処理」の概要について、ステップバイステップで解説していきます。",
                boxColor: "lighter",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-0-text-0",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "より深く学びたい方向けの案内もございますので、ぜひ最後までお楽しみください。",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-4",
            },
            {
              type: "text",
              data: {
                header: "講座の目次",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-0-text-3",
            },
            {
              type: "text",
              data: {
                header: "1：コバ処理の種類",
                text: "",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-5",
            },
            {
              type: "text",
              data: {
                header: "2：コバ処理の作業工程",
                text: "",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-9",
            },
            {
              type: "text",
              data: {
                header: "3：コバ処理の手法",
                text: "",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-6",
            },
            {
              type: "text",
              data: {
                header: "エッジングジェルを使った手法",
                text: "",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-0-text-7",
            },
            {
              type: "text",
              data: {
                header: "バーナーを使った手法",
                text: "",
                boxColor: "darker",
                indentation: 2,
              },
              id: "slide-0-text-8",
            },
            {
              type: "text",
              data: {
                header: "4：コバ処理の重要さ",
                text: "",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-0-text-10",
            },
            {
              type: "text",
              data: {
                header: "それではさっそく、見ていきましょう。",
                text: "",
                boxColor: "lighter",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-0-text-1",
            },
          ],
        },
        {
          id: "slide-0",
          fullContents: false,
          subTitle: "レッスン1",
          title: "コバ処理の種類",
          backgroundColor: "#E7E5E2",
          fontColor: "#685E57",
          blocks: [
            {
              type: "text",
              data: {
                header: "コバ処理とは",
                text: "コバ処理とは、革製品の縁部分を仕上げる工程のことです。革が水分や汚れから守られるようになり、耐摩耗性や耐水性も向上します。",
                indentation: 0,
                boxColor: "darker",
                shadowed: true,
              },
              id: 0,
            },
            {
              type: "video",
              data: {
                url: "",
                caption: "",
                cropped: false,
                indentation: 0,
                uploading: false,
                upload_progress: 100,
                originalFilename: "leathercraft.mp4",
                sourceUrl:
                  "https://storage.googleapis.com/video-storage-gcp-us-east4-vop1-uploads/kbkmIqSidESpFHgjQW6wyB?Expires=1682069350&GoogleAccessId=uploads-gcp-us-east1-vop1%40mux-video-production.iam.gserviceaccount.com&Signature=y8vGADc5ZSemdW8n49oAFxTEMqJgYScB2Lt3%2FG8JQxDCDBGfgh2hBZXu4iTltFhG0XS2RVc2oxAbAWDlEgVUxYlRLm0Prf4nfXysdYPgxLypenag%2B0epJN%2BAQ%2BFCuWH0CNPt8E0ZS%2BjddpFZJSqyQ90UoBcUz3cYD7b%2F5zliJ%2F77KUOGfUGJ9mIOZ9webQjjU4agJhosiX1an0rt6lRbSs6bHSN4nEECn%2F4AYY3aLtIWXEVZwPzBy3S6M1y9vpNxOA8fwNFSxgnr%2BVJ4sArPednmDKdqmu94JqhtwMvf7f%2Bq8oXnhlu2EBBmo81Cckiz7Prtl2QXqkmuYuJVVwoijg%3D%3D&upload_id=ADPycdvpEBHTWf5xMoYKhtAryYgvWHy_NIP5YntPf8oyK3B1WwK42mwn7BRZ-GPWDWXNE3qqZRY2PZLB3S1ZU57DarwlNg",
                mux_asset_id: "v2Jlg02e2E77fRinayJn601idp3JT5yir4yO1026nTdnco",
                mux_playback_id:
                  "dmiSfpyfGynv7NZg02XQ01woadTziOp2i01EA02QbkaNfes",
              },
              id: "slide-1-video-6",
            },
            {
              type: "text",
              data: {
                header: "コバ処理の種類",
                text: "",
                indentation: 0,
                boxColor: "darker",
                shadowed: true,
              },
              id: 1,
            },
            {
              type: "image",
              data: {
                url: "https://drive.google.com/uc?export=download&id=1AW-hPu7l4HggSWegTNg65x4SYQkCme1c",
                caption: "",
                cropped: true,
                indentation: 0,
                tilt: false,
              },
              id: "slide-1-image-6",
            },
            {
              type: "text",
              data: {
                header: "1. ワックスコバ",
                text: "表面にワックスを塗布して仕上げる方法です。革の味わいを残しながら、コバをwax感を持たせることができます。男性向けの小物などに適しています。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 2,
            },
            {
              type: "text",
              data: {
                header: "2. 糊コバ",
                text: "通常は革端末に糊を塗り、ニスを塗布して仕上げます。コバがしっかりしており、水滴なども弾くため、レインシューズに適しています。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 3,
            },
            {
              type: "text",
              data: {
                header: "3. 外しコバ",
                text: "糊コバの上にさらにバーナーで炙ってコバを固める手法です。仕上がりが硬く、凹凸感のある上品なコバが仕上がります。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 4,
            },
          ],
          transcript:
            "レザークラフトの工程である「コバ処理」の種類について解説するスライド",
        },
        {
          id: "slide-1",
          fullContents: false,
          subTitle: "レッスン2",
          title: "コバ処理の作業工程",
          backgroundColor: "#334451",
          fontColor: "#DADADA",
          blocks: [
            {
              type: "text",
              data: {
                header: "用意するもの",
                text: "",
                indentation: 0,
                boxColor: "darker",
                shadowed: true,
              },
              id: 2,
            },
            {
              type: "text",
              data: {
                header: "",
                text: "- ギザミ\n- 細切れの布かす\n- 水\n- 皮革用のワックス\n- ブラシ",
                boxColor: "lighter",
                indentation: 1,
              },
              id: "slide-2-text-9",
            },
            {
              type: "link",
              data: {
                title: "ここで材料を購入できます:shopping_bags:",
                url: "https://amzn.asia/d/97n2H1D",
                caption: "",
                indentation: 1,
              },
              id: "slide-2-link-10",
            },
            {
              type: "text",
              data: {
                header: "コバ処理の手順",
                text: "コバ処理には、合計6つのステップから構成されています。順に見ていきましょう。",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-2-text-8",
            },
            {
              type: "video",
              data: {
                url: "",
                caption: "",
                cropped: false,
                indentation: 0,
                uploading: false,
                upload_progress: 100,
                originalFilename: "コバ処理の作業工程.mp4",
                sourceUrl:
                  "https://storage.googleapis.com/video-storage-gcp-us-east4-vop1-uploads/8JWkeWNZTN17HUiWftGtfR?Expires=1682068806&GoogleAccessId=uploads-gcp-us-east1-vop1%40mux-video-production.iam.gserviceaccount.com&Signature=Yf2d0ksuAjOAk86p4Vsaxj7a8WzvAeiW1UbpMMeItByQ0BSJhCbSTKn2ABabHDiYNDCSHSLUoQ6FZroWgJ1Rhnt5P79tqACzULNmTl%2FcUeEfofQlYap2qQ5GLngRLnMSAl18wh3Flhj9YmKMu%2B0HEbfppsui%2F7FizL9%2F7ZF6Ckw3m1hE3ifchcB0oKm%2Fhcl7yG7WoZttitWmTOLpWH6XO%2BKRedVdAN3mAW9ACXAfHa5MWOEY6p%2F8NUyvAwvb8IFeIualIWlXQ58%2FR8H6yAGfDZa8%2FUYwK02Rvxn5pJDqvRnBsPNHcFyX7qj52ILpevOQrGPR1dExjhGR9tPcvkd2kQ%3D%3D&upload_id=ADPycdtws5LU3VMMnZhdhbvL5UyL2e7GdP8_X_q8m4R8dqrxnHv3nfqxf-TnpKTfCtYATj0Zc3ERTdZplJDjcFCJK7FJDA",
                mux_asset_id: "GxsNGZsBnubaQs2lX4Gi65JVRJaZgJ00WPJlx9BdLaPM",
                mux_playback_id: "hin4y5JrNbGEyYTUEATOPQ5FOoS00mvmhh9ylQ13SsSQ",
              },
              id: "slide-2-video-10",
            },
            {
              type: "text",
              data: {
                header: "1. ギザミで外側の角を整える",
                text: "ギザミを用いて、角を整えることにより、綺麗に処理することができます。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 3,
            },
            {
              type: "text",
              data: {
                header: "2. 細切れの布かすを水に浸す",
                text: "細切れの布かすを水に浸し、柔らかくします。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 4,
            },
            {
              type: "text",
              data: {
                header: "3. 皮革用のワックスを溶かす",
                text: "皮革用のワックスを加熱して溶かします。溶かす方法は、直火で加熱する方法と湯煎で加熱する方法とがあります。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 5,
            },
            {
              type: "text",
              data: {
                header: "4. 布を湿らせてワックスに絡ませる",
                text: "柔らかくした布を皮革用のワックスに浸し、絡ませます。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 6,
            },
            {
              type: "text",
              data: {
                header: "5. コバにワックスを塗布する",
                text: "塗布したいコバ箇所に布を当てて、ワックスを塗布します。均等に塗布するように注意しましょう。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 7,
            },
            {
              type: "text",
              data: {
                header: "6. ブラシで仕上げる",
                text: "ワックスを塗布した後、ブラシで軽く磨きます。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 8,
            },
          ],
          transcript: "レザークラフトの「コバ処理」の方法を解説するスライド",
        },
        {
          id: "slide-2",
          fullContents: false,
          subTitle: "レッスン3",
          title: "コバ処理の手法",
          backgroundColor: "#58C3CB",
          fontColor: "#FFFFFF",
          blocks: [
            {
              type: "text",
              data: {
                header: "エッジングジェルを使った手法",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-3-text-7",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "革の縁を塗布することでコバ処理を行う手法です。革の縁にエッジィングジェルを塗布し、指で滑らせながら均一に広げ、乾燥させます。その後刃物やペーパーで仕上げを行います。",
                boxColor: "lighter",
                indentation: 1,
                shadowed: true,
              },
              id: "slide-3-text-13",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "エッジングジェルとは、コバ処理の仕上げに使用されるジェル状の液体です。エッジングジェルを使用することで、コバ部分を滑らかに整え、美しい仕上がりを実現することができます。",
                boxColor: "lighter",
                indentation: 2,
              },
              id: "slide-3-text-9",
            },
            {
              type: "text",
              data: {
                header: "エッジングジェルの種類",
                text: "",
                boxColor: "darker",
                indentation: 0,
              },
              id: "slide-3-text-10",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "1. ウォーターベースのエッジングジェル\n2. オイルベースのエッジングジェル",
                boxColor: "lighter",
                indentation: 1,
                shadowed: true,
              },
              id: "slide-3-text-11",
            },
            {
              type: "text",
              data: {
                header: "",
                text: "エッジングジェルには、ウォーターベースのものとオイルベースのものがあります。ウォーターベースのエッジングジェルは、水で洗い流せるため、クリーニングが簡単です。一方、オイルベースのエッジングジェルは、耐水性が高く、耐久性に優れています。",
                boxColor: "lighter",
                indentation: 2,
              },
              id: "slide-3-text-12",
            },
            {
              type: "text",
              data: {
                header: "バーナーを使った手法",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-3-text-8",
            },
            {
              type: "text",
              data: {
                header: "バーナー手法とは",
                text: "バーナー手法は、レザークラフトにおけるコバ処理の一種であり、コバに熱を加えて溶かし、表面をなめらかに仕上げる方法です。",
                indentation: 1,
                boxColor: "lighter",
                shadowed: true,
              },
              id: 0,
            },
            {
              type: "text",
              data: {
                header: "バーナー手法の具体的な手順",
                text: "",
                indentation: 0,
                boxColor: "darker",
                shadowed: false,
              },
              id: 1,
            },
            {
              type: "text",
              data: {
                header: "1. レザークラフトバーナーを準備する",
                text: "",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 2,
            },
            {
              type: "text",
              data: {
                header: "2. コバを準備する",
                text: "",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 3,
            },
            {
              type: "text",
              data: {
                header: "3. バーナーで加熱する",
                text: "",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 4,
            },
            {
              type: "text",
              data: {
                header: "4. コバをなめらかに仕上げる",
                text: "",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 5,
            },
            {
              type: "text",
              data: {
                header: "⚠️ 注意！",
                text: "バーナー手法によるコバ処理は、美しい仕上がりを得ることができる一方、加熱による変色などのリスクもあるため、慣れるまでは慎重に行いましょう。",
                indentation: 1,
                boxColor: "darker",
                shadowed: false,
              },
              id: 6,
            },
          ],
        },
        {
          id: "slide-4",
          fullContents: false,
          subTitle: "レッスン4",
          title: "コバ処理の重要さ",
          backgroundColor: "#E7E5E2",
          fontColor: "#685E57",
          blocks: [
            {
              type: "image",
              data: {
                url: "https://images.unsplash.com/photo-1635100299010-0410d7434a93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
                caption: "",
                cropped: true,
                indentation: 0,
              },
              id: "slide-4-image-3",
            },
            {
              type: "text",
              data: {
                header: "1. 美しさ",
                text: "コバ処理をしないと、革がむき出しになってしまい、見た目が悪くなってしまいます。コバ処理をすることで、革独特の風合いや質感が引き立ち、美しさが増します。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 2,
            },
            {
              type: "text",
              data: {
                header: "2. 丈夫さ",
                text: "コバ処理をしないと、革が割れたり傷ついたりしやすくなります。コバ処理をすることで、革の縁の部分をしっかりと保護し、耐久性が増します。",
                indentation: 1,
                boxColor: "lighter",
              },
              id: 3,
            },
            {
              type: "text",
              data: {
                header: "",
                text: "また、コバ処理によって、革製品全体の品質が高まることもあります。",
                indentation: 0,
                boxColor: "darker",
                shadowed: true,
              },
              id: 4,
            },
          ],
          transcript:
            "レザークラフトの「コバ処理」の重要さについて解説するスライド",
        },
        {
          id: "slide-5",
          fullContents: false,
          subTitle: "",
          title: "さいごに",
          backgroundColor:
            "linear-gradient(163deg, rgba(242,187,107,1) 0%, rgba(250,149,84,1) 100%)",
          fontColor: "white",
          blocks: [
            {
              type: "text",
              data: {
                header: "講座のご受講ありがとうございました！",
                text: "",
                boxColor: "darker",
                indentation: 0,
                shadowed: true,
              },
              id: "slide-5-text-0",
            },
            {
              type: "text",
              data: {
                header: "もっと深く学びたい方へ…",
                text: "今回お伝えしたコバ処理含め、より体系的にレザークラフトについて解説した動画講座をご用意しております。もしご興味ある方は、ぜひチェックしてみてください！",
                boxColor: "lighter",
                indentation: 1,
                shadowed: true,
              },
              id: "slide-5-text-2",
            },
            {
              type: "link",
              data: {
                title: "レザークラフト講座",
                url: "https://lekcha.com/",
                caption: "",
                indentation: 0,
              },
              id: "slide-5-link-1",
            },
          ],
        },
      ],
      id: "content-1",
    },
  };

  return sampleContents[contentName];
};

export const themeColors = slideThemeColors;

export const cardTypes = {
  text: {
    typeName: "text",
    displayName: "テキストブロック",
    faIcon: faFont,
    fullContent: false,
    defalutValue: {
      type: "text",
      data: {
        header: "",
        text: "",
        boxColor: "lighter",
        indentation: 0,
      },
    },
  },
  image: {
    typeName: "image",
    displayName: "画像ブロック",
    faIcon: faImage,
    fullContent: false,
    defalutValue: {
      type: "image",
      data: {
        url: "",
        caption: "",
        cropped: true,
        indentation: 0,
      },
    },
  },
  video: {
    typeName: "video",
    displayName: "動画ブロック",
    faIcon: faVideo,
    fullContent: false,
    defalutValue: {
      type: "video",
      data: {
        url: "",
        caption: "",
        cropped: false,
        indentation: 0,
      },
    },
  },
  link: {
    typeName: "link",
    displayName: "リンクブロック",
    faIcon: faLink,
    fullContent: false,
    defalutValue: {
      type: "link",
      data: {
        title: "",
        url: "",
        caption: "",
        indentation: 0,
      },
    },
  },
  code: {
    typeName: "code",
    displayName: "コードブロック",
    faIcon: faCode,
    fullContent: false,
    defalutValue: {
      type: "code",
      data: {
        header: "",
        text: "",
        language: "javascript",
        boxColor: "darker",
        indentation: 0,
      },
    },
  },
  full_video: {
    typeName: "full_video",
    displayName: "フルスクリーン動画",
    faIcon: faVideo,
    fullContent: true,
    defalutValue: {
      type: "full_video",
      data: {
        url: "",
        cropped: false,
        indentation: 0,
      },
    },
  },
  form: {
    adminOnly: true,
    typeName: "form",
    displayName: "登録フォーム",
    faIcon: faUserPlus,
    fullContent: false,
    defalutValue: {
      type: "form",
      data: {
        title: "",
        message: "",
        thanks_message: "",
        button_text: "",
        indentation: 0,
      },
    },
  },
  // email_form: {
  //   typeName: "email_form",
  //   displayName: "メールアドレスフォーム",
  //   faIcon: faVideo,
  //   fullContent: false,
  //   defalutValue: {
  //     type: "email_form",
  //     unlock_block_id: "",
  //     data: {
  //       header: "",
  //       text: "",
  //       boxColor: "lighter",
  //       indentation: 0,
  //     },
  //   },
  // },
};

export const complexityMessage = (complexityVolume) => {
  switch (true) {
    case complexityVolume < 100:
      return {
        message: "かるめ",
        intensity: 1,
      };
      break;
    case 100 <= complexityVolume && complexityVolume < 300:
      return {
        message: "OK",
        intensity: 2,
      };
      break;
    case complexityVolume > 300:
      return {
        message: "重め",
        intensity: 3,
      };
      break;
    default:
      return {
        message: "-",
        intensity: 0,
      };
  }
};

// ----- コンテンツ系 ---------------
// コンテンツのデータ取得
export const retrieveContentData = (contentId) => {
  return axios
    .get(`${endpoints.api}/content/${contentId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// ----- Delete Content ---------------

export const deleteContent = (
  confirm = true,
  cognitoId,
  content_id,
  callBackFunction
) => {
  
  if (confirm && !window.confirm("本当にこのコンテンツを削除しますか?")) return;

  return axios
    .delete(`${endpoints.api}/contents`, {
      params: {
        cognito_id: cognitoId,
        content_id: content_id,
      },
    })
    .then((res) => {
      callBackFunction(res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// ----- Create Content ---------------
export const createContent = (cognitoId, title) => {
  return axios
    .post(`${endpoints.api}/contents`, {
      cognito_id: cognitoId,
      title: title,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createContentWithSlides = (cognitoId, title, slideTitles) => {
  return axios
    .post(`${endpoints.api}/contents_with_slides`, {
      cognito_id: cognitoId,
      title: title,
      slide_titles: slideTitles,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// ----- スライド系 ---------------
// スライドの追加
export const addNewSlide = (indexToAdd, currentSlideIndex, auth, contentId) => {
  return axios
    .post(`${endpoints.api}/slides/add_slide`, {
      cognito_id: auth.authState.cognito_user.username,
      content_id: contentId,
      index_to_add: indexToAdd,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// スライドの削除
export const removeSlide = (slideId, auth, contentId) => {
  return axios
    .delete(`${endpoints.api}/slides/${slideId}`, {
      params: {
        cognito_id: auth.authState.cognito_user.username,
        content_id: contentId,
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// 変更の保存
export const updateChanges = (slideData, auth, contentId, contentData) => {
  return axios
    .put(`${endpoints.api}/slides/${slideData.id}`, {
      cognito_id: auth.authState.cognito_user.username,
      content_id: contentId,
      content_data: contentData,
      slide_data: slideData,
    })
    .then((res) => {
      console.log("saved: ", res);
    });
};

// 親スライドの特定
export const getParentSlide = (slides, currentSlideId) => {
  const currentSlide = slides.find((slide) => slide.id === currentSlideId);
  const currentIndex = slides.indexOf(currentSlide);
  if (currentSlide.depth_level === 0 || currentIndex === 0) {
    return undefined;
  }
  const parentDepth = currentSlide.depth_level - 1;
  const parentLevelSlides = slides.filter(
    (slide, index) => slide.depth_level == parentDepth && index < currentIndex
  );
  const closestParentSlide = parentLevelSlides.reverse().find(() => true);

  return closestParentSlide;
};

// スライドが親スライドかを特定
export const isParentSlide = (slides, currentSlideId) => {
  const currentSlide = slides.find((slide) => slide.id === currentSlideId);
  const currentIndex = slides.indexOf(currentSlide);
  const nextSlide = slides[currentIndex + 1];

  if (nextSlide && nextSlide.depth_level > currentSlide.depth_level) {
    return true;
  } else {
    return false;
  }
};

// スライドの並び替え
export const reorderSlides = (direction, setSlidesData, slideIndex) => {
  setSlidesData((prevState) => {
    const slides = prevState.slides;
    const currentSlide = slides[slideIndex];

    switch (direction) {
      case "up":
        if (slideIndex === 0) {
          return prevState; // No change needed if the slide is already at the top
        }

        const prevSlide = slides[slideIndex - 1];

        // Swap the display_order values using array destructuring
        [currentSlide.display_order, prevSlide.display_order] = [
          prevSlide.display_order,
          currentSlide.display_order,
        ];

        // Swap the slides using array destructuring
        [slides[slideIndex - 1], slides[slideIndex]] = [
          slides[slideIndex],
          slides[slideIndex - 1],
        ];
        break;
      case "down":
        if (slideIndex === slides.length - 1) {
          return prevState; // No change needed if the slide is already at the bottom
        }

        const nextSlide = slides[slideIndex + 1];

        // Swap the display_order values using array destructuring
        [currentSlide.display_order, nextSlide.display_order] = [
          nextSlide.display_order,
          currentSlide.display_order,
        ];

        // Swap the slides using array destructuring
        [slides[slideIndex], slides[slideIndex + 1]] = [
          slides[slideIndex + 1],
          slides[slideIndex],
        ];
        break;
      default:
        console.log("error");
        return prevState; // No change if direction is invalid
    }

    // Return the updated state object
    return { ...prevState, slides: slides };
  });
};

// ----- ブロック系 ---------------
// ブロックの作成
export const createNewBlock = (
  blockType,
  indexToAdd,
  slideId,
  auth,
  contentId,
  block_default_value,
  child_default_value
) => {
  return axios
    .post(`${endpoints.api}/blocks`, {
      cognito_id: auth.authState.cognito_user.username,
      content_id: contentId,
      slide_id: slideId,
      block_type: blockType,
      full_screen: cardTypes[blockType].fullContent,
      index_to_add: indexToAdd,
      block_default_value: block_default_value,
      child_default_value: child_default_value,
    })
    .then((res) => {
      return res.data.id;
    })
    .catch((err) => {
      console.log(err);
    });
};

// ブロックの削除
export const removeBlock = (blockId, slideId, auth, contentId) => {
  return axios
    .delete(`${endpoints.api}/blocks/${blockId}`, {
      params: {
        cognito_id: auth.authState.cognito_user.username,
        content_id: contentId,
        slide_id: slideId,
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// ブロックの並び替え
export const reorderBlocks = (
  direction,
  setSlidesData,
  currentSlideIndex,
  blockIndex
) => {
  setSlidesData((prevState) => {
    const { slides } = prevState;
    const currentSlide = slides[currentSlideIndex];
    const blocks = [...currentSlide.blocks];
    const currentBlock = blocks[blockIndex];

    switch (direction) {
      case "up":
        if (blockIndex === 0) {
          return prevState; // No change needed if the block is already at the top
        }

        const prevBlock = blocks[blockIndex - 1];

        // Swap the display_order values using array destructuring
        [currentBlock.display_order, prevBlock.display_order] = [
          prevBlock.display_order,
          currentBlock.display_order,
        ];

        // Swap the blocks using array destructuring
        [blocks[blockIndex - 1], blocks[blockIndex]] = [
          blocks[blockIndex],
          blocks[blockIndex - 1],
        ];
        break;
      case "down":
        if (blockIndex === blocks.length - 1) {
          return prevState; // No change needed if the block is already at the bottom
        }

        const nextBlock = blocks[blockIndex + 1];

        // Swap the display_order values using array destructuring
        [currentBlock.display_order, nextBlock.display_order] = [
          nextBlock.display_order,
          currentBlock.display_order,
        ];

        // Swap the blocks using array destructuring
        [blocks[blockIndex], blocks[blockIndex + 1]] = [
          blocks[blockIndex + 1],
          blocks[blockIndex],
        ];
        break;
      default:
        console.log("error");
        return prevState; // No change if direction is invalid
    }

    // Create a new array of slides with the updated blocks array
    const updatedSlides = slides.map((slide, index) => {
      if (index === currentSlideIndex) {
        return { ...slide, blocks };
      } else {
        return slide;
      }
    });

    // Return the updated state object
    return { ...prevState, slides: updatedSlides };
  });
};

// ブロックのスライド間移動
export const moveBlocksToSlide = ({
  slideId,
  blockIdsToMove,
  slideTitle,
  targetSlideId = undefined,
  prepend = false,
}) => {
  return axios
    .put(`${endpoints.api}/blocks/move_blocks_to_slide`, {
      slideId: slideId,
      block_ids_to_move: blockIdsToMove,
      target_slide_id: targetSlideId,
      slide_title: slideTitle,
      prepend: prepend,
    })
    .then((res) => {
      return res.data;
    });
};

// ブロック内のtextarea等の高さを自動調整
export const resizeFieldHeights = (refArray) => {
  refArray.map((ref) => {
    if (ref.current) {
      ref.current.style.height = "auto"; // reset height to auto to recalculate
      ref.current.style.height = `${ref.current.scrollHeight}px`; // set new height based on content
    }
  });
};
