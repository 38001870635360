import React from "react";
import "./SlideCover.scss";

// helpers
import { getParentSlide } from "../helpers/ContentHelper";

// animation
import AnimateUpWrapper from "../components/Animation/AnimateUpWrapper";

const SlideCover = ({ contentData, slideData, duration, setSlideCover, slideColors }) => {
  // ------ States ---------------
  const [parentSlide, setParentSlide] = React.useState(null);
  const [percentage, setPercentage] = React.useState(0);

  // スライドが変わった時、スライドカバーを一定時間のみ表示する
  React.useEffect(() => {
    setPercentage(0);
    setSlideCover(true);

    const interval = setInterval(() => {
      setPercentage((percentage) => percentage + (100 / duration) * 5);
    }, 5);

    //クリーンアップ
    return () => {
      clearInterval(interval);
    };
  }, [slideData]);

  // スライドが変わった時、親スライドを取得する
  React.useEffect(() => {
    const parentSlide = getParentSlide(contentData.slides, slideData.id);
    setParentSlide(parentSlide);
  }, [slideData]);

  React.useEffect(() => {
    if (percentage >= 100) {
      setSlideCover(false);
    }
  }, [percentage]);

  if (slideColors) {
    return (
      <div
        className="slide-cover-wrapper"
        onClick={() => {
          setSlideCover(false);
        }}
      >
        <AnimateUpWrapper className="slide-info-wrapper">
          <div className="slide-title-wrapper">
            {parentSlide && parentSlide.title !== "" && (
              <span className="parent-slide-title">{parentSlide.title}</span>
            )}
            <span className="slide-title">{slideData.title}</span>
          </div>
          <div
            className="slide-cover-guage"
            style={{
              background: slideColors.lighterShift,
            }}
          >
            <div
              className="slide-cover-guage-fill"
              style={{
                width: `${percentage}%`,
                background: slideColors.legibleColor,
              }}
            ></div>
          </div>
        </AnimateUpWrapper>
      </div>
    );
  }
};

export default SlideCover;
