import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// コンポーネント
import Overlay from "./Overlay.js";

// helpers
import {
  cardTypes,
} from "../helpers/ContentHelper";

const AddBlockOverlay = ({
  auth,
  contentData,
  currentSlideIndex,
  addBlockData,
  setAddBlockData,
  addBlock,
}) => {
  return (
    <Overlay
      title="追加するブロックを選択"
      open={addBlockData.modalOpen}
      setOpen={(value) => {
        setAddBlockData((prev) => ({
          ...prev,
          modalOpen: value,
        }));
      }}
      buttons={{
        primaryButton: {
          display: false,
          text: "",
          action: (e) => {},
        },
        secondaryButton: {
          display: true,
          text: "キャンセル",
          action: (e) => {
            setAddBlockData((prev) => ({
              ...prev,
              modalOpen: false,
            }));
          },
        },
      }}
    >
      <div className="select-block-contents">
        {Object.keys(cardTypes).map((key, index) => {
          if (
            !(
              cardTypes[key].fullContent &&
              contentData.slides[currentSlideIndex].blocks.length > 0
            )
          ) {

            if (cardTypes[key].adminOnly == true && auth.authState.isAdmin != true) return;

            return (
              <div
                className={`type cursor-pointer scale-on-hover ${cardTypes[key].adminOnly == true ? "admin-only" : ""}`}
                key={index}
                onClick={() => {
                  setAddBlockData((prev) => ({
                    ...prev,
                    modalOpen: false,
                  }));
                  addBlock(key);
                }}
              >
                <FontAwesomeIcon icon={cardTypes[key].faIcon} />
                <span>{cardTypes[key].displayName}</span>
              </div>
            );
          }
        })}
      </div>
    </Overlay>
  );
};

export default AddBlockOverlay;
