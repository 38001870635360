import React from "react";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

// enpoints
import axios from "axios";
import endpoints from "../helpers/apiUrls.js";

// libraries
import { Link, useNavigate } from "react-router-dom";

// auth
import { Auth } from "aws-amplify";
import {
  sendPasswordResetCode,
  resetPassword,
} from "../helpers/authentication.js";

// helpers
import { validateEmailString } from "../helpers/Utility.js";

// components
import AuthenticationWrapper from "../components/AuthenticationWrapper.js";

const ResetPassword = ({ auth }) => {
  const navigate = useNavigate();

  const [resetStep, setResetStep] = React.useState("sendCode");

  const [redirectToConfirm, setRedirectToConfirm] = React.useState(false);
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);

  const { authState, setAuthState } = auth;

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [masterValidation, setMasterValidation] = React.useState(false);
  const [masterError, setMasterError] = React.useState({
    isErrored: false,
    errorMessage: "エラーが発生しました。",
  });
  const [data, setData] = React.useState({
    email: "",
    code: "",
    newPassword: "",
  });

  const [validationStatus, setValidationStatus] = React.useState({
    email: {
      validation: false,
      errorMessage: "",
    },
    code: {
      validation: false,
      errorMessage: "",
    },
    newPassword: {
      validation: false,
      errorMessage: "",
    },
  });

  const sendResetConfirmation = () => {
    sendPasswordResetCode(
      data.email,
      setIsConfirming,
      setResetStep,
      masterError,
      setMasterError
    );
  };

  const sendPasswordReset = () => {
    resetPassword(
      data.email,
      data.code,
      data.newPassword,
      setIsConfirming,
      setRedirectToLogin,
      masterError,
      setMasterError
    );
  };

  const validateMaster = () => {
    switch (resetStep) {
      case "sendCode":
        setMasterValidation(validationStatus.email.validation);
        break;
      case "resetPassword":
        setMasterValidation(
          validationStatus.code.validation &&
            validationStatus.newPassword.validation
        );
        break;
    }
  };

  const validateCode = (code) => {
    const validation = code != "";

    setValidationStatus((validationStatus) => ({
      ...validationStatus,
      code: {
        validation: validation,
        errorMessage: validation ? "" : "認証コードを入力してください。",
      },
    }));
  };

  const validatePassword = (password) => {
    const regExpAlphabets = /[a-zA-Z]/g;
    const validation = regExpAlphabets.test(password) && password.length >= 8;

    setValidationStatus((validationStatus) => ({
      ...validationStatus,
      newPassword: {
        validation: validation,
        errorMessage: validation
          ? ""
          : "パスワードは8文字以上で設定してください。",
      },
    }));
  };

  const validateEmail = (email) => {
    const validation = validateEmailString(email);

    setValidationStatus((validationStatus) => ({
      ...validationStatus,
      email: {
        validation: validation,
        errorMessage: validation ? "" : "正しいアドレスを入力してください。",
      },
    }));
  };

  React.useEffect(() => {
    setMasterValidation(false);
    setMasterError({
      isErrored: false,
      errorMessage: "エラーが発生しました。",
    });
  }, [resetStep]);

  React.useEffect(() => {
    switch (resetStep) {
      case "sendCode":
        validateEmail(data.email);
        break;
      case "resetPassword":
        validateCode(data.code);
        validatePassword(data.newPassword);
        break;
    }
  }, [data]);

  React.useEffect(() => {
    validateMaster();
  }, [validationStatus]);

  React.useEffect(() => {
    if (redirectToLogin) {
      navigate("/login");
    }
  }, [redirectToLogin]);

  switch (resetStep) {
    case "sendCode":
      return (
        <AuthenticationWrapper
          header="パスワードをリセットします"
          comment="アカウントのメールアドレスを入力してください。"
          appendix={{
            loginLink: true,
            contactLink: true,
          }}
        >
          <>
            <div className="forms-wrapper">
              <div className="authentication-form-section">
                <span className="form-title">メールアドレス</span>
                <input
                  type="text"
                  className="form"
                  value={data.email}
                  placeholder="mail@example.com"
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      email: e.target.value,
                    }));
                  }}
                />
                {!validationStatus.email.validation &&
                  validationStatus.email.errorMessage != "" && (
                    <div className="validationMessage">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span>{validationStatus.email.errorMessage}</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="button-section">
              <div
                className={`button ${
                  !masterValidation || isConfirming ? "disabled" : ""
                }`}
                onClick={() => {
                  if (masterValidation) {
                    sendResetConfirmation();
                  }
                }}
              >
                <span>認証コードを送信</span>
              </div>

              {masterError.isErrored && masterError.errorMessage != "" && (
                <div className="masterErrorMessage">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span>{masterError.errorMessage}</span>
                </div>
              )}
            </div>
          </>
        </AuthenticationWrapper>
      );
      break;
    case "resetPassword":
      return (
        <AuthenticationWrapper
          header="新しいパスワードの設定"
          comment={`${data.email} に、リセットコードをお送りしました。`}
          appendix={{
            contactLink: true,
          }}
        >
          <>
            <div className="forms-wrapper">
              <div className="authentication-form-section">
                <span className="form-title">認証コード</span>
                <input
                  type="text"
                  className="form"
                  value={data.code}
                  placeholder="メールで届いた認証コード"
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      code: e.target.value,
                    }));
                  }}
                />
                {!validationStatus.code.validation &&
                  validationStatus.code.errorMessage != "" && (
                    <div className="validationMessage">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span>{validationStatus.code.errorMessage}</span>
                    </div>
                  )}
              </div>
              <div className="authentication-form-section">
                <span className="form-title">新しいパスワード</span>
                <input
                  type="password"
                  className="form"
                  value={data.newPassword}
                  placeholder="新しいパスワード"
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      newPassword: e.target.value,
                    }));
                  }}
                />
                {!validationStatus.newPassword.validation &&
                  validationStatus.newPassword.errorMessage != "" && (
                    <div className="validationMessage">
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      <span>{validationStatus.newPassword.errorMessage}</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="button-section">
              <div
                className={`button ${
                  !masterValidation || isConfirming ? "disabled" : ""
                }`}
                onClick={() => {
                  if (masterValidation) {
                    sendPasswordReset();
                  }
                }}
              >
                <span>パスワードをリセット</span>
              </div>

              {masterError.isErrored && masterError.errorMessage != "" && (
                <div className="masterErrorMessage">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <span>{masterError.errorMessage}</span>
                </div>
              )}

              <div
                className="below-button-message link"
                onClick={() => {
                  sendResetConfirmation();
                }}
              >
                <span>認証コードを再送</span>
              </div>
            </div>
          </>
        </AuthenticationWrapper>
      );
      break;
  }
};

export default ResetPassword;
