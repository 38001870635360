import React from "react";
import "./EditBasketModal.scss";

// Overlays
import Overlay from "./Overlay.js";

// components
import ContentList from "../HomeComponents/ContentList";

// form components
import FormsWrapper from "./OverlayComponents/FormsWrapper.js";
import FormWrapper from "./OverlayComponents/FormWrapper.js";
import FormContent from "./OverlayComponents/FormContent.js";

// helpers
import { getBasketContents, updateBasket } from "../helpers/BasketsHelper.js";

// loading
import Loading from "../UtilityComponents/Loading";

import { returnSelected } from "../helpers/ContentListHelper.js";

const EditBasketModal = ({
  auth,
  loadBaskets,
  basketData,
  basketEditModal,
  setBasketEditModal,
}) => {
  var cognito_id = auth.authState.cognito_user.username;

  // ------ Basket state -----------------
  const [basketEditData, setBasketEditData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [edited, setEdited] = React.useState(false);

  const [contents, setContents] = React.useState([]);

  // ------ Functions -----------------
  const closeModalWithWarning = (value) => {
    if (value == false && edited == true) {
      if (
        window.confirm("変更は保存されません。よろしいですか？", "") == false
      ) {
        return;
      }
    }
    setEdited(false);
    setBasketEditModal(value);
  };

  const submitBasketEditData = () => {
    updateBasket(cognito_id, basketEditData, returnSelected(contents)).then(
      (res) => {
        setEdited(false);
        setBasketEditModal(false);
        loadBaskets();
      }
    );
  };

  // inserted as a middleware to track if the data is edited to ask before closing or canceling.
  const updateBasketEditData = (key, value) => {
    setEdited(true);
    setBasketEditData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // inserted as a middleware to track if the data is edited to ask before closing or canceling.
  const updateContents = (value) => {
    setEdited(true);
    setContents(value);
  };

  // ------ Effects -----------------

  React.useEffect(() => {
    // console.log("edit data", basketEditData);
  }, [basketEditData]);

  React.useEffect(() => {
    // load contents
    if (!basketEditModal) return;

    var cognito_id = auth.authState.cognito_user.username;
    getBasketContents(cognito_id, basketData).then((res) => {
      setBasketEditData(res.basket_data);
      setContents(res.contents_data);
      setLoading(false);
      setEdited(false);
    });
  }, [basketEditModal]);

  React.useEffect(() => {
    if (!basketEditModal) return;
  }, [contents]);

  return (
    <Overlay
      title="スペース編集"
      open={basketEditModal}
      setOpen={(value) => {
        closeModalWithWarning(value);
      }}
      buttons={{
        primaryButton: {
          display: true,
          text: "保存",
          action: (e) => {
            submitBasketEditData();
          },
        },
        secondaryButton: {
          display: true,
          text: "キャンセル",
          action: (e) => {
            closeModalWithWarning(false);
          },
        },
      }}
    >
      <div className="edit-basket-overlay-wrapper">
        {loading ? (
          <Loading />
        ) : (
          <FormsWrapper>
            <FormWrapper
              formTitle={"スペースの公開"}
              formDescription={
                <>
                  公開されたスペースは、プロフィールに表示されます。
                </>
              }
              customClassName="emphasis-wrapper"
            >
              <FormContent customClassName="flex-section">
                <input
                  type="checkbox"
                  id="cover-checkbox"
                  checked={basketEditData.public}
                  onChange={(e) => {
                    updateBasketEditData("public", e.target.checked);
                  }}
                />
                <label htmlFor="cover-checkbox">スペースを公開する</label>
              </FormContent>
            </FormWrapper>

            <FormWrapper formTitle={"タイトルと説明"}>
              <FormContent subTitle={"スペースのタイトル"}>
                <input
                  type="text"
                  value={basketEditData.title}
                  onChange={(e) => {
                    updateBasketEditData("title", e.target.value);
                  }}
                />
              </FormContent>

              <FormContent subTitle={"説明"}>
                <textarea
                  value={basketEditData.basket_description}
                  onChange={(e) => {
                    updateBasketEditData(
                      "basket_description",
                      e.target.value.replace(/(\r\n|\n|\r)/gm, "")
                    );
                  }}
                />
              </FormContent>
            </FormWrapper>

            {/* <FormWrapper formTitle={"スペース名"}>
              <FormContent>
                <input
                  type="text"
                  value={basketEditData.title}
                  placeholder="スペース名を入力 ..."
                  onChange={(e) => {
                    updateBasketEditData("title", e.target.value);
                  }}
                />
              </FormContent>
            </FormWrapper> */}

            <FormWrapper
              formTitle={"スペースコンテンツ"}
              formDescription={
                "このスペースに含むコンテンツを選択してください。"
              }
            >
              <FormContent>
                <ContentList
                  contents={contents}
                  setContents={updateContents}
                  selectable={true}
                  jumpable={false}
                  menuVisible={false}
                />
              </FormContent>
            </FormWrapper>
          </FormsWrapper>
        )}
      </div>
    </Overlay>
  );
};

export default EditBasketModal;
