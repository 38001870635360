import React, { useRef } from "react";
import "./ContentHeader.scss";

import { useNavigate } from "react-router-dom";

// icons
import { faXmark, faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// framer motion
import { motion } from "framer-motion";

// components
import SlideBreadCrumb from "./ContentHeaderChildren/SlideBreadCrumb.js";

const ContentHeader = ({
  visibility,
  editMode,
  contentData,
  slideData,
  slideColors,
  setChapterModal,
  display,
  auth,
  setWelcomeScreen,
}) => {
  const navigate = useNavigate();
  const titleContainerRef = useRef(null);

  // ----- Refs ---------------
  const animateRef = useRef(null);

  // ----- States -------------
  const [headerVisible, setHeaderVisible] = React.useState(visibility);

  const [titleScroll, setTitleScroll] = React.useState(false);

  // ----- slide colors ----------------

  const headerColors = slideData &&
    slideColors && {
      color: (() => {
        switch (slideData.full_contents) {
          case true:
            return "white";
          case false:
            return slideColors.legibleColor;
        }
      })(),
    };

  // ----- Effects -------------

  React.useEffect(() => {
    if (!titleContainerRef.current) return;

    var cont = titleContainerRef.current;

    if (cont.scrollWidth > cont.offsetWidth) {
      setTitleScroll(true);
    } else {
      setTitleScroll(false);
    }
  }, [contentData.title, editMode]);

  React.useEffect(() => {
    if (visibility == true) {
      let timeoutId = setTimeout(() => {
        setHeaderVisible(visibility);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setHeaderVisible(visibility);
    }
  }, [visibility]);

  if (display) {
    return (
      <motion.div
        ref={animateRef}
        variants={{
          hidden: {
            // top: -animateRef?.current?.offsetHeight,
            opacity: 0,
          },
          visible: {
            // top: 0,
            opacity: 1,
          },
        }}
        animate={headerVisible ? "visible" : "hidden"}
        transition={{ ease: "easeInOut", duration: 0.3, delay: 0 }}
        className={`content-header`}
        style={
          slideColors && {
            color: headerColors.color,
          }
        }
      >
        {contentData.slides.length > 1 && (
          <div
            className="chapter-button cursor-pointer"
            onClick={() => {
              setChapterModal(true);
            }}
          >
            <FontAwesomeIcon icon={faBarsStaggered} />
          </div>
        )}

        <div className="content-header-container">
          <div className="title-section-line">
            <div
              className="content-title cursor-pointer"
              id="title-container"
              ref={titleContainerRef}
              onClick={() => {
                setWelcomeScreen(true);
              }}
            >
              <span className={`title ${titleScroll ? "scroll" : ""}`}>
                {contentData.title}
              </span>
            </div>

            <div className="right">
              {auth.authState.cognito_user ? (
                <>
                  <div
                    className="separator"
                    style={
                      slideColors && {
                        backgroundColor: headerColors.color,
                      }
                    }
                  ></div>
                  <div
                    className="back-button"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} className="back-icon" />
                    <span>とじる</span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="separator"
                    style={
                      slideColors && {
                        backgroundColor: headerColors.color,
                      }
                    }
                  ></div>
                  <div
                    className="back-button"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} className="back-icon" />
                    <span>とじる</span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="title-section-line">
            <SlideBreadCrumb slideData={slideData} contentData={contentData} />
          </div>
        </div>
      </motion.div>
    );
  }
};

export default ContentHeader;
