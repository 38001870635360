import React from "react";
import "./App.scss";

// SEO
import SEO from "./UtilityComponents/SEO.js";

// app start
import AppStart from "./screens/AppStart.js";

// test
import apiTest from "./helpers/apiTest.js";

// authentication
import { getCurrentSession } from "./helpers/authentication.js";

// routing
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes/routes.js";

// analytics
import posthog from "posthog-js";
import { posthogCapture } from "./helpers/postHogHelper";


function App() {
  // authentication
  const [authState, setAuthState] = React.useState({
    isAuthenticated: false,
    isAuthenticating: true,
    cognito_user: null,
  });

  const authProps = {
    authState: authState,
    setAuthState: setAuthState,
  };

  React.useEffect(() => {
    apiTest(); // test backend connection
    getCurrentSession(authProps); // user authentication
    posthogCapture('App Loaded');
  }, []);

  React.useEffect(() => {

    posthogCapture('Auth State Changed');
    
    if (authState.cognito_user?.attributes?.email) {
      posthog.identify(
        authState.cognito_user.username,  // Replace 'distinct_id' with your user's unique identifier
        { email: authState.cognito_user.attributes.email, admin: authState.isAdmin } // optional: set additional user properties
      );

      // posthog.people.set_once({
      //   cognito_id: authState.cognito_user.username,
      //   email: authState.cognito_user.attributes.email,
      // });

    } else {
      posthog.reset()
    }

  }, [authState]);

  return (
    <div className="app-wrapper">
      <SEO
        title="Pulp"
        description="知識をコンテンツにする、全く新しいカタチ。"
        imageUrl={process.env.PUBLIC_URL + "/logo/ogp_new.jpg"}
      />


      {authState.isAuthenticating ? (
        <AppStart />
      ) : (
        <RouterProvider router={routes(authProps)} />
      )}
    </div>
  );
}

export default App;
