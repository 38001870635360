import React from "react";
import "./ContentCardMenuBar.scss";

// fonts
import { faLink, faCheck, faMarker, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// utilities
import { copyToClipboard } from "../helpers/Utility";

const ContentCardMenuBar = ({
  className,
  content,
  requestContentDelete,
  menuVisible,
  shareButton,
  permissionToEdit,
  showPublished,
}) => {
  if (menuVisible && (shareButton || permissionToEdit)) {
    return (
      <div className={`content-card-menu-bar ${className}`}>
        <div className="left">
          {shareButton && (
            <div
              className="button scale-on-hover"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyToClipboard(
                  `${window.location.origin}/content/${content.id}`,
                  true,
                  "コンテンツのリンクをコピーしました。"
                );
              }}
            >
              <FontAwesomeIcon
                className="icon content-delete-icon"
                icon={faLink}
              />
            </div>
          )}

          {(() => {
            if (showPublished) {
              if (content.public) {
                return (
                  <div className="content-published-wrapper green">
                    <FontAwesomeIcon
                      className="content-published-icon"
                      icon={faCheck}
                    />
                    <span className="content-published-text">公開済み</span>
                  </div>
                );
              } else {
                return (
                  <div className="content-published-wrapper gray">
                    <FontAwesomeIcon
                      className="content-published-icon"
                      icon={faMarker}
                    />
                    <span className="content-published-text">下書き</span>
                  </div>
                );
              }
            }
          })()}
        </div>
        <div className="right">
          {permissionToEdit && (
            <div
              className="button scale-on-hover"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                requestContentDelete(content);
              }}
            >
              <FontAwesomeIcon
                className="icon content-delete-icon"
                icon={faTrashCan}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default ContentCardMenuBar;
