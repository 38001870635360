

export const devideContentsIntoColumns = (contents) => {
  const columnOneData = [];
  const columnTwoData = [];

  contents.forEach((content, index) => {
    if (index % 2 == 0) {
      columnOneData.push(content);
    } else {
      columnTwoData.push(content);
    }
  });

  const columns = {
    columnOneData: columnOneData,
    columnTwoData: columnTwoData
  }

  return columns;
};

export const returnSelected = (contents) => {
  return contents.filter((el) => {
    return el.selected == true;
  })
}