import React from "react";
import "./ProfilePage.scss";

import { useParams } from "react-router-dom";

// loading
import Loading from "../../UtilityComponents/Loading.js";

// basket
import Basket from "../CreatorScreens/Basket";

// framer motion
import { framerFadeUp } from "../../helpers/Utility";
import { motion } from "framer-motion";

// helpers
import { getProfileByHandleName } from "../../helpers/ProfileHelper.js";

// SEO
import SEO from "../../UtilityComponents/SEO.js";

const ProfilePage = ({ auth }) => {
  // ----- params ---------------
  let { handle_name } = useParams();

  // ----- states ---------------
  const [profileData, setProfileData] = React.useState(null);
  const [baskets, setBaskets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // ----- effects --------------
  React.useEffect(() => {
    getProfileByHandleName(handle_name).then((res) => {
      setLoading(false);
      setProfileData(res.profile);
      setBaskets(res.baskets);
    });
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        {/* SEO */}
        <SEO
          url={window.location.href}
          title={profileData.display_name}
          imageUrl={
            profileData.profile_image_url && profileData.profile_image_url
          }
          description={profileData.profile_bio && profileData.profile_bio}
        />

        <div className="profile-wrapper">
          <div className="profile-info-section">
            <div className="image-section">
              <div className="image-wrapper">
                {profileData.profile_image_url ? (
                  <img
                    src={profileData.profile_image_url}
                    alt=""
                    className="person-icon"
                  />
                ) : (
                  <span className="person-icon-no-image">🤝</span>
                )}
              </div>
            </div>
            <div className="text-section">
              {profileData.display_name ? (
                <span className="profile-header">
                  {profileData.display_name}
                </span>
              ) : (
                <span className="profile-header">Your Name</span>
              )}
              {profileData.handle_name && (
                <span className="handle-name">@{profileData.handle_name}</span>
              )}
              {profileData.profile_bio && (
                <p className="profile-description">{profileData.profile_bio}</p>
              )}
            </div>
          </div>

          <div className="contents-section">
            {/* <ContentList
                      contents={contents}
                      permissionToEdit={false}
                      menuVisible={false}
                      requestContentDelete={() => {}}
                      rotateEnabled={true}
                      showPublished={false}
                      noContentMessage={`まだコンテンツがありません。`}
                      updatedBadge={true}
                    /> */}

            {baskets.map((basket, index) => {
              return (
                <motion.div
                  className="basket"
                  key={basket.id}
                  variants={framerFadeUp}
                  initial="hidden"
                  animate="show"
                  transition={{
                    ...framerFadeUp.transition,
                    delay: 0.2 * (index + 1),
                  }}
                >
                  <Basket
                    auth={auth}
                    basketData={basket}
                    control={false}
                    showPublished={false}
                    showContentPublished={false}
                    updatedBadge={true}
                  />
                </motion.div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
};

export default ProfilePage;
